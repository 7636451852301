import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { Tab, Row, Col, Card, InputGroup, Form, Button, Badge, Spinner } from 'react-bootstrap'
import * as t from 'counterpart'
import { useSelector } from 'react-redux'
import { useLocalState } from '../../../hooks'
import api from '../../../utilities/API'
import Plans from './Plans'
import './Subscribe.scss'

const initialForm = { name: '', url: '', yearly: false }
const domain = process.env.REACT_APP_SAAS_DOMAIN

function Subscribe() {
    const store = useParams().store
    const [form, setForm] = useLocalState('subscribe_form', { ...initialForm })
    const [step, setStep] = useLocalState('subscribe_step', 'info')
    const { ready, user } = useSelector((state) => state.portal)
    const [orderID, setOrderID] = useState(null)
    const [cards, setCards] = useState([])
    const navigate = useNavigate()
    const yearlyMonths = 11

    // todo: feedback and loading

    useEffect(() => {
        if (!ready || !user) {
            return
        }

        api('/payment/cards/', {
            result: setCards,
            error: alert,
        })
    }, [ready, user])

    //
    const addNewCard = () => {
        api('/payment/cards/?active=1', {
            method: 'POST',
            result: ({ redirect }) => {
                if (redirect) {
                    window.location.href = redirect
                } else {
                    alert('Invalid redirection')
                }
            },
            error: alert,
        })
    }

    //
    const nextStep = () => {
        switch (step) {
            case 'info':
                validate('plan')
                break
            case 'plan':
                validate('pay')
                break
            case 'pay':
                validate('save')
                break
            default:
        }
    }

    //
    const prevStep = () => {
        switch (step) {
            case 'plan':
                setStep('info')
                break
            case 'pay':
                setStep('plan')
                break
            case 'save':
                setStep('pay')
                break
            default:
        }
    }

    //
    const validate = (next) => {
        let required = 0
        let passed = 0

        switch (step) {
            case 'info':
                required = passed = 2

                if (!form.name) {
                    passed--
                }

                if (!form.url) {
                    passed--
                }
                break
            case 'plan':
                required = passed = 1

                if (!form.plan_id) {
                    passed--
                }

                break
            case 'pay':
                required = passed = 1

                if (!form.card_id) {
                    passed--
                }
                break

            default:
        }

        if (passed !== required) {
            alert(t('Please fill out the required fields'))
            return
        }

        setStep(next)
    }

    //
    const submit = () => {
        let data = { ...form }
        delete data.plan

        api('/commerce/subscriptions/', {
            method: 'POST',
            data,
            result: ({ status, order_id }) => {
                if (status === 'delivered') {
                    setForm({ ...initialForm })
                    setStep('info')
                    setOrderID(order_id)
                    // todo: show fake loading until the store setup is done
                } else {
                    alert('Subscription error')
                }
            },
            error: alert,
        })
    }

    if (!ready) {
        return (
            <Spinner
                animation="border"
                variant="primary"
                style={{ position: 'fixed', top: '50%', left: '50%' }}
            >
                <span className="sr-only">Loading...</span>
            </Spinner>
        )
    }

    if (!user || !user.can('cor.admin.access')) {
        return <Navigate to={`/${store}/auth/login?from=/${store}/subscribe`} />
    }

    if (orderID) {
        return <Navigate to={`/${store}/orders/${orderID}`} />
    }

    return (
        <div id="subscribe-page" className="container">
            <Card style={{ marginTop: 50 }}>
                <Tab.Container activeKey={step}>
                    <Row>
                        {/*<Col sm={1}>*/}
                        {/*    <Nav variant="pills" className="flex-column">*/}
                        {/*        <Nav.Item><Nav.Link eventKey="info" title={t("General Info")}> <span>1</span> </Nav.Link></Nav.Item>*/}
                        {/*        <Nav.Item><Nav.Link eventKey="plan" title={t("Choose Plan")}> <span>2</span></Nav.Link></Nav.Item>*/}
                        {/*        <Nav.Item><Nav.Link eventKey="pay" title={t("Payment Info")}> <span>3</span> </Nav.Link></Nav.Item>*/}
                        {/*        <Nav.Item><Nav.Link eventKey="save" title={t("Confirm")}> <span>4</span> </Nav.Link></Nav.Item>*/}
                        {/*    </Nav>*/}
                        {/*</Col>*/}
                        <Col sm={12}>
                            <Tab.Content>
                                <Tab.Pane eventKey="info">
                                    <h2>{t('General Info')}</h2>

                                    <Row>
                                        <div className="col-md-6">
                                            <Row>
                                                <Form.Group className="form-group col-md-9">
                                                    <Form.Label>{t('Platform Name')} </Form.Label>
                                                    <Form.Control
                                                        size="lg"
                                                        placeholder={t('Platform Name')}
                                                        value={form.name}
                                                        onChange={(e) =>
                                                            setForm({
                                                                ...form,
                                                                name: e.target.value,
                                                            })
                                                        }
                                                    />
                                                </Form.Group>
                                            </Row>
                                            <Row>
                                                <Form.Group className="form-group col-md-9">
                                                    <Form.Label>
                                                        {t('Platform URL in English')}{' '}
                                                    </Form.Label>
                                                    <InputGroup size="lg">
                                                        <Form.Control
                                                            placeholder={t('platform-url')}
                                                            value={form.url}
                                                            onChange={(e) =>
                                                                setForm({
                                                                    ...form,
                                                                    url: e.target.value,
                                                                })
                                                            }
                                                        />
                                                        <InputGroup.Text dir="ltr">
                                                            {domain}
                                                        </InputGroup.Text>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Row>
                                        </div>

                                        <div className="col-md-6">
                                            <img
                                                src="/subscription_info.png"
                                                alt=""
                                                style={{ maxHeight: 350, float: 'right' }}
                                            />
                                        </div>
                                    </Row>

                                    <div className="actions">
                                        <Button variant="primary" onClick={nextStep}>
                                            {t('Next')}{' '}
                                            <i className={'fa fa-chevron-' + t('right')} />
                                        </Button>
                                    </div>
                                </Tab.Pane>

                                <Tab.Pane eventKey="plan">
                                    <Form.Label id="yearly">
                                        <Badge style={{ margin: '0 5px' }}>
                                            {(((12 - yearlyMonths) / 12) * 100).toFixed(0)}%{' '}
                                            {t('Discount')}
                                        </Badge>
                                        <Form.Check
                                            type="switch"
                                            label={t('Yearly')}
                                            checked={form.yearly}
                                            onChange={(e) =>
                                                setForm({ ...form, yearly: e.target.checked })
                                            }
                                        />
                                    </Form.Label>

                                    <Plans
                                        selected={form.plan_id}
                                        yearly={form.yearly}
                                        yearlyMonths={yearlyMonths}
                                        onChange={(plan) =>
                                            setForm({ ...form, plan_id: plan?.id, plan })
                                        }
                                        navigate={navigate}
                                        store={store}
                                    />

                                    <div className="actions">
                                        <Button variant="secondary" onClick={prevStep}>
                                            <i className={'fa fa-chevron-' + t('left')} />{' '}
                                            {t('Previous')}
                                        </Button>
                                        <Button variant="primary" onClick={nextStep}>
                                            {t('Next')}{' '}
                                            <i className={'fa fa-chevron-' + t('right')} />
                                        </Button>
                                    </div>
                                </Tab.Pane>

                                <Tab.Pane eventKey="pay">
                                    <h2>{t('Payment Info')}</h2>

                                    <div className="row">
                                        <div id="cards" className="col-md-6">
                                            {cards.map((card) => (
                                                <label
                                                    key={card.id}
                                                    className={'item card-brand-' + card.brand}
                                                >
                                                    <i className="general fa fa-credit-card" />
                                                    <i className="visa fab fa-cc-visa" />
                                                    <i className="mastercard fab fa-cc-mastercard" />

                                                    <div className="bin">
                                                        <div className="mask"> **** **** **** </div>{' '}
                                                        <div>{card.bin}</div>
                                                    </div>

                                                    <Form.Check
                                                        id={'payment-cash'}
                                                        type="radio"
                                                        checked={form.card_id === card.id}
                                                        onChange={() =>
                                                            setForm({ ...form, card_id: card.id })
                                                        }
                                                    />
                                                </label>
                                            ))}

                                            <Button className="item col-12" onClick={addNewCard}>
                                                <i className="general fa fa-credit-card" />{' '}
                                                {t('Add New Card')}
                                                <span></span>
                                            </Button>
                                        </div>

                                        <div className="col-md-6" style={{ textAlign: 'center' }}>
                                            <img
                                                src="/subscription_pay.png"
                                                alt=""
                                                style={{ maxHeight: 250 }}
                                            />
                                        </div>
                                    </div>

                                    <div className="actions">
                                        <Button variant="secondary" onClick={prevStep}>
                                            <i className={'fa fa-chevron-' + t('left')} />{' '}
                                            {t('Previous')}
                                        </Button>
                                        <Button variant="primary" onClick={nextStep}>
                                            {t('Next')}{' '}
                                            <i className={'fa fa-chevron-' + t('right')} />
                                        </Button>
                                    </div>
                                </Tab.Pane>

                                <Tab.Pane eventKey="save">
                                    <h3>{t('Confirm Your Subscription')}</h3>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <img
                                                src="/subscription_cofirmation.png"
                                                alt=""
                                                style={{ margin: '50px auto', display: 'block' }}
                                            />
                                        </div>
                                        <div className="col-md-6 d-flex flex-column justify-content-center">
                                            <h3>{form.plan?.name}</h3>
                                            <h3>
                                                {form.plan?.current_price}{' '}
                                                {window.portalSetting('commerce.currency')}
                                            </h3>
                                        </div>
                                    </div>

                                    <br />
                                    <div>
                                        By submitting this form you agree to the terms of service
                                    </div>

                                    <div className="actions">
                                        <Button variant="secondary" onClick={prevStep}>
                                            <i className={'fa fa-chevron-' + t('left')} />{' '}
                                            {t('Previous')}
                                        </Button>
                                        <Button variant="primary" onClick={submit}>
                                            {t('Confirm')}
                                        </Button>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Card>
        </div>
    )
}

export default Subscribe
