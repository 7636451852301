import React from 'react'
import { Form } from 'react-bootstrap'
import t from 'counterpart'
import './IsBusinessSwitch.scss'

type IsBusinessSwitchProps = {
    isBusiness: boolean
    onChange: (value: boolean) => void
}

export const IsBusinessSwitch = ({ isBusiness, onChange }: IsBusinessSwitchProps) => {
    return (
        <div className="is-business-switch-wrapper">
            <Form.Group controlId="is_business" className=" form-group-is_business">
                <div className="fa fa-suitcase icon-is_business" />
                <Form.Label className="big-switch-label">{t('business_address')}</Form.Label>
                <Form.Check
                    name="is_business"
                    inline
                    type="switch"
                    checked={isBusiness}
                    onChange={(e) => onChange(e.target.checked)}
                    className="big-switch"
                />
            </Form.Group>
        </div>
    )
}
