import moment from 'moment'
import { Tab, Tabs } from 'react-bootstrap'
import * as t from 'counterpart'
import { useState } from 'react'
import FormSelect from 'react-bootstrap/FormSelect'

export const TabsSection = ({ product, reviews, faqs }) => {
    const defaultTab = {
        label: t('description'),
        value: 'description1',
    }
    const [selectedTab, setSelectedTab] = useState(defaultTab)

    const tabs = [
        defaultTab,
        ...(product.displaySetting || []).map((section) => ({
            label: section.title,
            value: section.title,
        })),
        { label: t('Reviews'), value: 'reviews' },
        { label: t('F & Q'), value: 'faq' },
    ]
    return (
        <div className="tabs-container " id="tabs">
            <TabsHeader tabs={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
            <TabsBody product={product} selectedTab={selectedTab} reviews={reviews} faqs={faqs} />
        </div>
    )
}

const TabsHeader = ({ tabs, selectedTab, setSelectedTab }) => {
    const handleTabChange = (event) => {
        setSelectedTab(tabs.find((option) => option.value === event))
    }
    return (
        <>
            <div className="tabs-desktop">
                <Tabs onSelect={(k) => handleTabChange(k)} activeKey={selectedTab.value}>
                    {tabs.map((tab) => (
                        <Tab
                            key={`${tab.value}${tab.label}`}
                            eventKey={tab.value}
                            title={tab.label}
                        />
                    ))}
                </Tabs>
            </div>
            <div className="tabs-mobile">
                <FormSelect
                    value={selectedTab.value}
                    options={tabs}
                    onChange={(e) => handleTabChange(e.target.value)}
                >
                    {tabs.map((tab, i) => (
                        <option key={`${tab.value}${tab.label}`} value={tab.value}>
                            {tab.label}
                        </option>
                    ))}
                </FormSelect>
            </div>
        </>
    )
}
const TabsBody = ({ product, selectedTab, reviews, faqs }) => {
    return (
        <div className="tabs-content">
            {selectedTab?.value === 'description1' && <MainDescriptionTab product={product} />}

            {(product.displaySetting || []).map(
                (section, i) =>
                    selectedTab.value === section.title && (
                        <div key={i}>
                            <DiscriptionTab section={section} i={i} product={product} />
                        </div>
                    )
            )}

            {selectedTab.value === 'reviews' && (
                <div>
                    <ReviewsTab reviews={reviews} />
                </div>
            )}
            {selectedTab.value === 'faq' && (
                <div>
                    <FaqTab faqs={faqs} />
                </div>
            )}
        </div>
    )
}

//Each Tabs Components
const MainDescriptionTab = ({ product }) => {
    return (
        <div
            className="description"
            dangerouslySetInnerHTML={{
                __html: product.description,
            }}
        />
    )
}
const DiscriptionTab = ({ section, product }) => {
    return (
        <div className="attributes">
            <div className="section-description">{section.description}</div>

            {/* <h6>{section.title}</h6> */}
            {(section.attributes_ids || []).map((attribute, j) =>
                product[attribute] ? (
                    <div key={j} className={'row ' + attribute}>
                        <div className="col-5 label">
                            <i className={'fa fa-caret-' + t('right')}></i>
                            {(product.attributesSetting[attribute] || {}).label || attribute}
                        </div>
                        <div className="col-7 value">{product[attribute]}</div>
                    </div>
                ) : null
            )}
        </div>
    )
}

const ReviewsTab = ({ reviews }) => {
    return (
        <>
            {(reviews?.data || []).map((review) => (
                <div className="review" key={review.id}>
                    <div className="date">{moment().from(review.created_at)}</div>
                    <h6>{review.user?.full_name || review.user?.first_name}</h6>
                    <div className="rating">
                        {[1, 2, 3, 4, 5].map((r) => (
                            <span key={r}>{r > review.rate ? '☆' : '★'}</span>
                        ))}
                    </div>
                    <p>{review.comment}</p>
                </div>
            ))}

            {(reviews.data || []).length === 0 && (
                <h5 style={{ padding: 10, color: '#aaaaaa' }}>
                    {t('There are no reviews right now')}
                </h5>
            )}
        </>
    )
}

const FaqTab = ({ faqs }) => {
    return (
        <>
            {(faqs.data || []).map((faq, i) => (
                <div className="faq" key={i}>
                    <h6>{faq.question}</h6>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: faq.answer,
                        }}
                    />
                </div>
            ))}

            {(faqs.data || []).length === 0 && (
                <h5 style={{ padding: 10, color: '#aaaaaa' }}>{t('There are no F&Q right now')}</h5>
            )}
        </>
    )
}
