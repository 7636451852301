import React from 'react'
import { Link, useParams } from 'react-router-dom'

import './style/Menu.scss'

export default React.forwardRef(function (props = {}, ref) {
    const { items } = props
    const store = useParams().store

    props = {
        ...props,
        className: 'menu-block ' + props.className,
    }

    delete props.items

    const renderSection = (menu, level = 1) => (
        <div className={'level-' + level}>
            <ul>
                {(menu.items || []).map((item, key) => {
                    let Wrapper = 'div'
                    let p = {}
                    if (item.link) {
                        Wrapper = Link
                        p = { to: `/${store}/${item.link}` }
                    }

                    return (
                        <li key={key} className={item.extra_class}>
                            <Wrapper {...p}>
                                {item.image && <img src={item.image} alt="" />}
                                {item.icon && <i className={item.icon} />}
                                <span className="text">{item.text}</span>
                                <span>{item.line}</span>
                            </Wrapper>

                            {item.items && renderSection(item, level + 1)}
                        </li>
                    )
                })}
            </ul>
        </div>
    )

    return (
        <div {...props} ref={ref}>
            {renderSection({ items })}
        </div>
    )
})
