import React, { useEffect, useState } from 'react'
import { Instagram } from 'react-content-loader'
import api from '../../../utilities/API'
import ProductBox from './ProductBox'
import './style/ProductsList.scss'

export default React.forwardRef(function (props = {}, ref) {
    const [products, setProducts] = useState({ data: [], placeholder: [1, 2, 3, 4, 5] })

    props = {
        ...props,
        className: 'products-list-block ' + props.className,
    }

    useEffect(() => {
        api('/commerce/products/', {
            result: (res) => setProducts(res.data),
        })
    }, [])

    return (
        <div {...props} ref={ref}>
            {props.title && <h3>{props.title}</h3>}
            {(products.data || []).map((product, key) => (
                <ProductBox key={key} product={product} />
            ))}
            {(products.placeholder || []).map((key) => (
                <Instagram key={key} className="placeholder" />
            ))}
        </div>
    )
})
