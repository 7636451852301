import storage from './utilities/storage'

const initialState = {
    loading: false,
    error: null,
    ready: false,
    user: null,
    menus: {},
    sliders: {},
    categories: storage('portal_categories') || {},
    permissions: {},
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'PORTAL_SET_USER':
            let user = { ...action.payload }

            user.can = function (permission) {
                if (!permission) {
                    return true
                }

                return user.permissions_list?.[permission]
            }

            return { ...state, ready: true, user: user }

        case 'PORTAL_SET_READY':
            return { ...state, ready: action.payload }

        case 'PORTAL_SET_MENUS':
            return { ...state, menus: action.payload }

        case 'PORTAL_SET_SLIDERS':
            return { ...state, sliders: action.payload }

        case 'PORTAL_SET_CATEGORIES':
            return { ...state, categories: action.payload }

        case 'PORTAL_ERROR':
            return { ...state, error: action.payload }

        default:
            return state
    }
}
