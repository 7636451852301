import moment from 'moment'
import { Helmet } from 'react-helmet'

export const Setup = ({ product, shareURL, ratingAverage }) => {
    const nextYear = moment().add(1, 'Y').locale('en_US').format('YYYY-MM-DD')

    return (
        <>
            <Helmet>
                {/* Primary Meta Tags  */}
                <title>{product.name}</title>
                <meta name="title" content={product.name} />
                <meta name="description" content={product.name} />

                {/* Open Graph / Facebook  */}
                <meta property="og:type" content="website" />
                <meta property="og:url" content={shareURL} />
                <meta property="og:title" content={product.name} />
                <meta property="og:description" content={product.name} />
                <meta property="og:image" content={product.thumbnail} />

                {/* Twitter */}
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={shareURL} />
                <meta property="twitter:title" content={product.name} />
                <meta property="twitter:description" content={product.name} />
                <meta property="twitter:image" content={product.thumbnail} />
            </Helmet>
            <script type="application/ld+json">
                {JSON.stringify({
                    '@context': 'http://schema.org',
                    '@type': 'Product',
                    name: product.name,
                    image: product.thumbnail,
                    description: product.name,
                    url: shareURL,
                    brand: {
                        '@type': 'Brand',
                        name: product.brand?.name,
                        logo: product.brand?.image,
                    },
                    offers: {
                        '@type': 'Offer',
                        price: product.current_price?.toFixed(2),
                        priceCurrency: window.portalSetting('commerce.currency', '', false),
                        priceValidUntil: nextYear,
                        availability: 'https://schema.org/InStock',
                        itemCondition: 'https://schema.org/NewCondition',
                    },
                    aggregateRating: {
                        '@type': 'AggregateRating',
                        ratingValue: ratingAverage,
                        ratingCount: product.reviewers || 1,
                    },
                })}
            </script>
        </>
    )
}
