import t from 'counterpart'

export const Steps = () => {
    return (
        <div id="steps">
            <div className="step active">
                <div>
                    <i className="fa fa-bag-shopping" />
                </div>
                <span>{t('Place Your Order')}</span>
            </div>
            <div className="line active"></div>
            <div className="step">
                <div>
                    <i className="fa fa-credit-card" />
                </div>
                <span>{t('Payment')}</span>
            </div>
            <div className="line"></div>
            <div className="step">
                <div>
                    <i className="fa fa-house-circle-check" />
                </div>
                <span>{t('Delivery')}</span>
            </div>
            <div className="line"></div>
            <div className="step">
                <div>
                    <i className="fa fa-check-double" />
                </div>
                <span>{t('delivered')}</span>
            </div>
        </div>
    )
}
