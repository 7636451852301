import { useEffect, useState } from 'react'
import api from '../../../../utilities/API'

export const useGetReviews = (id) => {
    const [reviews, setReviews] = useState({})

    useEffect(() => {
        api('/commerce/reviews/?product_id=' + id, {
            result: (data) => setReviews(data),
        })
    }, [id])

    return [reviews]
}
