import api from './utilities/API'
import storage from './utilities/storage'
import { Capacitor } from '@capacitor/core'
import { PushNotifications } from '@capacitor/push-notifications'

window.platform = Capacitor.getPlatform()

//
export function loadUser() {
    return function (dispatch) {
        if (!storage('access_token')) {
            dispatch({ type: 'PORTAL_SET_READY', payload: true })
            return
        }

        dispatch({ type: 'PORTAL_LOADING' })

        api('/auth/user', {
            result: (user) => dispatch({ type: 'PORTAL_SET_USER', payload: user }),

            error: (error, details) => {
                if (details.response && details.response.status === 401) {
                    dispatch({ type: 'PORTAL_SET_READY', payload: true })
                } else {
                    dispatch({ type: 'PORTAL_ERROR', payload: error })
                }
            },
        })
    }
}

//
export function loadCategories() {
    return function (dispatch) {
        api('/commerce/categories/?active=1', {
            result: ({ data }) => {
                let categories = {}
                ;(data || []).forEach((cat) => {
                    if (!categories[cat.parent_id]) {
                        categories[cat.parent_id] = []
                    }

                    categories[cat.parent_id].push(cat)
                })

                storage('portal_categories', categories)
                dispatch({ type: 'PORTAL_SET_CATEGORIES', payload: categories })
            },
        })
    }
}

//
export function setReady(isReady) {
    return function (dispatch) {
        dispatch({ type: 'PORTAL_SET_READY', payload: isReady })
    }
}

//
export function pushNotifications() {
    if (window.platform === 'web') {
        return
    }

    PushNotifications.requestPermission().then((result) => {
        if (result.granted) {
            PushNotifications.register()
        } else {
            // not granted
        }
    })

    PushNotifications.addListener('registration', (token) => {
        window.push_notifications_token = token.value
        console.log('Push Token:', token.value)
    })

    PushNotifications.addListener('pushNotificationReceived', (notification) => {
        console.log('Push received: ' + JSON.stringify(notification))
    })

    PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
        console.log('Push action performed: ' + JSON.stringify(notification))
    })
}
