const initialState = {
    isLoading: false,
    error: null,
    productError: null,
    items: [],
    isShippable: false,
    offCanvasCart: false,
}

export default function reducer(state = initialState, { type, payload }) {
    switch (type) {
        case 'CART_LOADING':
            return { ...state, isLoading: true, error: null }

        case 'CART_ERROR':
            return {
                ...state,
                isLoading: false,
                error: payload.message,
                productError: payload.productError,
            }

        case 'CART_ORDER_PLACED':
            return { ...state, isLoading: false, error: null, items: [] }

        case 'CART_TOGGLE_OffCanvas':
            return { ...state, offCanvasCart: payload }

        case 'CART_SET_ITEMS':
            const items = payload || []

            return {
                ...state,
                items,
                isShippable: !!items.find((i) => i.cart_shippable),
                isLoading: false,
                error: null,
            }

        default:
            return state
    }
}
