import { Alert, Button, Form } from 'react-bootstrap'
import t from 'counterpart'
import { useState } from 'react'
import API from '../../../../utilities/API'
import { AddressFormProps, ErrorsStateType } from './types'

export const AddressForm = ({
    newAddressForm,
    onAddressFormChange,
    onSubmitAddressSucess,
    is_order_business,
}: AddressFormProps) => {
    const [errors, setErrors] = useState<ErrorsStateType>({ validation: {} })
    const [loading, setLoading] = useState(false)
    // add new address
    const onNewAddress = () => {
        setErrors({ validation: {} })
        setLoading(true)
        API('commerce/addresses/', {
            method: 'POST',
            data: { ...newAddressForm, is_business: is_order_business },
            result: (data: any) => {
                setLoading(false)
                onSubmitAddressSucess(data)
            },
            error: (error: any, details: any) => {
                setLoading(false)
                if (details.response && details.response.status === 422)
                    error = details.response.data

                setErrors({ validation: {}, ...error })
            },
        })
    }

    return (
        <div className="mt-3">
            {errors.message && <Alert variant="danger">{t(errors.message)}</Alert>}
            <Form.Group controlId="address_name">
                <Form.Control
                    name="name"
                    value={newAddressForm.name || ''}
                    onChange={onAddressFormChange}
                />
                <div className="error">{t(errors?.validation?.name || ' ')}</div>
            </Form.Group>
            <div className="row" style={{ marginTop: 10 }}>
                <Form.Group controlId="country" className="col-md-4">
                    <Form.Label>{t('Country')}</Form.Label>
                    <Form.Control
                        name="country"
                        value={
                            newAddressForm.country
                                ? new Intl.DisplayNames([t.getLocale()], {
                                      type: 'region',
                                  }).of(newAddressForm.country)
                                : ''
                        }
                        disabled
                        onChange={onAddressFormChange}
                    />
                    <div className="error">{t(errors?.validation?.country || ' ')}</div>
                </Form.Group>

                <Form.Group controlId="state" className="col-md-4">
                    <Form.Label>{t('State')}</Form.Label>
                    <Form.Control
                        name="state"
                        value={newAddressForm.state || ''}
                        disabled
                        onChange={onAddressFormChange}
                    />
                    <div className="error">{t(errors?.validation?.state || ' ')}</div>
                </Form.Group>

                <Form.Group controlId="city" className="col-md-4">
                    <Form.Label>{t('City')}</Form.Label>
                    <Form.Control
                        name="city"
                        value={newAddressForm.city || ''}
                        disabled
                        onChange={onAddressFormChange}
                    />
                    <div className="error">{t(errors?.validation?.city || ' ')}</div>
                </Form.Group>
            </div>

            <Form.Group controlId="neighborhood">
                <Form.Label>{t('Neighborhood')}</Form.Label>
                <Form.Control
                    name="neighborhood"
                    value={newAddressForm.neighborhood || ''}
                    onChange={onAddressFormChange}
                />
                <div className="error">{t(errors?.validation?.neighborhood || ' ')}</div>
            </Form.Group>

            <Form.Group controlId="street">
                <Form.Label>{t('Street')}</Form.Label>
                <Form.Control
                    name="street"
                    value={newAddressForm.street || ''}
                    onChange={onAddressFormChange}
                />
                <div className="error">{t(errors?.validation?.street || ' ')}</div>
            </Form.Group>
            {is_order_business && (
                <>
                    <div className="row" style={{ marginTop: 10 }}>
                        <Form.Group controlId="postal_code" className="col-md-6">
                            <Form.Label>{t('Postal Code')}</Form.Label>
                            <Form.Control
                                name="postal_code"
                                value={newAddressForm.postal_code || ''}
                                onChange={onAddressFormChange}
                            />
                            <div className="error">{t(errors?.validation?.postal_code || ' ')}</div>
                        </Form.Group>
                        <Form.Group controlId="plot_number" className="col-md-6">
                            <Form.Label>{t('Plot Number')}</Form.Label>
                            <Form.Control
                                name="plot_number"
                                value={newAddressForm.plot_number || ''}
                                onChange={onAddressFormChange}
                            />
                            <div className="error">{t(errors?.validation?.plot_number || ' ')}</div>
                        </Form.Group>
                    </div>
                    <div className="row" style={{ marginTop: 10 }}>
                        <Form.Group controlId="registry_name" className="col-md-6">
                            <Form.Label>{t('Registry Name')}</Form.Label>
                            <Form.Control
                                name="registry_name"
                                value={newAddressForm.registry_name || ''}
                                onChange={onAddressFormChange}
                            />
                            <div className="error">
                                {t(errors?.validation?.registry_name || ' ')}
                            </div>
                        </Form.Group>
                        <Form.Group controlId="tax_number" className="col-md-6">
                            <Form.Label>{t('Tax Number')}</Form.Label>
                            <Form.Control
                                name="tax_number"
                                value={newAddressForm.tax_number || ''}
                                onChange={onAddressFormChange}
                            />
                            <div className="error">{t(errors?.validation?.tax_number || ' ')}</div>
                        </Form.Group>
                        <Form.Group controlId="registry_number" className="col-md-6">
                            <Form.Label>{t('Registry Number')}</Form.Label>
                            <Form.Control
                                name="registry_number"
                                value={newAddressForm.registry_number || ''}
                                onChange={onAddressFormChange}
                            />
                            <div className="error">
                                {t(errors?.validation?.registry_number || ' ')}
                            </div>
                        </Form.Group>
                    </div>
                </>
            )}
            <div className="row" style={{ marginTop: 10 }}>
                <Form.Group controlId="building" className="col-md-4">
                    <Form.Label>{t('Building')}</Form.Label>
                    <Form.Control
                        name="building"
                        value={newAddressForm.building || ''}
                        onChange={onAddressFormChange}
                    />
                    <div className="error">{t(errors?.validation?.building || ' ')}</div>
                </Form.Group>

                <Form.Group controlId="floor" className="col-md-4">
                    <Form.Label>{t('Floor')}</Form.Label>
                    <Form.Control
                        name="floor"
                        value={newAddressForm.floor || ''}
                        onChange={onAddressFormChange}
                    />
                    <div className="error">{t(errors?.validation?.floor || ' ')}</div>
                </Form.Group>

                <Form.Group controlId="apartment" className="col-md-4">
                    <Form.Label>{t('Apartment')}</Form.Label>
                    <Form.Control
                        name="apartment"
                        value={newAddressForm.apartment || ''}
                        onChange={onAddressFormChange}
                    />
                    <div className="error">{t(errors?.validation?.apartment || ' ')}</div>
                </Form.Group>
            </div>
            <Form.Group
                controlId="default"
                className="switch-wrapper-label"
                style={{ marginTop: 10 }}
            >
                <Form.Check
                    name="default"
                    type="switch"
                    checked={newAddressForm.default || false}
                    onChange={(e) =>
                        onAddressFormChange({
                            target: { name: 'default', value: e.target.checked },
                        })
                    }
                />
                <Form.Label>{t('Set as default address')}</Form.Label>
            </Form.Group>
            <Button
                variant="danger"
                onClick={onNewAddress}
                style={{ marginTop: 10 }}
                disabled={loading}
            >
                {t('Save Address')}
            </Button>
        </div>
    )
}
