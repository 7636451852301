import React, {useState, useEffect} from 'react';
import {Spinner} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import moment from "moment";
import * as t from 'counterpart';
import api from '../../utilities/API';

function ContentType()
{
    const [contents, setContents] = useState({loading: true, data: []});
    const { store } = useParams();

    useEffect(() => {
        api('/cms/contents/?&content_type=blog', {
            result: setContents
        })
    }, []);

    if(contents.loading) {
        return <Spinner animation="border" variant="primary" style={{margin: "100px auto"}} />
    }

    return (
        <div id="cms-content-type-page" className="container">
            <h1 style={{fontSize: 30, textAlign: "center", margin: "20px 0"}}>{t("Blog")}</h1>

            <div style={{display: "flex", flexWrap: "wrap"}}>
                {contents.data.map(content => (
                    <a
                        key={content.id}
                        href={`/${store}/${content.slug}`}
                        style={{
                            background: "#ddd",
                            width: "24%",
                            margin: "5px 0.5%",
                            padding: 30,
                            borderRadius: 10,
                            textAlign: "center",
                            minHeight: 200,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                        }}>
                        <h3>{content.title}</h3>
                        <span>{moment(content.created_at).fromNow()}</span>
                    </a>
                ))}
            </div>
        </div>
    );
}

export default ContentType;
