import { OverlayTrigger, Tooltip } from 'react-bootstrap'
export const ToolTip = ({ children, label }) => {
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {label}
        </Tooltip>
    )

    return (
        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
            {children}
        </OverlayTrigger>
    )
}
