import { CartItem } from './CartItem'
import { ProductItem } from './Types'

export const CartList = ({ products }: { products: ProductItem[] }) => {
    return (
        <div className="products-list">
            {products.map((product) => (
                <CartItem
                    key={product.item.id}
                    item={product.item}
                    cashBack={product.cashBack}
                    gift={product.gift}
                />
            ))}
        </div>
    )
}
