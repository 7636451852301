import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import * as t from 'counterpart'

function WarningDialog() {
    const [warning, setWarning] = useState(null)

    //
    useEffect(() => {
        window.alert = (msg) => {
            setWarning({ text: t(msg.toString() || 'Unknown Error') })
        }

        window.confirmDialog = (msg) => {
            return new Promise((resolve, reject) => {
                const text = t(msg.toString() || 'Unknown Error')
                setWarning({ resolve, reject, text })
            })
        }
    }, [])

    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={!!warning}
            onHide={() => setWarning(null)}
            dir={t('dir')}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">{t('Warning')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{warning?.text}</Modal.Body>
            <Modal.Footer className="d-flex justify-content-end">
                {warning?.reject && (
                    <Button
                        variant="secondary"
                        onClick={() => {
                            warning.reject()
                            setWarning(null)
                        }}
                    >
                        {t('Cancel')}
                    </Button>
                )}

                <Button
                    onClick={() => {
                        if (warning.resolve) {
                            warning.resolve(true)
                        }

                        setWarning(null)
                    }}
                >
                    {t('confirm')}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default WarningDialog
