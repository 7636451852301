import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'

import './style/Categories.scss'

let timer

export default React.forwardRef(function (props = {}, ref) {
    const categories = useSelector((state) => state.portal.categories)
    const { store } = useParams()
    const [active, setActive] = useState(false)
    const toSellers = props.toSellers

    props = {
        ...props,
        className: 'commerce-categories-block ' + (props.className || ''),
    }

    delete props.toSellers

    if (active) {
        props.className += ' active'
    }

    const renderLevel = (parent = { id: 0 }, level = 1) => (
        <ul className={'level-' + level}>
            {(categories[parent.id] || []).map((cat, key) => {
                const hasChildren = !!categories[cat.id]

                return (
                    <li key={key} className={hasChildren ? 'has-children' : ''}>
                        <Link
                            to={
                                toSellers
                                    ? `/${store}/sellers?category_id=${cat.id}`
                                    : `/${store}/browse/${cat.id}`
                            }
                            onClick={() => setActive(false)}
                        >
                            {cat.image && <img src={cat.image} alt="" />}
                            {cat.icon && <i className={cat.icon} />}
                            <span>{cat.name}</span>
                        </Link>

                        {hasChildren && renderLevel(cat, level + 1)}
                    </li>
                )
            })}

            {level === 2 && parent.description && (
                <li
                    className="category-offer"
                    dangerouslySetInnerHTML={{ __html: parent.description }}
                />
            )}
        </ul>
    )

    const onMouseEnter = () => {
        timer = setTimeout(() => setActive(true), 200)
    }

    const onMouseLeave = () => {
        clearTimeout(timer)
        setActive(false)
    }

    return (
        <div {...props} ref={ref} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            {/*<div className="dim" />*/}
            {renderLevel()}
        </div>
    )
})
