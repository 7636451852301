import React from 'react'
import * as t from 'counterpart'
import storage from '../../../utilities/storage'

import './style/LanguageSwitcher.scss'

export default React.forwardRef(function (props = {}, ref) {
    const current = t.getLocale()
    const locale = storage('locale') || {}

    props = {
        ...props,
        className: 'language-switcher-block ' + (props.className || ''),
    }

    return (
        <div {...props} ref={ref}>
            <a href={`/${locale.store || 'ksa'}-${current === 'en' ? 'ar' : 'en'}`} className="btn">
                {current === 'en' ? 'عربي' : 'English'}
            </a>
        </div>
    )
})
