import { lazy, useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Navigate, Routes, Route, useNavigate, useLocation } from 'react-router-dom'
import { applyMiddleware, combineReducers, createStore } from 'redux'
import * as serviceWorker from './serviceWorker'
import thunk from 'redux-thunk'
import moment from 'moment'
import t from 'counterpart'
import { Provider } from 'react-redux'
import reducer from './reducer'
import storage from './utilities/storage'
import apps from './apps'
import Portal from './Portal'
import View from './Layout/View'
import I18n from './i18n.js'
import api from './utilities/API.js'
import 'moment/locale/ar'
import { App as capApp } from '@capacitor/app'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

const Login = lazy(() => import('./pages/Login'))
const Account = lazy(() => import('./pages/Account'))
const AccountDevices = lazy(() => import('./pages/Account/Devices'))
const AccountNotifications = lazy(() => import('./pages/Account/notifications'))

// setting
window.portalSettingList = {}
window.portalSetting = function (name, defaultValue = '', i18n = true) {
    let v = window.portalSettingList[name] || defaultValue
    if (name === 'commerce.currency' && i18n) {
        return t(v)
    }

    return v
}

// routes
let routes = [
    <Route key="page" path="*" element={<View />} />,
    <Route key="auth" path={`/auth/:page`} element={<Login />} />,
    <Route key="account" path={`/account`} element={<Account />} />,
    <Route key="account_devices" path={`/account/devices`} element={<AccountDevices />} />,
    <Route
        key="account-notifications"
        path={`/account/notifications`}
        element={<AccountNotifications />}
    />,
]

let reducers = { portal: reducer },
    translations = {},
    blocks = {}

// setup the apps
apps.forEach((app) => {
    routes = [...routes, ...app.routes]
    reducers = { ...reducers, ...app.reducers }
    blocks = { ...blocks, ...app.blocks }
    translations.en = { ...translations.en, ...app.i18n.en }
    translations.ar = { ...translations.ar, ...app.i18n.ar }
})

window.viewBlocks = blocks

//
translations.en = { ...translations.en, ...I18n.en }
translations.ar = { ...translations.ar, ...I18n.ar }
t.setLocale('ar')

// setup translations
t.registerTranslations('en', translations.en)
t.registerTranslations('ar', translations.ar)
t.setMissingEntryGenerator(function (key) {
    return key.split('.')[1].replace(/_/g, ' ')
})

// to avoid error on empty string
const f = t.Instance.prototype.translate
t.Instance.prototype.translate = function (key, options) {
    return f.apply(this, [key || ' ', options])
}

//
export const reduxStore = createStore(combineReducers(reducers), applyMiddleware(thunk))

const home = process.env.REACT_APP_DEFAULT_HOME || '' // /pos | /confirmation
const render = (setting = {}) => {
    // get locale and prefix
    const base = window.location.pathname
    let locale = {}
    if (base === '/') {
        locale = storage('locale') || {}
    } else {
        const l = base.split('/')[1].split('-')
        locale = { store: l[0], lang: l[1] }
    }

    // lang
    if (locale.lang !== 'ar' && locale.lang !== 'en') {
        locale.lang = setting.default_language || 'ar'
    }
    window.portal_lang = locale.lang
    moment.locale(locale.lang)

    // set store id
    if (setting.stores) {
        let selectStore = setting.stores.find((s) => s.store_code === locale.store)
        if (!selectStore) {
            selectStore = setting.stores[0] || {}
        }

        window.portal_store_id = selectStore.id
        if (!locale.store) {
            locale.store = selectStore.store_code
        }

        storage('theme_key', selectStore.theme_key)
    }

    // set locale
    t.setLocale(locale.lang)
    storage('locale', locale)
    storage('lang', locale.lang, true)

    // set direction
    var htmlElm = document.getElementsByTagName('html')[0] // '0' to assign the first (and only `HTML` tag)
    htmlElm.setAttribute('class', t('dir'))
    htmlElm.setAttribute('dir', t('dir'))
    htmlElm.setAttribute('lang', t.getLocale())

    // todo: workaround for bootstrap rtl, remove it when the issue is fixed and check index.html (bootstrap-rtl / bootstrap-ltr) elements
    document.getElementById('bootstrap-' + (t('dir') === 'rtl' ? 'ltr' : 'rtl'))?.remove()

    const App = () => {
        const navigate = useNavigate()
        const location = useLocation()

        useEffect(() => {
            const backButtonListener = capApp.addListener('backButton', ({ canGoBack }) => {
                if (!canGoBack) {
                    capApp.exitApp() // Exits the app if there's no page to go back to
                } else {
                    navigate(-1) // Uses React Router's navigate function to go back
                }
            })

            return () => {
                backButtonListener.remove()
            }
        }, [navigate])

        return (
            <Routes>
                <Route
                    path="/"
                    exact
                    element={<Navigate to={'/' + locale.store + '-' + locale.lang + home} />}
                ></Route>
                <Route path="/:store/*" element={<Portal routes={routes} />}></Route>
            </Routes>
        )
    }

    // render the app
    const root = ReactDOM.createRoot(document.getElementById('root'))
    root.render(
        <Provider store={reduxStore}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    )
}

api('/init', {
    result: (setting) => {
        window.portalSettingList = setting || {}

        if (setting['web.script']) {
            const newContent = document
                .createRange()
                .createContextualFragment(setting['web.script'])
            document.head.append(newContent)
        }

        if (setting['web.css']) {
            document.head.appendChild(document.createElement('style')).innerHTML =
                setting['web.css']
        }

        render(setting)
    },
    error: () => render(),
})

serviceWorker.unregister()

// todo: generate public/manifest.json based on the app web/pos/confirmation
