import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom'
import { Form, Button, Accordion } from 'react-bootstrap'
import Select from 'react-select'
import Slider from 'react-slider'
import ReactPaginate from 'react-paginate'
import * as t from 'counterpart'
import qs from 'qs'
import ProductBox from './Blocks/ProductBox'
import ProductsCarousel from './Blocks/ProductsCarousel'
import api from '../../utilities/API'

import './style/Category.scss'

const MaxPrice = 10000

function Category() {
    const navigate = useNavigate()
    const location = useLocation()
    const { store, category } = useParams()
    const categories = useSelector((state) => state.portal.categories)
    const [brands, setBrands] = useState([])
    const [products, setProducts] = useState({ isLoading: true, data: [] })
    const [filters, setFilters] = useState(null)
    const [gridView, setGridView] = useState(true)
    const [filtersSwitcher, setFiltersSwitcher] = useState(false)

    // load products
    useEffect(() => {
        if (!filters) {
            return
        }

        setProducts((products) => ({ ...products, isLoading: true }))

        api('/commerce/products/?limit=32&' + qs.stringify(filters, { arrayFormat: 'brackets' }), {
            result: (data) => setProducts(data || {}),
        })
    }, [filters])

    // set filters from url
    useEffect(() => {
        const f = qs.parse(location.search.replace('?', ''))

        setFilters({
            with_filters: 1,
            category_id: category,
            ...f,
        })
    }, [category, location.search])

    // brands
    useEffect(() => {
        api('/commerce/brands/', {
            result: ({ data }) => setBrands(data),
        })
    }, [])

    //
    const update = (filter, value, array = false, removedValue = null) => {
        let newFilters = { ...filters }
        if (filter === 'price') {
            newFilters['price_from'] = value[0]
            newFilters['price_to'] = value[1]
        }

        if (value !== null) {
            if (array) {
                value = [...(newFilters[filter] || []), value]
            }

            newFilters[filter] = value
        } else {
            if (array && removedValue && Array.isArray(newFilters[filter])) {
                newFilters[filter].splice(newFilters[filter].indexOf(removedValue.toString()), 1)
            } else {
                delete newFilters[filter]
            }
        }

        if (filter !== 'page') {
            delete newFilters.page
        }

        navigate(`/${store}/browse?` + qs.stringify(newFilters))
    }

    // categories
    const categoriesList = (parent = 0, level = 1) => {
        let c = []
        ;(categories[parent] || []).forEach((category) => {
            const isChild = category.children?.includes(products?.category?.id)
            if (
                products.category &&
                !isChild &&
                !products.category.children?.includes(category.id)
            ) {
                return
            }

            c.push(
                <Link
                    key={category.id}
                    className={category.id === products.category?.id || isChild ? 'active' : ''}
                    to={`/${store}/browse/` + category.id}
                >
                    {category.name}
                </Link>,
                <div key={category.id + 'c'} className="sub">
                    {categoriesList(category.id, level + 1)}
                </div>
            )
        })

        return c
    }

    return (
        <div id="commerce-category-page" className={'container ' + (!gridView ? 'list-view' : '')}>
            <div className="row">
                <div className="col-md-3">
                    <div id="products-filters" className={filtersSwitcher ? 'opened' : ''}>
                        <Button
                            className="filters-switcher fas fa-times"
                            variant="link"
                            onClick={() => setFiltersSwitcher(false)}
                        />

                        <div className="section" style={{ padding: 10 }}>
                            <h5>{t('Price')}</h5>
                            <Slider
                                className="range-slider"
                                invert
                                min={0}
                                max={MaxPrice}
                                value={[
                                    parseInt(filters?.price_from || 0),
                                    parseInt(filters?.price_to || MaxPrice),
                                ]}
                                onAfterChange={(value) => update('price', value)}
                                renderThumb={(props, state) => (
                                    <div {...props}>
                                        <span>
                                            {state.valueNow +
                                                (state.valueNow === MaxPrice ? '+' : '')}
                                        </span>
                                    </div>
                                )}
                            />

                            <br />
                            <Form.Check
                                label={t('Offers')}
                                onChange={(e) => update('offers', e.target.checked ? 1 : '')}
                            />
                        </div>

                        <Accordion alwaysOpen defaultActiveKey="category">
                            <Accordion.Item className="section" eventKey="category">
                                <Accordion.Header>{t('Categories')}</Accordion.Header>
                                <Accordion.Body>
                                    <div id="categories-filter" className="section">
                                        <Link
                                            className={!products.category ? 'active' : ''}
                                            to={`/${store}/browse`}
                                        >
                                            {t('All')}
                                        </Link>
                                        {categoriesList()}
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item className="section" eventKey="brands">
                                <Accordion.Header>{t('Brands')}</Accordion.Header>
                                <Accordion.Body>
                                    {brands
                                        .filter((b) =>
                                            Object.keys(products?.filters?.brands || {}).includes(
                                                b.id.toString()
                                            )
                                        )
                                        .map((brand) => (
                                            <div key={brand.id} className="choice">
                                                <input
                                                    type="checkbox"
                                                    checked={
                                                        filters &&
                                                        filters['brand_id']?.includes(
                                                            brand.id.toString()
                                                        )
                                                    }
                                                    onChange={(e) =>
                                                        update(
                                                            'brand_id[]',
                                                            e.target.checked ? brand.id : null,
                                                            true,
                                                            brand.id
                                                        )
                                                    }
                                                />
                                                <div>{brand.name}</div>
                                            </div>
                                        ))}
                                </Accordion.Body>
                            </Accordion.Item>

                            {Object.entries((products.filters || {}).options || {}).map(
                                ([filter, options], i) => {
                                    const f = filters?.a?.[filter]

                                    return (
                                        <Accordion.Item key={i} className="section" eventKey={i}>
                                            <Accordion.Header>
                                                {products.filters.labels[filter] || filter}
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                {(options || []).map((option, i) => (
                                                    <div key={i} className="choice">
                                                        <input
                                                            type="checkbox"
                                                            checked={f?.includes(option.value)}
                                                            onChange={(e) =>
                                                                update(
                                                                    'a[' + filter + ']',
                                                                    e.target.checked
                                                                        ? option.value
                                                                        : null
                                                                )
                                                            }
                                                        />
                                                        <div>{option.value}</div>
                                                        <span>{option.total}</span>
                                                    </div>
                                                ))}

                                                {/*<div className="all">*/}
                                                {/*<i className="fa fa-chevron-left"/> مشاهدة الكل*/}
                                                {/*</div>*/}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    )
                                }
                            )}
                        </Accordion>
                    </div>
                </div>

                <div className="col-md-9">
                    {products.seller && (
                        <div>
                            <h3>{products.seller.name}</h3>
                        </div>
                    )}

                    <div className="head">
                        <div className="wrap">
                            <h3>{(products.category || {}).name || t('Products')}</h3>
                            {products.isLoading && <i className="fa fa-spinner fa-spin" />}
                        </div>

                        <div className="sort-by">
                            <span>{t('Sort By')}</span>
                            <Select
                                defaultValue={{ label: t('Special Products'), value: '-1' }}
                                autoComplete={false}
                                onChange={({ value }) => update('order_by', value)}
                                options={[
                                    { label: t('Special Products'), value: '-1' },
                                    { label: t('Popular'), value: 'orders' },
                                    { label: t('Views'), value: 'views' },
                                    { label: t('reviews'), value: 'stars' },
                                    { label: t('Highest Price'), value: 'price_desc' },
                                    { label: t('Lowest Price'), value: 'price_asc' },
                                    { label: t('Oldest'), value: 'asc' },
                                    { label: t('Latest'), value: 'desc' },
                                ]}
                            />
                        </div>

                        <Button
                            className={'view-switcher fas fa-' + (gridView ? 'grip' : 'bars')}
                            variant="outer-link"
                            onClick={() => setGridView(!gridView)}
                        />
                        <Button
                            className="filters-switcher fas fa-filter"
                            variant="outer-link"
                            onClick={() => setFiltersSwitcher(!filtersSwitcher)}
                        />
                    </div>

                    <div id="products-list">
                        {(products.data || []).map((p) => (
                            <ProductBox key={p.id} product={p} />
                        ))}
                        {(products.data || []).length === 0 && (
                            <h6 style={{ margin: 40 }}>لا يوجد منتجات متاحة</h6>
                        )}
                    </div>

                    {!products.isLoading && (
                        <ReactPaginate
                            forcePage={products.current_page - 1}
                            pageCount={products.to}
                            onPageChange={({ selected }) => update('page', selected + 1)}
                            previousLabel={t('Previous')}
                            nextLabel={t('Next')}
                            className="pagination"
                            pageClassName="page-item"
                            previousClassName="page-item"
                            nextClassName="page-item"
                            breakClassName="page-item"
                            activeClassName="active"
                            pageLinkClassName="page-link"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            breakLinkClassName="page-link"
                        />
                    )}
                </div>
            </div>

            <ProductsCarousel
                title={t('You May Also Like')}
                cardsNumber={4}
                hideDots
                filters={{ order_by: 'views' }}
            />
        </div>
    )
}

export default Category
