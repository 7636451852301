import t from 'counterpart'
import { Button, Form } from 'react-bootstrap'
import { placeOrder } from '../actions'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

// SummaryProp
export const Summary = ({ totals, cart, order, setOrder, review, setReview }) => {
    const store = useParams().store

    // steps feedback
    const contactInfoDone = order.name && order.phone && order.email

    const shippingDone =
        !cart.isShippable ||
        (order.shipping_method_id && (!order.addressAddress || order.address_id))
    const canContinue = !cart.isLoading && contactInfoDone && shippingDone

    const includeCashback = !(window.portalSetting('commerce.cashback.type') === 'included')

    const subtotal = totals.total.toLocaleString()
    const total = includeCashback ? totals.total : totals.total - totals.totalCashBack
    return (
        <div id="summary">
            <div id="total">
                {totals.totalSaved !== 0 && <h6 className="col-6 saved">{t('Total Saved')}</h6>}
                {totals.totalSaved !== 0 && (
                    <div className="col-6 saved"> {totals.totalSaved.toLocaleString()}</div>
                )}
                {!includeCashback && (
                    <>
                        {' '}
                        <h6 className="col-6">{t('subtotal')}</h6>
                        <div className="col-6"> {subtotal.toLocaleString()}</div>
                    </>
                )}
                <h6 className="col-6">{t('VAT')}</h6>
                <div className="col-6"> {((totals.total / 1.15) * 0.15).toLocaleString()}</div>
                {!includeCashback && (
                    <>
                        <h6 className="col-6">{t('Discount(Tax gift)')}</h6>
                        <div className="col-6"> {totals.totalCashBack.toLocaleString()}</div>
                    </>
                )}
                <h6 className="col-6">{t('Total')}</h6>
                <div className="col-6"> {total.toLocaleString()}</div>
            </div>
            {!review && <CheckBoxes setOrder={setOrder} order={order} store={store} cart={cart} />}

            <Actions
                cart={cart}
                review={review}
                setReview={setReview}
                canContinue={canContinue}
                order={order}
                store={store}
            />
        </div>
    )
}

const CheckBoxes = ({ setOrder, order, store, cart }) => {
    return (
        <div>
            <Form.Check
                id="terms"
                type="checkbox"
                onChange={(e) => setOrder({ ...order, consent: e.target.checked })}
                label={
                    <>
                        {t("I've read and agreed to the")}
                        <a href={`/${store}/terms`} target="_blank" rel="noreferrer">
                            {t('terms and conditions agreement')}
                        </a>
                    </>
                }
            />
            {cart.isShippable && (
                <Form.Check
                    id="shipping-terms"
                    type="checkbox"
                    onChange={(e) => setOrder({ ...order, shipping_consent: e.target.checked })}
                    label={
                        <>
                            {t("I've read and agreed to the")}
                            <a href={`/${store}/delivery`} target="_blank" rel="noreferrer">
                                {t('shipping and installation agreement')}
                            </a>
                        </>
                    }
                />
            )}
        </div>
    )
}

const Actions = ({ cart, setReview, canContinue, order, store, review }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const onPlaceOrder = () => {
        let newOrder = { ...order }
        delete newOrder.shippingMethod
        delete newOrder.paymentMethod

        if (!cart.isLoading) {
            dispatch(placeOrder(newOrder, navigate, store))
        }
    }

    if (review)
        return (
            <div style={{ display: 'flex' }}>
                <Button
                    className="continue"
                    onClick={() => setReview(false)}
                    style={{ marginLeft: 5, marginRight: 5 }}
                >
                    {!cart.isLoading && <i className={'fa fa-chevron-' + t('left')} />}
                </Button>

                <Button className="continue" disabled={!canContinue} onClick={onPlaceOrder}>
                    {!cart.isLoading && (
                        <>
                            <i className="fa fa-money-bill" /> {t('Place Your Order')}
                        </>
                    )}
                    {cart.isLoading && (
                        <>
                            <i className="fa fa-spinner fa-spin" /> {t('Loading')}
                        </>
                    )}
                </Button>
            </div>
        )
    if (!review)
        return (
            <Button
                className="continue review"
                disabled={!canContinue}
                onClick={() => {
                    if (!order.consent || (cart.isShippable && !order.shipping_consent)) {
                        alert('You must agree to the terms before you can proceed')
                        return
                    }

                    setReview(true)
                }}
                // style={{ backgroundColor: '#10c600' }}
            >
                {t('Review Your Order')}
            </Button>
        )
}
