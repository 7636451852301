import React, { useState } from 'react'
import * as t from 'counterpart'
import { Alert, Button } from 'react-bootstrap'
import { Navigate } from 'react-router-dom'
import api from '../../../utilities/API'

export const ReviewsSummery = ({ product, store, user, id }) => {
    const [newReview, setNewReview] = useState({})

    // send review
    const sendReview = () => {
        api('/commerce/reviews/', {
            method: 'POST',
            data: { ...newReview, product_id: id },
            result: () => setNewReview({ rate: newReview.rate, success: true }),
            error: (error) => {
                if (error === 'You did it before') {
                    error = t('You did review it before')
                }

                setNewReview({ ...newReview, error })
            },
        })
    }

    return (
        <div id="reviews-summery">
            <div id="summery">
                <h3>{t('Reviews Summary')}</h3>

                {[5, 4, 3, 2, 1].map((rate) => (
                    <div className="line" key={rate}>
                        <div>★</div>
                        <div>{rate}</div>
                        <div className="progress">
                            <div
                                style={{
                                    width:
                                        (product.reviews_summary[rate] /
                                            product.reviews_summary.total) *
                                            100 +
                                        '%',
                                }}
                            />
                        </div>
                        <div>{product.reviews_summary[rate] || 0}</div>
                    </div>
                ))}
            </div>

            <div id="add-review">
                <h3>{t('Share your review')}</h3>
                {t('Share your buying experience')}

                {newReview.success && <Alert variant="success">تم إضافة التقييم بنجاح</Alert>}

                <div className="rating">
                    {[5, 4, 3, 2, 1].map((r) => (
                        <span
                            key={r}
                            className={'fas fa-star ' + (newReview.rate >= r ? 'active' : '')}
                            onClick={() => {
                                if (!user) {
                                    Navigate(
                                        `/${store}/auth/login?from=/${store}/details/${product.slug}-${product.id}`
                                    )
                                    return
                                }

                                if (!newReview.success) {
                                    setNewReview({ ...newReview, rate: r })
                                }
                            }}
                        />
                    ))}
                </div>

                {newReview.rate && !newReview.success && (
                    <div>
                        {newReview.error && <Alert variant="danger">{newReview.error}</Alert>}
                        <textarea
                            value={newReview.comment}
                            onChange={(e) =>
                                setNewReview({ ...newReview, comment: e.target.value })
                            }
                        />
                        <Button onClick={() => sendReview()}>مشاركة</Button>
                    </div>
                )}
            </div>
        </div>
    )
}
