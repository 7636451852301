import axios from 'axios'
import * as t from 'counterpart'
import storage from './storage'

export const apiKey = process.env.REACT_APP_API_KEY || '2853152294a192f18c3da51ae965f'
export const baseURL = process.env.REACT_APP_API_BASE_URL.replace(
    '%HOST%',
    window.location.protocol + '//' + window.location.host
)

//
const queueHandler = (response) => {
    response.headers = response.headers || {}
    if (window.location.search.indexOf('queue') !== -1) {
        response.headers._queued = 'size:1000,passed:400,number:700,until:170'
    }

    if (response.headers._queued) {
        let queue = {}

        response.headers._queued.split(',').forEach((v) => {
            const p = v.split(':')
            if (p.length === 2) {
                queue[p[0]] = parseInt(p[1])
            }
        })

        queue.passedPercentage = (queue.passed / queue.size) * 100 + '%'
        queue.numberPercentage = (queue.number / queue.size) * 100 + '%'

        document.dispatchEvent(new CustomEvent('queue', { detail: queue }))
    }
}

// http todo: improve overall
function API(url, request) {
    // set initial setting
    request = {
        url: url,
        method: 'GET',
        data: {},
        headers: {},
        responseType: 'json',
        result: () => {},
        error: (e, d) => console.log('API Unhandled Error:', d),
        ...request,
    }

    let isDownload = false
    if (request.blob) {
        isDownload = true
        request.responseType = 'blob'
    }

    // convert data object to form data
    let formData = new FormData()

    if (!request.data.store_id) {
        formData.append('store_id', window.portal_store_id)
    }

    for (let key in request.data) {
        let value = request.data[key]
        const isArray = value instanceof Array
        if (isObject(value)) {
            value = JSON.stringify(value)
        }

        if (isArray) {
            value.map((v) => formData.append(key + '[]', isObject(v) ? JSON.stringify(v) : v))
        } else {
            formData.append(key, value || '')
        }
    }

    // handle put and form-data issue
    if (request.method === 'PUT') {
        request.method = 'POST'
        formData.append('_method', 'PUT')
    }

    // todo: check if the token about to expire refresh it
    const token = (storage('access_token') || {}).access_token

    // make the request
    return axios({
        baseURL: baseURL,
        url: request.url,
        method: request.method,
        data: formData,
        timeout: 50000,
        responseType: request.responseType,
        withCredentials: true,
        headers: {
            'X-Api-Key': apiKey,
            Authentication: token,
            'Content-Type': 'application/x-www-form-urlencoded',
            Language: t.getLocale(),
            Store: window.portal_store_id,
            Interface: window.portal_interface,
            ...request.headers,
        },
    })
        .then((response) => {
            queueHandler(response)

            //
            if (isDownload) {
                let link = document.createElement('a')
                link.href = window.URL.createObjectURL(response.data)
                link.download =
                    (response.headers['content-disposition'] || '=').split('=')[1] || 'Download'
                link.click()
                return
            }

            //
            const data = response.data || {}
            request.result(data)
        })
        .catch((error) => {
            let msg = ''
            if (error.response) {
                queueHandler(error.response)

                if (isDownload) {
                    msg = error.response.statusText
                } else {
                    if (error.response.status === 401) {
                        msg = 'Unauthorized'
                    } else if (error.response.status === 423) {
                        msg = "You are in a queue right now, Please try again when you're in"
                    } else {
                        const data = error.response.data || {}
                        msg = data.message || data.error || 'Unknown Server Error'
                        if (typeof msg === 'object') {
                            msg = msg.message || msg.error || 'Unknown Server Error'
                        }
                    }
                }
            } else if (error.request) {
                msg = 'Unable to connect to the server, Check your internet connection'
            } else {
                msg = error.message
            }

            request.error(t(msg || 'Request Error'), error)
        })
}

//
function isObject(value) {
    return (
        value !== null &&
        typeof value === 'object' &&
        !(value instanceof Array) &&
        !(value instanceof File)
    )
}

// websocket
export function webSocket(url) {
    url = baseURL.replace('http', 'ws') + url
    if (!url.includes('?')) {
        url += '?'
    }

    const tokens = storage('access_token') || {}
    return new WebSocket(url + '&authentication=' + tokens.access_token)
}

// expiredTokenHandler
let tokenExpired = false
let expiredRequests = []

async function expiredTokenHandler(err) {
    const originalConfig = err.config
    if (!err.response || err.response.status !== 401 || originalConfig._retry) {
        return Promise.reject(err)
    }

    originalConfig._retry = true
    try {
        if (!tokenExpired) {
            tokenExpired = true
            const refreshToken = (storage('access_token') || {}).refresh_token
            if (!refreshToken) {
                return Promise.reject(err)
            }

            // await API("/auth/refresh", {
            API('/auth/refresh', {
                method: 'post',
                data: { 'refresh-token': refreshToken },
                result: (data) => {
                    const newTokens = {
                        access_token: data.access_token,
                        refresh_token: data.refresh_token,
                    }
                    storage('access_token', newTokens)
                    storage('PORTTOKEN', newTokens.access_token, true)

                    tokenExpired = false
                    expiredRequests.map((request) => request(newTokens.access_token))
                    expiredRequests = []
                },
                error: () => {
                    storage('access_token', null)
                    storage('PORTTOKEN', null, true)
                    window.location.reload()
                },
            })
        }

        return new Promise((resolve) => {
            expiredRequests.push((token) => {
                originalConfig.headers.Authentication = token
                resolve(axios(originalConfig))
            })
        })
    } catch (_err) {
        console.log('API _err:', _err)
    }

    return Promise.reject(err)
}

axios.interceptors.response.use((res) => res, expiredTokenHandler)

//
export function apiStream(endpoint, body, results) {
    const form = new FormData()
    for (const key in body) {
        form.append(key, body[key])
    }

    fetch(baseURL + endpoint, {
        method: 'POST',
        headers: {
            'X-Api-Key': apiKey,
            Authentication: (storage('access_token') || {}).access_token,
        },
        body: form,
    })
        .then((response) => {
            const reader = response.body.getReader()
            const decoder = new TextDecoder()
            reader.read().then(function processResult(result) {
                if (result.done) {
                    return
                }
                const chunk = decoder.decode(result.value, { stream: true })
                results(chunk)
                return reader.read().then(processResult)
            })
        })
        .catch((error) => {
            console.log(error)
        })
}

export default API
