const commerceInit = {
    reducers: {},
    routes: [],
    i18n: {
        en: {},
        ar: {},
    },
}

export default commerceInit
