import { Form } from 'react-bootstrap'
import t from 'counterpart'
import Select from 'react-select'
import './DeliveryContactInfo.scss'

type DeliveryContactInfoProps = {
    onContactChange: (event: { target: { name: string; value: any } }) => void
    review?: boolean
    order: {
        delivery_name: string
        delivery_phone: string
        delivery_email: string
        delivery_time: string
    }
}

export const DeliveryContactInfo = ({
    onContactChange,
    order,
    review = false,
}: DeliveryContactInfoProps) => {
    const DELIVERY_TIME = [
        {
            value: '09:00 - 12:00 ' + t('AM'),
            label: '09:00 - 12:00 ' + t('AM'),
        },
        {
            value: '04:00 - 09:00 ' + t('PM'),
            label: '04:00 - 09:00 ' + t('PM'),
        },
    ]

    return (
        <div id="contact-info">
            <h3>{t('Delivery Information')}</h3>
            <div className="row content-wrapper">
                <Form.Group controlId="name" className="col-md-3">
                    <Form.Label>{t('Full Name')}</Form.Label>
                    <Form.Control
                        name="delivery_name"
                        value={order.delivery_name}
                        onChange={onContactChange}
                    />
                </Form.Group>

                <Form.Group controlId="phone" className="col-md-3">
                    <Form.Label>{t('Mobile')}</Form.Label>
                    <Form.Control
                        name="delivery_phone"
                        value={order.delivery_phone}
                        onChange={onContactChange}
                    />
                </Form.Group>

                <Form.Group controlId="email" className="col-md-3">
                    <Form.Label>{t('Email')}</Form.Label>
                    <Form.Control
                        name="delivery_email"
                        value={order.delivery_email}
                        onChange={onContactChange}
                    />
                </Form.Group>

                <Form.Group controlId="delivery_time" className="col-md-3">
                    <Form.Label>{t('Delivery Time')}</Form.Label>
                    {review ? (
                        <div>{order.delivery_time}</div>
                    ) : (
                        <Select
                            placeholder={t('Select')}
                            value={
                                order.delivery_time
                                    ? {
                                          value: order.delivery_time,
                                          label: order.delivery_time,
                                      }
                                    : null
                            }
                            options={DELIVERY_TIME}
                            onChange={(l) =>
                                onContactChange({
                                    target: {
                                        name: 'delivery_time',
                                        value: l?.value,
                                    },
                                })
                            }
                        />
                    )}
                </Form.Group>
            </div>
        </div>
    )
}
