import React, { useState, useEffect } from 'react'
import './style.scss'

function SafeZone({ data }) {
    const [counter, setCounter] = React.useState(data.until)
    const [bgIndex] = useState((Math.random() * 6).toFixed())

    useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000)
    }, [counter])

    const bg = [
        {
            color: '#ffc6d3',
            src: 'https://cdn.dribbble.com/users/59947/screenshots/11608350/media/6ed0d4b174c10208960de1f75584b472.mp4',
        },
        {
            color: '#69e6ff',
            src: 'https://cdn.dribbble.com/users/13754/screenshots/8214123/media/1f5ba8946d517fba941c248fd415f647.mp4',
        },
        {
            color: '#aeecdb',
            src: 'https://cdn.dribbble.com/users/989157/screenshots/4632455/loading-animation-bored-hand.gif',
            isGIF: true,
        },
        {
            color: '#fff0eb',
            src: 'https://cdn.dribbble.com/users/1976516/screenshots/8813421/media/118cea9cc0fff87af1d71c9550627eb2.gif',
            isGIF: true,
        },
        {
            color: '#ffffff',
            src: 'https://cdn.dribbble.com/users/2059463/screenshots/5432270/tus_invitaciones.gif',
            isGIF: true,
        },
        {
            color: '#ffffff',
            src: 'https://cdn.dribbble.com/users/1813781/screenshots/5597337/dribbble-girl-with-clock.gif',
            isGIF: true,
        },
        {
            color: '#f3f3f5',
            src: 'https://cdn.dribbble.com/users/3356707/screenshots/14054620/media/260491a8195df1312ec78a46cd7f6d23.gif',
            isGIF: true,
        },
    ][bgIndex]

    return (
        <div id="safe-zone" style={{ backgroundColor: bg.color }}>
            <div className="container">
                {!bg.isGIF && <video preload="auto" muted loop playsinline autoPlay src={bg.src} />}
                {bg.isGIF && <img src={bg.src} alt="" />}
                <div className="wrap">
                    <h4>Due to the huge traffic you will continue in:</h4>
                    <div className="size bar">
                        <span>Total: {data.size}</span>
                        <div className="number" style={{ left: data.numberPercentage }}>
                            You: {data.number}
                        </div>
                        <div className="passed bar" style={{ width: data.passedPercentage }}>
                            <span>Passed: {data.passed}</span>
                        </div>
                    </div>

                    <div>
                        There are <strong>{data.number - data.passed}</strong> ahead of you
                    </div>

                    {data.until < 100 && (
                        <h2>{new Date(counter * 1000).toISOString().substr(11, 8)}</h2>
                    )}
                </div>
            </div>
        </div>
    )
}

export default SafeZone
