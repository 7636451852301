import React from 'react'
import { Route } from 'react-router-dom'

const List = React.lazy(() => import('./list'))
const Form = React.lazy(() => import('./form'))

const plyInit = {
    reducers: {},
    routes: [
        <Route key="ply_list" exact path="/:app/manage/:entity" element={<List />} />,
        <Route key="ply_create" exact path="/:app/manage/:entity/create" element={<Form />} />,
        <Route key="ply_create_subEntity" exact path="/:app/manage/:entity/create/:subEntity" element={<Form />} />,
        <Route key="ply_edit" exact path="/:app/manage/:entity/edit/:id" element={<Form />} />,
        <Route key="ply_list" exact path="/:app/manage/:entity/:subEntity" element={<List />} />,
    ],
    i18n: {
        en: {
            id: '#',
            updated_at: 'Last Updated',
            parent_id: 'Parent',
        },
        ar: {
            id: '#',
            name: 'الإسم',
            slug: 'المعرف الفريد',
            updated_at: 'أخر تحديث',
            thumbnail: 'الصورة',
            'Add New': 'إضافة جديد',
            status: 'الحالة',
            active: 'نشط',
            draft: 'مسودة',
            Arabic: 'العربية',
            English: 'الإنجليزية',
            label: 'الإسم',
            type: 'النوع',
            image: 'الصورة',
            email: 'البريد الإلكتروني',
            templates: 'القوالب',
            channels: 'القنوات',
            SMS: 'الرسائل القصيرة',
            'Voice Call': 'مكالمة صوتية',
            pages: 'الصفحات',
            body: 'المحتوي',
            title: 'العنوان',
            Forbidden: 'لا تملك الصلاحيات المطلوبة',
            methods: 'الطرق',
            categories: 'التصنيفات',
            View: 'عرض',
            Preview: 'معاينة',
            parent_id: 'الرئيسي',
            icon: 'الرمز',
            blog: 'المدونة',
            contents: 'المحتوي',
            'App Name': 'إسم الموقع',
            'App Description': 'وصف الموقع',
            maintenance: 'الصيانة',
            Message: 'الرسالة',
            Enabled: 'تشغيل',
            admin: 'لوحة التحكم',
            ai: 'الذكاء الإصطناعي',

            // cms
            'Custom Style': 'تنسيق CSS مخصص',
            'Custom Script': 'كود JavaScript',
        },
    },
}

export default plyInit
