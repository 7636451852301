import React, { useState, useEffect } from 'react'
import { Button, Card, Spinner } from 'react-bootstrap'
import * as t from 'counterpart'
import api from '../../../utilities/API'

function Plans({ selected, yearly, onChange, yearlyMonths, store, navigate }) {
    const [plans, setPlans] = useState(null)
    const [bundles, setBundles] = useState(null)
    const [selectedBundle, setSelectedBundle] = useState({})

    useEffect(() => {
        // products
        api('/commerce/products/?limit=50&with_attrs=true', {
            error: alert,
            result: (products) =>
                setPlans(
                    products.data.map((p) => {
                        let attributes = {}
                        p.attributes?.forEach((a) => (attributes[a.key] = a.value))
                        p.attributes = attributes
                        return p
                    })
                ),
        })

        // sets
        api('/commerce/sets/', {
            error: alert,
            result: (sets) => {
                sets = sets.data
                    .filter((s) => s.type === 'subscription')
                    .map((s) => {
                        s.display_setting = JSON.parse(s.display_setting)
                        return s
                    })
                setBundles(sets)
                setSelectedBundle(sets?.[0] || {})
            },
        })
    }, [])

    // loading
    if (plans === null || bundles === null) {
        return <Spinner animation="border" style={{ margin: '100px auto', display: 'block' }} />
    }

    return (
        <>
            <div id="bundles">
                {bundles.length > 1 &&
                    bundles.map((bundle) => (
                        <Button
                            key={bundle.id}
                            className={bundle.id === selectedBundle.id ? 'active' : ''}
                            onClick={() => setSelectedBundle(bundle)}
                        >
                            {bundle.name}
                        </Button>
                    ))}
            </div>

            <div id="plans">
                <Card id="selected-bundle">
                    <Card.Header style={{ minHeight: 150 }}>
                        <Card.Text>{selectedBundle.name}</Card.Text>
                        <Card.Title>{selectedBundle.name}</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        {selectedBundle.display_setting?.map((section, i) => (
                            <div key={i} className="features">
                                <h6>{section.title}</h6>
                                {section.attributes?.map((attr, j) => (
                                    <div key={j} className="feature">
                                        {attr.label}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </Card.Body>
                </Card>

                {plans
                    .filter((p) => p.attributes_set_id === selectedBundle.id)
                    .map((plan) => {
                        const enterprise = plan.code === 'enterprise'

                        const onClick = () => {
                            if (enterprise) {
                                navigate(`/${store}/contact`)
                            } else {
                                onChange(plan)
                            }
                        }

                        return (
                            <Card
                                key={plan.id}
                                className={
                                    'plan ' +
                                    (plan.code || '') +
                                    (selected === plan.id ? ' active' : '')
                                }
                                onClick={onClick}
                            >
                                <Card.Header style={{ minHeight: 150 }}>
                                    <Card.Text>{selectedBundle.name}</Card.Text>
                                    <Card.Title>{plan.name}</Card.Title>
                                    {enterprise && <h4>{t('Contact Sales')}</h4>}
                                    {!enterprise && (
                                        <div className="price">
                                            {(
                                                plan.current_price * (yearly ? yearlyMonths : 1)
                                            ).toLocaleString()}{' '}
                                            {window.portalSetting('commerce.currency')}
                                        </div>
                                    )}
                                    {!enterprise && (
                                        <div
                                            className="old-price"
                                            style={{ textDecoration: 'line-through' }}
                                        >
                                            {plan.saved_amount
                                                ? plan.price.toLocaleString() +
                                                  ' ' +
                                                  window.portalSetting('commerce.currency')
                                                : ''}{' '}
                                        </div>
                                    )}
                                </Card.Header>
                                <Card.Body>
                                    {selectedBundle.display_setting?.map((section, i) => (
                                        <div key={i} className="features">
                                            <h6>{section.title}</h6>
                                            {section.attributes.map((attr, j) => {
                                                let value = plan.attributes?.[attr.key]
                                                if (attr.type === 'checkbox') {
                                                    value = (
                                                        <i
                                                            className={
                                                                'fa fa-' +
                                                                (value ? 'check' : 'times')
                                                            }
                                                        />
                                                    )
                                                }

                                                return (
                                                    <div key={j} className="feature">
                                                        <strong>{attr.label}</strong> {value}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    ))}
                                </Card.Body>
                                <Card.Footer>
                                    {enterprise ? (
                                        <Button>{t('Contact Sales')}</Button>
                                    ) : (
                                        <Button>
                                            {selected === plan.id && <i className="fa fa-check" />}{' '}
                                            {t('Select') + ' ' + plan.name}
                                        </Button>
                                    )}
                                </Card.Footer>
                            </Card>
                        )
                    })}
            </div>
        </>
    )
}

export default Plans
