import * as t from 'counterpart'
import api from '../../utilities/API'

//
export function loadFavorites() {
    return function (dispatch, state) {
        if (state().commerce.favorites.isLoading) {
            return
        }

        dispatch({ type: 'FAVOURITES_LOADING' })

        api('/commerce/wishlist/', {
            result: (data) => dispatch({ type: 'COMMERCE_SET_FAVORITES', payload: data || {} }),
            error: (error) => alert(t('Favorites') + ': ' + error), // dispatch({type: "COMMERCE_ERROR", payload: error}),
        })
    }
}

//
export function toggleFavorites(product_id, setFavStatus, isFav) {
    return function (dispatch) {
        let url = 'commerce/wishlist/'
        if (isFav) {
            url += product_id
        }
        setFavStatus('loading')
        api(url, {
            method: isFav ? 'DELETE' : 'POST',
            data: { product_id },
            result: () => {
                dispatch(loadFavorites())
                setFavStatus(isFav ? 'removed' : 'success')
            },
            error: (e) => {
                alert(e)
                setFavStatus('error')
            },
        })
    }
}

//
export function addToCompare(data) {
    return function (dispatch) {
        dispatch({ type: 'COMMERCE_SET_COMPARE', payload: data })
    }
}
