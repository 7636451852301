import { ReactNode, useEffect, useState } from 'react'
import t from 'counterpart'
import { Form } from 'react-bootstrap'
import api from '../../../../utilities/API'
import './ShippingMethods.scss'
import { ShippingMethodDTO } from '../../../../Types/DTOs/ShippingMethodDTO'

type ShippingMethodsProps = {
    onChange: (method: ShippingMethodDTO) => void
    className?: string
    title: ReactNode
}

function ShippingMethods({ onChange, className, title }: ShippingMethodsProps) {
    const [methods, setMethods] = useState<ShippingMethodDTO[]>([])
    const [selected, setSelected] = useState<ShippingMethodDTO | null>(null)

    // load initial data
    useEffect(() => {
        api('shipping/methods/?active=1', {
            result: (data: ShippingMethodDTO[]) => {
                setMethods(data || [])

                // set the first method by default
                if (data.length) {
                    const first = data[0]
                    setSelected(first)
                    if (onChange) {
                        onChange(first)
                    }
                }
            },
        })
    }, [])

    //
    const localOnChange = (method: any) => {
        setSelected(method)
        if (onChange) {
            onChange(method)
        }
    }

    return (
        <div id="shipping" className={className}>
            {title}

            {methods.map((method) => (
                <label
                    key={method.id}
                    className={'method ' + (method.id === selected?.id ? 'selected' : '')}
                >
                    <Form.Check
                        id={'shipping-' + method.id}
                        type="radio"
                        label={method.displayed_name}
                        checked={method.id === selected?.id}
                        onChange={() => localOnChange(method)}
                    />

                    <div className="cost">
                        {method.price
                            ? method.price + ' ' + window.portalSetting('commerce.currency')
                            : t(method.price ? 'Continue for options' : 'Free')}
                    </div>
                </label>
            ))}
        </div>
    )
}

export default ShippingMethods
