import React from "react";
import {Route} from "react-router-dom";
import ContentType from "./ContentType";

// blocks
import Categories from './Blocks/Categories'
import Menu from './Blocks/Menu'
import Slider from './Blocks/Slider'
import UserMenu from './Blocks/UserMenu'
import LanguageSwitcher from './Blocks/LanguageSwitcher'
import Iframe from './Blocks/Iframe'
import Countdown from './Blocks/Countdown'

const cmsInit = {
    reducers: {},
    routes: [
        <Route key="cms_content_type" exact path="/content/:contentType" element={<ContentType />} />,
    ],
    blocks: {
        'cms-categories': {
            component: Categories,
            setting: {},
        },
        'cms-menu': {
            component: Menu,
            setting: {},
        },
        'cms-slider': {
            component: Slider,
            setting: {
                slides: { form: 'slides' },
                indicators: { form: 'checkbox' },
                controls: { form: 'checkbox' },
                fade: { form: 'checkbox' },
            },
        },
        'user-menu': {
            component: UserMenu,
            setting: {
                title: { form: 'text' },
            },
        },
        'language-switcher': {
            component: LanguageSwitcher,
            setting: {},
        },
        'iframe-block': {
            component: Iframe,
            setting: {
                width: { form: 'text' },
                height: { form: 'text' },
                src: { form: 'text' },
            },
        },
        'countdown': {
            component: Countdown,
            setting: {
                title: { form: 'text' },
                date: { form: 'text' },
            },
        },
    },
    i18n: {
        en: {},
        ar: {
            'Login / Register': 'دخول/تسجيل',
            Remove: 'إزالة',
            Profile: 'الملف الشخصي',
            'Track Your Orders': ' متابعة الطلبات',
            'Your Addresses': ' إدارة العناوين',
            Favorites: 'المفضلة',
            'Compare Products': 'مقارنة المنتجات',
            'Sign out': ' تسجيل الخروج',
            'Blog': 'المدونة',
        },
    },
}

export default cmsInit
