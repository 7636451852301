import React from 'react'
import { Route } from 'react-router-dom'

const ERP = React.lazy(() => import('./ERP'))

const plyInit = {
    reducers: {},
    routes: [<Route key="commerce_seating" exact path="/erp/:app" element={<ERP />} />],
    i18n: {
        en: {
            'inventory structure': 'Structure',
            'inventory items': 'Items',
            'purchasing transactions': 'Transactions',
            'sales structure': 'Structure',
            'sales transactions': 'Transactions',
            'financial accounts': 'Accounts',
            'accounting structure': 'Structure',
            'assets structure': 'Structure',
            'manufacturing structure': 'Structure',
            'manufacturing operations': 'Operations',
            'hr structure': 'Structure',
        },
        ar: {
            sales_c: 'إعدادادت المبيعات',
            sales_a: 'إستعلامات المبيعات',
            purch: 'المشتريات',
            purch_c: 'إعدادادت المشتريات',
            purch_a: 'إستعلامات المشتريات',
            gl: 'المالية',
            gl_c: 'إعدادادت المالية',
            gl_a: 'إستعلامات المالية',
            manuf: 'التصنيع',
            manuf_c: 'إعدادادت التصنيع',
            manuf_a: 'إستعلامات التصنيع',
            items: 'الأصناف',
            items_c: 'إعدادادت الأصناف',
            items_a: 'إستعلامات الأصناف',
            assets_c: 'إعدادادت الأصول',
            assets_a: 'إستعلامات الأصول',
            dim: 'الفروع والقطاعات',
            dim_c: 'إعدادات الفروع والقطاعات',
            setup: 'التركيب',
            spec: 'الصيانة',

            invoicing: 'الفوترة',
            customers: 'العملاء',
            'sales structure': 'هيكلة المبيعات',
            'sales transactions': 'حركات المبيعات',
            reports: 'التقارير',
            suppliers: 'الموردين',
            'purchasing transactions': 'حركات المشتريات',
            inquiries: 'الإستعلامات',
            'inventory structure': 'هيكلة المخازن',
            'inventory items': 'الأصناف المخزنية',
            'accounting structure': 'هيكلة المالية',
            'assets structure': 'هيكلة الأصول',
            entries: 'القيود',
            'financial accounts': 'الحسابات المالية',
            auditing: 'مراجعة الحسابات',
            operations: 'العمليات',
            'manufacturing structure': 'هيكلة التصنيع',
            'manufacturing operations': 'عمليات التصنيع',
            'work orders': 'أوامر التشغيل',
            materials: 'المواد الخام',
            'hr structure': 'هيكلة الموظفين',
            attendance: 'الحضور',
            salaries: 'الرواتب',
            payments: 'المدفوعات',
        },
    },
}

export default plyInit
