import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { addToCart } from '../../../Cart/actions'
import { Button, FormControl } from 'react-bootstrap'
import * as t from 'counterpart'
import { ToolTip } from '../../components/Tooltip'
import { ReactComponent as AddToCartIcon } from '../style/nsaq-icon-atc.svg'

export const AddToCartAction = ({ product, withQuantity }) => {
    const dispatch = useDispatch()
    const { user } = useSelector((state) => ({
        user: state.portal.user,
    }))

    const [addToCartStatus, setAddToCartStatus] = useState('')
    const [quantity, setQuantity] = useState(1)

    // addToCartLabel
    let addToCartLabel = 'Add to Cart'
    if (addToCartStatus === 'loading') addToCartLabel = 'Loading'

    const onAddToCart = (e) => {
        e.stopPropagation()
        e.preventDefault()
        setAddToCartStatus('loading')
        let item = { product_id: product.id, quantity: parseInt(quantity) }
        if (!user) {
            item.productData = product
        }
        dispatch(
            addToCart({
                data: [item],
                after: (status, error) => {
                    setAddToCartStatus(status)
                    if (error) {
                        alert(error)
                    }
                },
                local: !user,
                product,
            })
        )
    }

    return (
        <div className="add-to-cart-action">
            {product.available_stock ? (
                <>
                    {withQuantity && (
                        <FormControl
                            type="number"
                            min="1"
                            value={quantity}
                            onChange={(e) => setQuantity(parseInt(e.target.value))}
                        />
                    )}
                    <ToolTip label={t(addToCartLabel)}>
                    <Button
                        className="button"
                        onClick={onAddToCart}
                        disabled={addToCartStatus === 'loading'}
                    >
                        <AddToCartIcon /> <span>{t(addToCartLabel)}</span>
                    </Button>
                    </ToolTip>
                </>
            ) : (
                <ToolTip label={t('Out of stock')}>
                    <Button variant="danger" disabled>
                        {t('Out of stock')}
                    </Button>
                </ToolTip>
            )}
        </div>
    )
}
