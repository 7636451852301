import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Alert } from 'react-bootstrap'
import { Helmet } from 'react-helmet'

import t from 'counterpart'
import { loadCart } from '../actions'

import '../style/Cart.scss'

import { triggerViewCartGEvent } from '../../Commerce/analytics/g-tags-events-triggers'
import { CartList } from './CartList'
import { CartTotals } from './CartTotals'
import { useProductAndTotal } from './hooks/useProductAndTotal'
import ProductsCarousel from "../../Commerce/Blocks/ProductsCarousel";

export const CartPage = () => {
    const dispatch = useDispatch()
    const user = useSelector((state) => state.portal.user)
    const cart = useSelector((state) => state.cart)
    const store = useParams().store
    const localCart = !user

    const [products, totals] = useProductAndTotal(cart)

    useEffect(() => dispatch(loadCart(localCart)), [user, dispatch, localCart])

    useEffect(() => {
        if (cart?.items.length > 0) {
            triggerViewCartGEvent(cart.items, window.portalSetting('commerce.currency', '', false))
        }
    }, [cart?.items.length])

    return (
        <div id="cart-page" className="container ">
            <h1>
                <i className="fa fa-shopping-cart" />
                {t('Cart')}{' '}
                <span>
                    ({cart.items.length} {t('Items')})
                </span>
            </h1>
            <Helmet>
                <title>{t('Cart')}</title>
            </Helmet>
            {/* cart.error  */}
            {cart.error && <Alert variant="danger">{cart.error}</Alert>}

            {cart.items.length !== 0 && <CartList products={products} />}

            {cart.items.length !== 0 && <CartTotals totals={totals} user={user} store={store} />}

            <ProductsCarousel title={t('You May Also Like')} cardsNumber={4} style={{marginTop: 70}} />
        </div>
    )
}
