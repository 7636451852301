import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { PaymentModals } from './types'
import t from 'counterpart'

import './style/SummaryModal.scss'

type SplittedModalOptionsProps = {
    PaymentModalType: PaymentModals | ''
    setPaymentModalType: Function,
    order: any,
}
const SplittedModalOptions = ({
    PaymentModalType,
    setPaymentModalType,
    order,
}: SplittedModalOptionsProps) => {
    return (
        <div className="summary-modal options-modal">
            <Modal
                show={PaymentModalType === PaymentModals.OPTIONS}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header>
                    <div className="modal__header">
                        <div className="modal__header--title">
                            <span className="mb-1">{t('youArePaying')}</span>
                            <small className="text-muted text-small">{t('youCanSplitTheBill')}</small>
                        </div>
                        <button
                            className="btn btn-close"
                            onClick={() => setPaymentModalType('')}
                        ></button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <ul className="list-style-none m-0 p-0">
                        <li className="border-bottom p-3 mb-2 d-flex justify-content-between align-items-center">
                            <span>{t('divideTheBillEqually')}</span>
                            <Button size="sm" variant="outline-primary" onClick={() => setPaymentModalType(PaymentModals.DIVIDE)}>
                                {t('select')}
                            </Button>
                        </li>
                        <li className="border-bottom p-3 mb-2 d-flex justify-content-between align-items-center">
                            <span>
                            {t('payForYourItems')}
                            </span>
                            <Button size="sm" variant="outline-primary" onClick={() => setPaymentModalType(PaymentModals.YOURITEMS)}>
                            {t('select')}

                            </Button>
                        </li>
                        <li className="p-3 d-flex justify-content-between align-items-center">
                            <span>
                            {t('payCustomAmount')}
                                
                            </span>
                            <Button size="sm" variant="outline-primary" onClick={() => setPaymentModalType(PaymentModals.CUSTOM)}>
                            {t('select')}

                            </Button>
                        </li>
                    </ul>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default SplittedModalOptions
