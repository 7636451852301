import { useEffect, useState } from 'react'
import { Cart } from '../Types'

export const useProductAndTotal = (cart: Cart) => {
    const [products, setProducts] = useState<any[]>([])
    const [totals, setTotals] = useState({ total: 0, totalSaved: 0, totalCashBack: 0 })

    useEffect(() => {
        const MainItems: any[] = []
        const tempTotals = { total: 0, totalSaved: 0, totalCashBack: 0 }
        const displayCashback = !!window.portalSetting('commerce.cashback.display')
        cart.items.forEach((item) => {
            //Totals calculation
            const cashBack = item.cash_back || 0

            item.cart_options_price = item.cart_options_price || 0

            tempTotals.total +=
                (item.current_price + item.cart_options_price - (displayCashback ? cashBack : 0)) *
                item.cart_quantity
            tempTotals.totalSaved += item.saved_amount * item.cart_quantity
            tempTotals.totalCashBack += cashBack * item.cart_quantity
            //End Totals calculation

            //Item rendering
            MainItems.push({ item, cashBack })
            MainItems.push(
                ...(item.gifts || []).map((gift) => {
                    gift.cart_quantity = item.cart_quantity
                    gift.saved_amount = gift.current_price
                    gift.cart_price = 0
                    gift.current_price = 0
                    gift.cash_back = 0

                    tempTotals.totalSaved += gift.saved_amount * gift.cart_quantity

                    return { item: gift, cashBack: 0, gift: true }
                })
            )
        })

        setTotals(tempTotals)
        setProducts(MainItems)
    }, [cart.items])

    return [products, totals]
}
