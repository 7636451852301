import React from 'react'

export default React.forwardRef(function (props = {}, ref) {
    props = {
        ...props,
        className: 'iframe-block ' + (props.className || ''),
    }

    return <iframe width="100%" height="700" {...props} ref={ref} />
})
