import storage from '../../utilities/storage'

const initialState = {
    loading: false,
    error: null,
    browse: {},
    favorites: { data: [], isLoading: false },
    compare: { data: storage('compare_products') || [], isLoading: false },
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'COMMERCE_LOADING':
            return { ...state, isLoading: true, error: null }
        case 'FAVOURITES_LOADING':
            return { ...state, favorites: { ...state.favorites, isLoading: true } }

        case 'COMMERCE_ERROR':
            return { ...state, isLoading: false, error: action.payload }

        case 'COMMERCE_SET_COMPARE':
            storage('compare_products', action.payload)
            return { ...state, compare: { data: action.payload } }

        case 'COMMERCE_SET_FAVORITES':
            const favorites = action.payload || initialState.favorites

            return {
                ...state,
                favorites,
                favoritesIDs: favorites.data.map((f) => f.id) || [],
            }

        case 'COMMERCE_SET_BROWSE_DATA':
            return {
                ...state,
                isLoading: false,
                error: null,
                browse: action.payload,
            }

        default:
            return state
    }
}
