import React, { useEffect, useRef, useState } from 'react'
import api from '../../utilities/API'
import * as t from 'counterpart'

function PaymentHooks({ product }) {
    const [paymentMethods, setPaymentMethods] = useState([])
    const paymentMethodsRef = useRef(null)

    // payment methods
    useEffect(() => {
        api('payment/methods/?active=1', {
            result: (data) => {
                setPaymentMethods(
                    (data || []).map((method) => {
                        if (method.frontend_setting) {
                            try {
                                method.frontend_setting = eval('(' + method.frontend_setting + ')')
                            } catch (e) {
                                console.log('payment method hook: ', e)
                            }
                        }

                        return method
                    })
                )
            },
        })
    }, [])

    // apply payment methods hooks
    useEffect(() => {
        if (paymentMethodsRef.current && product) {
            try {
                const ctx = {
                    lang: t.getLocale(),
                    currency: window.portalSetting('commerce.currency', '', false),
                }
                paymentMethods.forEach(function (method) {
                    method.frontend_setting?.hooks?.product_page_after_add_to_cart?.(
                        paymentMethodsRef.current,
                        product,
                        ctx
                    )
                })
            } catch (e) {
                console.log('payment method hook: ', e)
            }
        }
    }, [paymentMethods, product])

    return <div id="payment_methods" ref={paymentMethodsRef} />
}

export default PaymentHooks
