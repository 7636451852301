import React from 'react'

// image gallery
import ImageGallery from 'yet-another-react-lightbox'
import { Inline, Thumbnails, Fullscreen, Video } from 'yet-another-react-lightbox/plugins'
import 'react-datepicker/dist/react-datepicker.css'
import ProductBadges from '../../Blocks/ProductBadges'
import { Plugins } from '@capacitor/core'
import { Link } from 'react-router-dom'

let imagePrefix = process.env.REACT_APP_IMAGES_PREFIX || "";
let largeImagePrefix = process.env.REACT_APP_LARGE_IMAGES_PREFIX || "";

export const MediaSide = ({ product, shareURL, store }) => {
    const fullscreenRef = React.useRef(null)
    return (
        <>
            <div className="image-carousel">
                <ImageGallery
                    plugins={[Inline, Thumbnails, Fullscreen, Video]}
                    inline={{ style: { aspectRatio: '1 ' } }}
                    fullscreen={{ ref: fullscreenRef }}
                    on={{ click: () => fullscreenRef.current?.enter() }}
                    slides={(product.media || []).map((m) => {
                        let slide = { src: largeImagePrefix + m.link }
                        if (m.type === 'video') {
                            slide = {
                                type: m.type,
                                poster: imagePrefix + product.thumbnail,
                                sources: [{ type: 'video/mp4', src: m.link }],
                            }
                        }

                        return slide
                    })}
                />
            </div>
            <ShareComponent name={product.name} shareURL={shareURL} />
        </>
    )
}

const ShareComponent = ({ name, shareURL }) => {
    return (
        <div className="extra">
            <div className="share">
                <a
                    href={`https://www.facebook.com/sharer.php?u=${shareURL}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <span className="fab fa-facebook-square"></span>
                </a>
                <a
                    href={`https://twitter.com/share?url=${shareURL}&text=${name}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <span className="fab fa-twitter-square"></span>
                </a>
                <a
                    href={`https://www.linkedin.com/shareArticle?mini=true&url=${shareURL}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <span className="fab fa-linkedin"></span>
                </a>
                <a
                    href={`mailto:?Subject=${name}&Body=${name} ${shareURL}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <span className="fa fa-envelope-square"></span>
                </a>
                <input type="text" readOnly disabled value={shareURL} />
            </div>

            {window.platform !== 'web' && (
                <button
                    onClick={() => {
                        Plugins.Share.share({
                            url: shareURL,
                            title: name,
                            text: name,
                            dialogTitle: name,
                        })
                    }}
                >
                    <i className="fa fa-share" />
                </button>
            )}
        </div>
    )
}
