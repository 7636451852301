import TagManager from 'react-gtm-module'

export const triggerCheckoutGEvent = (products = [], currency = 'SAR') => {
    let productsList = []
    let value = 0

    if (products?.length > 0) {
        productsList = products.map((product) => {
            // Calculate price based on saved amount & cashback
            const price =
                product.saved_amount && product.saved_amount > 0
                    ? product.current_price - (product.cash_back || 0)
                    : product.price - (product.cash_back || 0)

            //return the product object
            return {
                item_id: product.id,
                item_name: product.name,
                price: price,
                discount: product.saved_amount + (product.cash_back || 0),
                quantity: product.cart_quantity,
            }
        })

        // Calculate total value of the products in the cart for checkout after discount and cashback
        value = productsList.reduce((acc, item) => {
            return acc + item.price * item.quantity
        }, 0)
    }

    // create the checkout event object
    const tagManagerArgs = {
        dataLayer: {
            event: 'begin_checkout',
            ecommerce: {
                currency: currency,
                value,
                items: productsList,
            },
        },
    }

    // push the event to the dataLayer to trigger the event
    TagManager.dataLayer(tagManagerArgs)
}
