import { numberToCompactForm } from '../utils/format'

type RatingProps = { product: any }

export const RatingAverage = ({ product }: RatingProps) => {
    const ratingAverage =
        product?.stars && product?.reviewers ? (product.stars / product.reviewers).toFixed(1) : 5
    return (
        <div className="rating">
            {ratingAverage} <i className="fa fa-star" /> (
            {numberToCompactForm(product.reviewers || 0)})
        </div>
    )
}
