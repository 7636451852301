import { useEffect, useState } from 'react'
import api from '../../../../utilities/API'
import { useSelector } from 'react-redux'
import * as t from 'counterpart'

export const useGetProductAndCategories = (id) => {
    const categories = useSelector((state) => state.portal.categories)
    const [product, setProduct] = useState(null)
    const [faqs, setFaqs] = useState({})

    useEffect(() => {
        // load product
        api('/commerce/products/' + id, {
            result: (data) => {
                const set = data.attributes_set || {}

                // display setting
                try {
                    data.displaySetting = JSON.parse(set.display_setting || '[]')
                } catch (e) {
                    console.log(e)
                }

                if (!data.displaySetting || !data.displaySetting.length) {
                    data.displaySetting = [
                        {
                            title: t('description'),
                            attributes_ids: set.attributes?.map((a) => a.key),
                        },
                    ]
                }

                // first category and it's parents for breadcrumbs
                const firstCategory = (data.categories || [{}])[0]
                data.categoriesBreadcrumbs = []
                if (firstCategory) {
                    // flat the categories menu data to get parents from it
                    let categoriesMap = Object.values(categories)
                        .flat()
                        .reduce((acc, cat) => {
                            acc[cat.id] = cat
                            return acc
                        }, {})

                    let current = firstCategory
                    while (current) {
                        data.categoriesBreadcrumbs.push(current)
                        current = categoriesMap[current.parent_id]
                    }

                    data.categoriesBreadcrumbs.reverse()
                }

                // reviews summary
                let reviewsSummary = { total: 0 }
                ;(data.reviews_summary || []).forEach((review) => {
                    reviewsSummary[review.rate] = review.count
                    reviewsSummary.total += review.count
                })
                data.reviews_summary = reviewsSummary

                // attributes labels
                data.attributesSetting = {}
                ;(set.attributes || []).forEach((attr) => {
                    data.attributesSetting[attr.key] = attr
                    if (attr.type === 'seats_map') {
                        data.seatsMap = data[attr.key]
                    }
                })

                // availabilities
                if (data.availabilities) {
                    let dates = {}

                    data.availabilities.forEach((availability) => {
                        let current = new Date(availability.available_from)
                        const end = new Date(availability.available_to)

                        while (current <= end) {
                            const key = current.toISOString().split('T')[0]
                            if (!dates[key]) {
                                dates[key] = []
                            }

                            dates[key].push(availability)

                            current.setDate(current.getDate() + 1)
                        }
                    })

                    data.availabilities = dates
                    data.availabilitiesDates = Object.keys(dates).map((d) => new Date(d)) || []
                }

                setProduct(data)

                // load faqs
                api(
                    '/commerce/products-faqs/?product_id=' +
                        id +
                        '&category_id=' +
                        data.categories.map((c) => c.id).join(','),
                    {
                        result: (data) => setFaqs(data),
                    }
                )
            },
            error: (error) => console.log(error),
        })
    }, [id])

    return [product, setProduct, faqs]
}
