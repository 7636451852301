import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useSearchParams, useNavigate, Navigate, Link } from 'react-router-dom'
import { Badge, Spinner, Modal, Card, Form, Button } from 'react-bootstrap'
import moment from 'moment'
import t from 'counterpart'
import QRCode from 'qrcode.react'
import AsyncCreatableSelect from 'react-select/async-creatable'
import { PrintShippingDetails } from './PrintShippingDetails'
import api from '../../../utilities/API'

import '../style/OrderDetails.scss'

const saasDomain = process.env.REACT_APP_SAAS_DOMAIN
let imagePrefix = process.env.REACT_APP_IMAGES_PREFIX || ''

function OrderDetails({ initialOrder, controls }) {
    const { ready, user } = useSelector((state) => state.portal)
    const [order, setOrder] = useState(initialOrder)
    const [payment, setPayment] = useState(null)
    const [shipping, setShipping] = useState(null)
    const [code, setCode] = useState(null)
    const [returns, setReturns] = useState({})
    const navigate = useNavigate()
    const { store, id } = useParams()
    const paymentID = useSearchParams()[0].get('payment')

    const load = () => {
        api('/commerce/orders/' + (paymentID ? paymentID + '?by=payment_id' : id), {
            result: (order) => {
                order.has_subscription = !!order.items?.find(
                    (item) => item.product?.type === 'subscription'
                )
                setOrder(order)
            },
        })
    }

    //
    useEffect(() => {
        setReturns({})
        if (!initialOrder && ready && user) {
            load()
        }
    }, [id, initialOrder, ready, user])

    // load payment method
    useEffect(() => {
        if (!order || payment) {
            return
        }

        if (order.payment_method_id) {
            api('/payment/methods/' + order.payment_method_id, { result: setPayment })
        } else {
            setPayment({ displayed_name: t('Cash on delivery'), image: '/assets/payment/cash.svg' })
        }
    }, [order, payment])

    // load shipping method
    useEffect(() => {
        if (!order || shipping) {
            return
        }

        if (order.shipping_method_id && !shipping) {
            api('/shipping/methods/' + order.shipping_method_id, { result: setShipping })
        }
    }, [order, shipping])

    if (ready && !user) {
        return <Navigate to={`/${store}/auth/login`} replace />
    }

    // change status
    const changeStatus = async (status, item) => {
        if (!order || !controls) {
            return
        }

        if (!(await window.confirmDialog(t('Are you sure?')))) {
            return
        }

        setOrder(null)

        api('/commerce/orders/' + order.id + (item ? '/item/' + item : ''), {
            method: 'PUT',
            data: { status },
            result: load,
            error: (error) => {
                alert(error)
                load()
            },
        })
    }

    // update notes
    const updateNotes = () => {
        setOrder(null)

        api('/commerce/orders/' + order.id, {
            method: 'PUT',
            data: { notes: order.notes, tags: order.tags },
            result: load,
            error: (error) => {
                alert(error)
                load()
            },
        })
    }

    // submit rating
    const submitRating = () => {
        setOrder(null)

        api('/commerce/orders/' + order.id + '/rating', {
            method: 'POST',
            data: { rating: order.new_rating, rating_notes: order.rating_notes },
            result: load,
            error: (error) => {
                alert(error)
                load()
            },
        })
    }

    //
    const addToReturn = (item) => {
        if (returns.loading) {
            return
        }

        let count = (returns[item.id] || 0) + 1
        if (count <= item.quantity) {
            returns[item.id] = count
        } else {
            delete returns[item.id]
        }

        setReturns({ ...returns })
    }

    //
    const submitReturn = (item) => {
        if (returns.loading) {
            return
        }

        setReturns({ ...returns, loading: true })

        api('/commerce/orders/' + order.id + '/return', {
            method: 'POST',
            data: { items: returns },
            result: (returnOrder) => navigate(`/${store}/orders/${returnOrder.id}`),
            error: (error) => {
                delete returns.loading
                setReturns({ ...returns })
                alert(error)
            },
        })
    }

    //
    const makePayment = (cash = false) => {
        setOrder(null)

        api('/commerce/orders/' + order.id + '/payment/method', {
            method: 'POST',
            data: { cash },
            error: alert,
            result: ({ payment }) => {
                if (cash) {
                    window.location.reload()
                } else if (payment?.redirect) {
                    window.location.href = payment.redirect
                }
            },
        })
    }

    const cancel = async () => {
        if (!(await window.confirmDialog(t('Are you sure?')))) {
            return
        }

        setOrder(null)

        api('/commerce/orders/' + order.id + '/cancel', {
            method: 'put',
            result: load,
            error: (error) => {
                alert(error)
                load()
            },
        })
    }

    const isCompleted = order?.status === 'completed'
    const since = moment().diff(moment(order?.created_at), 'days')
    const arabicNumeralMap = {
        '٠': '0',
        '١': '1',
        '٢': '2',
        '٣': '3',
        '٤': '4',
        '٥': '5',
        '٦': '6',
        '٧': '7',
        '٨': '8',
        '٩': '9',
    }
    const orderDate = moment(order?.created_at)
        .format('YYYY/MM/DD hh:mm A')
        .replace(/[٠١٢٣٤٥٦٧٨٩]/g, (digit) => arabicNumeralMap[digit])
    let statusLabel = order?.status
    if (statusLabel === 'pending' && order?.payment_method_id && !order?.paid) {
        statusLabel = 'not_confirmed'
    }

    let totalOrder = 0
    let discount = 0
    let subtotal = 0
    let vat = 0
    let totalCashback = 0

    order?.items?.forEach((item) => {
        totalOrder += (item.price + item.saved) * item.quantity
        discount += item.saved * item.quantity
        subtotal += item.total + (item.cash_back || 0)
        vat += (item.price / 1.15) * 0.15 * item.quantity
        totalCashback += item.cash_back || 0
    })

    const cashbackIncludedInTax = !(order?.cashback_type === 'included')

    subtotal = cashbackIncludedInTax ? order?.total : subtotal
    vat = cashbackIncludedInTax ? (order?.total / 1.15) * 0.15 : vat

    return (
        <div id="order-details-page" className="container">
            {!controls && (
                <h1>
                    {order?.has_subscription
                        ? t('Subscription')
                        : t(order?.return ? 'Refund Details' : 'Order Details')}
                </h1>
            )}
            {!order && (
                <div id="loading">
                    <Spinner animation="border" variant="primary" />
                </div>
            )}

            {order && (
                <>
                    {controls && (
                        <div id="order-actions">
                            {(order.status === 'pending' || order.status === 'paid_expired') && (
                                <Button
                                    variant="primary"
                                    className="col-md-2"
                                    onClick={() => changeStatus('processing')}
                                >
                                    <i className="fa fa-gears" /> {t('processing')}
                                </Button>
                            )}
                            {(order.status === 'pending' || order.status === 'processing') &&
                                !order.paid &&
                                (!order.payment_method_id || order.payment_driver === 'onsite') && (
                                    <Button
                                        className="col-md-2"
                                        onClick={() => changeStatus('paid')}
                                    >
                                        <i className="fa fa-money-bill-1" /> {t('paid')}
                                    </Button>
                                )}
                            {order.status === 'draft' && !order.paid && (
                                <Button className="col-md-2" onClick={() => changeStatus('paid')}>
                                    <i className="fa fa-money-bill-1" /> {t('paid')}
                                </Button>
                            )}

                            {(order.status === 'canceled' ||
                                order.status === 'canceled_by_client') && (
                                <Button
                                    variant="warning"
                                    className="col-md-2"
                                    onClick={() => changeStatus('cash_on_delivery')}
                                >
                                    <i className="fa fa-gears" /> {t('Pay in Store')}
                                </Button>
                            )}
                            {order.status !== 'completed' &&
                                order.status !== 'canceled_by_client' &&
                                !!order.payment_method_id &&
                                !order.paid && (
                                    <Button
                                        variant="warning"
                                        className="col-md-2"
                                        onClick={() => changeStatus('cash_on_delivery')}
                                    >
                                        <i className="fa fa-truck" /> {t('Pay in Store')}
                                    </Button>
                                )}
                            {(order.status === 'pending' ||
                                order.status === 'processing' ||
                                order.status === 'draft') && (
                                <Button
                                    variant="danger"
                                    className="col-md-2"
                                    onClick={() => changeStatus('canceled')}
                                >
                                    <i className="fa fa-ban" /> {t('Cancel Order')}
                                </Button>
                            )}
                        </div>
                    )}

                    {!order.has_subscription &&
                        !order.return &&
                        order.status !== 'canceled_by_client' && (
                            <div className="steps">
                                <div
                                    className={
                                        'step ' +
                                        (order?.paid || isCompleted || order.status === 'pending'
                                            ? 'active'
                                            : '')
                                    }
                                >
                                    <div>
                                        <i className="fa fa-bag-shopping" />
                                    </div>
                                    <span>{t('Place Your Order')}</span>
                                </div>

                                <div className="line">
                                    {(order?.paid || isCompleted || order.status === 'pending') && (
                                        <div />
                                    )}
                                    {order?.paid && <div />}
                                </div>

                                <div className={'step ' + (order?.paid ? 'active' : '')}>
                                    <div>
                                        <i className="fa fa-credit-card" />
                                    </div>
                                    <span>{t('Payment')}</span>
                                </div>

                                <div className="line">
                                    {order?.paid && <div />}
                                    {isCompleted && <div />}
                                </div>

                                <div className={'step ' + (isCompleted ? 'active' : '')}>
                                    <div>
                                        <i className="fa fa-house-circle-check" />
                                    </div>
                                    <span>{t('Delivery')}</span>
                                </div>

                                <div className="line">
                                    {isCompleted && <div />}
                                    {isCompleted && <div />}
                                </div>

                                <div className={'step ' + (isCompleted ? 'active' : '')}>
                                    <div>
                                        <i className="fa fa-check-double" />
                                    </div>
                                    <span>{t('delivered')}</span>
                                </div>
                            </div>
                        )}

                    <div className="row">
                        <div className="col-md-8">
                            <Card>
                                <Card.Header
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                >
                                    {t(order.return ? 'Refund Details' : 'Order Details')}
                                    {!controls &&
                                        !order.return &&
                                        ['pending', 'processing'].includes(order.status) && (
                                            <Button variant="warning" onClick={cancel}>
                                                {t('Cancel Order')}
                                            </Button>
                                        )}
                                </Card.Header>
                                <Card.Body>
                                    <table width="100%">
                                        <tbody>
                                            <tr>
                                                <th width="20%">
                                                    {t(
                                                        order.return
                                                            ? 'Refund Number'
                                                            : 'Order Number'
                                                    )}
                                                </th>
                                                <td width="30%">{order.id + 1000}</td>
                                                <th width="20%">{t('status')}</th>
                                                <td width="30%">
                                                    <Badge
                                                        variant={
                                                            order.status === 'completed'
                                                                ? 'success'
                                                                : 'warning'
                                                        }
                                                    >
                                                        {t(statusLabel)}
                                                    </Badge>

                                                    <div>
                                                        {order.status === 'canceled_by_client'
                                                            ? moment(order.completed_at)
                                                                  .format('YYYY/MM/DD hh:mm A')
                                                                  .replace(
                                                                      /[٠١٢٣٤٥٦٧٨٩]/g,
                                                                      (digit) =>
                                                                          arabicNumeralMap[digit]
                                                                  )
                                                            : ''}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>{t('Total')}</th>
                                                <td>{order.total}</td>
                                                <th width="20%">{t('paid')}</th>
                                                <td>
                                                    <i
                                                        className={
                                                            order.paid
                                                                ? 'fa fa-check'
                                                                : 'fa fa-times'
                                                        }
                                                        style={{ margin: '0 5px' }}
                                                    />
                                                    {order.paid &&
                                                        order.payment &&
                                                        moment(order.payment.at).from()}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>{t('Payment Method')}</th>
                                                <td>
                                                    <i
                                                        className={
                                                            !order.payment_method_id
                                                                ? 'fa fa-money-bill'
                                                                : 'fa fa-credit-card'
                                                        }
                                                        style={{ margin: '0 5px' }}
                                                    />
                                                    {t(
                                                        !order.payment_method_id ||
                                                            order.payment_driver === 'onsite'
                                                            ? 'Cash Payment'
                                                            : 'Online Payment'
                                                    )}
                                                </td>
                                                <th>{t('Date')}</th>
                                                <td>
                                                    {orderDate}
                                                    {controls &&
                                                        order.status !== 'completed' &&
                                                        since !== 0 && (
                                                            <div>
                                                                {t('Since')} {since} {t('day')}
                                                            </div>
                                                        )}
                                                </td>
                                            </tr>
                                            <tr>
                                                {controls && !!order.location && (
                                                    <>
                                                        <th>{t('location_id')}</th>
                                                        <td>{order.location.name}</td>
                                                    </>
                                                )}
                                                {controls && !!order.completed_at && (
                                                    <>
                                                        <th>{t('completed_at')}</th>
                                                        <td>
                                                            {moment(order.completed_at)
                                                                .format('YYYY/MM/DD hh:mm A')
                                                                .replace(
                                                                    /[٠١٢٣٤٥٦٧٨٩]/g,
                                                                    (digit) =>
                                                                        arabicNumeralMap[digit]
                                                                )}
                                                        </td>
                                                    </>
                                                )}
                                            </tr>
                                        </tbody>
                                    </table>
                                </Card.Body>
                            </Card>

                            <Card>
                                <Card.Header>{t('Contact Information')}</Card.Header>
                                <Card.Body>
                                    <table width="100%">
                                        <tbody>
                                            <tr>
                                                <th width="30%">{t('Full Name')}</th>
                                                <td>{order.name}</td>
                                            </tr>
                                            <tr>
                                                <th>{t('Mobile')}</th>
                                                <td dir="ltr">{order.phone}</td>
                                            </tr>
                                            <tr>
                                                <th>{t('Email')}</th>
                                                <td>{order.email}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Card.Body>
                            </Card>

                            <hr />

                            {order.items?.map((item, i) => {
                                const isSubscription = item.product?.type === 'subscription'
                                const isItemRefund = item.quantity < 0
                                const subscriptionURL = item.options?.find(
                                    (o) => o.option_key === 'url'
                                )?.value
                                const step2 = ['ready', 'delivering', 'delivered'].includes(
                                    item.status
                                )
                                const step1 =
                                    step2 ||
                                    order.paid ||
                                    order.status === 'processing' ||
                                    (item.status === 'pending' && !order.payment_method_id)
                                const canCancel = controls && item.status === 'pending'
                                const label = item.status === 'pending' ? order.status : item.status

                                return (
                                    <Card key={item.id}>
                                        <Card.Header>
                                            {isSubscription
                                                ? t('Subscription')
                                                : t('Shipment') + ' #' + (i + 1)}{' '}
                                            {<Badge>{t(label)}</Badge>}
                                            {!!item.location && (
                                                <Badge style={{ float: 'left' }}>
                                                    {item.location.name}
                                                </Badge>
                                            )}
                                        </Card.Header>
                                        <Card.Body>
                                            <div className="item row" key={item.id}>
                                                <Link
                                                    className="col-2"
                                                    to={`/${store}/details/${item.product?.slug}-${item.product_id}`}
                                                    style={{ textAlign: 'center' }}
                                                >
                                                    <img
                                                        src={imagePrefix + item.product?.thumbnail}
                                                        alt=""
                                                    />{' '}
                                                    <br />#{item.id}
                                                </Link>
                                                <div className="col-5">
                                                    <Link
                                                        to={`/${store}/details/${item.product?.slug}-${item.product_id}`}
                                                    >
                                                        {item.product?.name}
                                                    </Link>{' '}
                                                    {!!item.product?.code &&
                                                        '(' + item.product?.code + ')'}{' '}
                                                    <br />
                                                    <span className="price">
                                                        {t('price')} {item.price.toLocaleString()}{' '}
                                                        {t(order.currency)}{' '}
                                                    </span>
                                                    {!!item.options_price && (
                                                        <span>
                                                            {' '}
                                                            / {t('Additional Options')}{' '}
                                                            {item.options_price} {t(order.currency)}
                                                        </span>
                                                    )}
                                                    <ul style={{ margin: 0 }}>
                                                        {item.options?.map((option) => (
                                                            <li key={option.id}>
                                                                {t(option.option_key || ' ')}{' '}
                                                                {option.option_type === 'text'
                                                                    ? option.value
                                                                    : ''}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                <div className="col-2">
                                                    {Math.abs(item.quantity)}{' '}
                                                    {!!item.returned && '(' + item.returned + ')'}
                                                </div>
                                                <div className="col-2">
                                                    {!!item.total
                                                        ? Math.abs(item.total).toLocaleString() +
                                                          ' ' +
                                                          t(order.currency)
                                                        : t('Free')}
                                                </div>
                                                <div
                                                    className="col-md-2"
                                                    style={{ textAlign: 'left' }}
                                                >
                                                    {/*!isSubscription &&
                                                    <div onClick={() => setCode(order.code || "0000-0000")}>
                                                        <i className="fa fa-qrcode"
                                                           style={{fontSize: 60, display: "block"}}/>
                                                        {t("Show Code")}
                                                    </div>*/}
                                                </div>
                                            </div>

                                            {isSubscription && (
                                                <div className="d-flex align-items-center justify-content-around">
                                                    <h5>{t(item.recurring)}</h5>
                                                    <div>
                                                        {t('From')}:{' '}
                                                        {moment(item.reserved_from).format(
                                                            'MMMM DD YYYY'
                                                        )}
                                                    </div>
                                                    <i className={'fa fa-arrow-' + t('right')} />
                                                    <div>
                                                        {t('To')}:{' '}
                                                        {moment(item.reserved_to).format(
                                                            'MMMM DD YYYY'
                                                        )}
                                                    </div>
                                                    <h6>
                                                        {t('Renewal')}{' '}
                                                        {moment(item.reserved_to).from()}
                                                    </h6>
                                                </div>
                                            )}

                                            {subscriptionURL && (
                                                <Button
                                                    as="a"
                                                    href={'https://' + subscriptionURL + saasDomain}
                                                    target="_blank"
                                                    style={{ marginTop: 10 }}
                                                >
                                                    {t('Go to your dashboard')}
                                                </Button>
                                            )}

                                            {!isSubscription && !isItemRefund && (
                                                <div className="steps">
                                                    <div
                                                        className={
                                                            'step ' + (step1 ? 'active' : '')
                                                        }
                                                    >
                                                        <div>
                                                            <i className="fa fa-boxes-packing" />
                                                        </div>
                                                        <span>{t('Packing')}</span>
                                                    </div>

                                                    <div className="line">
                                                        {step1 && <div />}
                                                        {step2 && <div />}
                                                    </div>

                                                    {!!order.address_id ? (
                                                        <div
                                                            className={
                                                                'step ' + (step2 ? 'active' : '')
                                                            }
                                                            onClick={() => {
                                                                if (
                                                                    ['pending', 'paid'].includes(
                                                                        item.status
                                                                    )
                                                                ) {
                                                                    changeStatus(
                                                                        'delivering',
                                                                        item.id
                                                                    )
                                                                }
                                                            }}
                                                        >
                                                            <div>
                                                                <i className="fa fa-truck-fast" />
                                                            </div>
                                                            <span>{t('delivering')}</span>
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className={
                                                                'step ' + (step2 ? 'active' : '')
                                                            }
                                                            onClick={() => {
                                                                if (
                                                                    ['pending', 'paid'].includes(
                                                                        item.status
                                                                    )
                                                                ) {
                                                                    changeStatus('ready', item.id)
                                                                }
                                                            }}
                                                        >
                                                            <div>
                                                                <i className="fa fa-shop" />
                                                            </div>
                                                            <span>{t('Ready for Pickup')}</span>
                                                        </div>
                                                    )}

                                                    <div className="line">
                                                        {step2 && <div />}
                                                        {item.status === 'delivered' && <div />}
                                                    </div>

                                                    <div
                                                        className={
                                                            'step ' +
                                                            (item.status === 'delivered'
                                                                ? 'active'
                                                                : '')
                                                        }
                                                        onClick={() => {
                                                            if (
                                                                ['delivering', 'ready'].includes(
                                                                    item.status
                                                                )
                                                            ) {
                                                                changeStatus('delivered', item.id)
                                                            }
                                                        }}
                                                    >
                                                        <div>
                                                            <i className="fa fa-house-circle-check" />
                                                        </div>
                                                        <span>
                                                            {t(
                                                                order.address_id
                                                                    ? 'Handed'
                                                                    : 'delivered'
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}

                                            {!controls &&
                                                item.status === 'delivered' &&
                                                !isSubscription &&
                                                item.quantity + item.returned > 0 && (
                                                    <Button
                                                        title={t('Return')}
                                                        onClick={() => addToReturn(item)}
                                                        disabled={returns.loading}
                                                    >
                                                        <i className="fa fa-share" />{' '}
                                                        {!!returns[item.id] && (
                                                            <span>({returns[item.id]})</span>
                                                        )}
                                                    </Button>
                                                )}

                                            {canCancel && (
                                                <Button
                                                    onClick={() =>
                                                        changeStatus('canceled', item.id)
                                                    }
                                                >
                                                    {t('Cancel')}
                                                </Button>
                                            )}
                                        </Card.Body>
                                    </Card>
                                )
                            })}

                            {Object.values(returns).length !== 0 && (
                                <div className="row">
                                    <Button
                                        variant="danger"
                                        onClick={submitReturn}
                                        disabled={returns.loading}
                                        className="col-md-4 offset-md-4"
                                    >
                                        {t('Return')} (
                                        {Object.values(returns).reduce((a, b) => a + b, 0)}){' '}
                                        {t('Items')}
                                    </Button>
                                </div>
                            )}

                            {!!order.address_id && (
                                <Card>
                                    <Card.Header>{t('Address')}</Card.Header>
                                    <Card.Body>
                                        <a
                                            href={
                                                'https://www.google.com/maps/search/?api=1&query=' +
                                                order.coords
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <img
                                                src={
                                                    `https://maps.googleapis.com/maps/api/staticmap?center=${order.coords}&zoom=12&size=400x200&maptype=roadmap
                                            &markers=color:red%7Clabel:A%7C${order.coords}&key=` +
                                                    window.portalSetting('web.google.maps.key')
                                                }
                                                style={{
                                                    border: '1px solid #aaa',
                                                    display: 'block',
                                                }}
                                                alt=""
                                            />
                                        </a>
                                        <br />

                                        <Form.Group>
                                            <Form.Label>{t('Country')}</Form.Label>
                                            <Form.Control
                                                value={new Intl.DisplayNames([t.getLocale()], {
                                                    type: 'region',
                                                }).of(order.country)}
                                                disabled
                                            />
                                        </Form.Group>

                                        <div className="row" style={{ marginTop: 5 }}>
                                            <Form.Group className="col-md-6">
                                                <Form.Label>{t('State')}</Form.Label>
                                                <Form.Control value={order.state} disabled />
                                            </Form.Group>
                                            <Form.Group className="col-md-6">
                                                <Form.Label>{t('City')}</Form.Label>
                                                <Form.Control value={order.city} disabled />
                                            </Form.Group>
                                        </div>
                                        <div className="row" style={{ marginTop: 5 }}>
                                            <Form.Group className="col-4">
                                                <Form.Label>{t('Neighborhood')}</Form.Label>
                                                <Form.Control value={order.neighborhood} disabled />
                                            </Form.Group>
                                            <Form.Group className="col-4">
                                                <Form.Label>{t('Street')}</Form.Label>
                                                <Form.Control value={order.street} disabled />
                                            </Form.Group>
                                            <Form.Group className="col-4">
                                                <Form.Label>{t('Postal Code')}</Form.Label>
                                                <Form.Control value={order.postal_code} disabled />
                                            </Form.Group>
                                        </div>

                                        <div className="row" style={{ marginTop: 5 }}>
                                            <Form.Group className="col-4">
                                                <Form.Label>{t('Building')}</Form.Label>
                                                <Form.Control value={order.building} disabled />
                                            </Form.Group>
                                            <Form.Group className="col-4">
                                                <Form.Label>{t('Floor')}</Form.Label>
                                                <Form.Control value={order.floor} disabled />
                                            </Form.Group>
                                            <Form.Group className="col-4">
                                                <Form.Label>{t('Apartment')}</Form.Label>
                                                <Form.Control value={order.apartment} disabled />
                                            </Form.Group>
                                        </div>
                                        {order.is_business && (
                                            <div className="row" style={{ marginTop: 5 }}>
                                                <Form.Group className="col-4">
                                                    <Form.Label>{t('Registry Name')}</Form.Label>
                                                    <Form.Control
                                                        value={order.registry_name}
                                                        disabled
                                                    />
                                                </Form.Group>
                                                <Form.Group className="col-4">
                                                    <Form.Label>{t('Registry Number')}</Form.Label>
                                                    <Form.Control
                                                        value={order.registry_number}
                                                        disabled
                                                    />
                                                </Form.Group>
                                                <Form.Group className="col-4">
                                                    <Form.Label>{t('Tax Number')}</Form.Label>
                                                    <Form.Control
                                                        value={order.tax_number}
                                                        disabled
                                                    />
                                                </Form.Group>
                                                <Form.Group
                                                    style={{ marginTop: 5 }}
                                                    className="col-4"
                                                >
                                                    <Form.Label>{t('Plot Number')}</Form.Label>
                                                    <Form.Control
                                                        value={order.plot_number}
                                                        disabled
                                                    />
                                                </Form.Group>
                                            </div>
                                        )}
                                    </Card.Body>
                                </Card>
                            )}
                        </div>

                        <div className="col-md-4">
                            <Card>
                                <Card.Header>{t('Invoice')}</Card.Header>
                                <Card.Body>
                                    {order.items?.map((item) => (
                                        <div className="row" key={item.id}>
                                            <div className="col-md-7" style={{ fontSize: 10 }}>
                                                {item.product?.name} <br />
                                                <span>
                                                    {t('price')} {item.price.toLocaleString()}{' '}
                                                    {t(order.currency)}{' '}
                                                </span>
                                                {!!item.options_price && (
                                                    <span>
                                                        / {t('Additional Options')}{' '}
                                                        {item.options_price} {t(order.currency)}
                                                    </span>
                                                )}
                                                <ul style={{ margin: 0 }}>
                                                    {item.options?.map((option) => (
                                                        <li key={option.id}>
                                                            {t(option.option_key || ' ')}{' '}
                                                            {option.option_type === 'text'
                                                                ? option.value
                                                                : ''}
                                                        </li>
                                                    ))}
                                                </ul>{' '}
                                            </div>
                                            <div className="col-md-1">{item.quantity}</div>
                                            <div className="col-md-4" style={{ textAlign: 'left' }}>
                                                {item.total.toLocaleString()} {t(order.currency)}
                                            </div>
                                            <div>
                                                <hr style={{ borderColor: '#aaa' }} />
                                            </div>
                                        </div>
                                    ))}

                                    <div className="row">
                                        {!cashbackIncludedInTax && (
                                            <>
                                                <h6 className="col-md-6">{t('total_order')}</h6>
                                                <h6
                                                    className="col-md-6"
                                                    style={{ textAlign: 'left' }}
                                                >
                                                    {totalOrder.toLocaleString()}{' '}
                                                    {t(order.currency)}
                                                </h6>
                                                <h6 className="col-md-6">{t('Discount')}</h6>
                                                <h6
                                                    className="col-md-6"
                                                    style={{ textAlign: 'left' }}
                                                >
                                                    {discount.toLocaleString()} {t(order.currency)}
                                                </h6>
                                            </>
                                        )}
                                        <h6 className="col-md-6">{t('Subtotal')}</h6>
                                        <h6 className="col-md-6" style={{ textAlign: 'left' }}>
                                            {(
                                                subtotal +
                                                (order.discount || 0) -
                                                (order.shipping_fees || 0)
                                            ).toLocaleString()}{' '}
                                            {t(order.currency)}
                                        </h6>
                                    </div>
                                    {!!order.discount && (
                                        <div className="row">
                                            <h6 className="col-md-6">{t('Discount')}</h6>
                                            <h6 className="col-md-6" style={{ textAlign: 'left' }}>
                                                {order.discount} {t(order.currency)}
                                            </h6>
                                        </div>
                                    )}
                                    {!order.has_subscription && (
                                        <div className="row">
                                            <h6 className="col-md-6">{t('Shipping Fees')}</h6>
                                            <h6 className="col-md-6" style={{ textAlign: 'left' }}>
                                                {order.shipping_fees
                                                    ? order.shipping_fees + ' ' + t(order.currency)
                                                    : t('Free')}
                                            </h6>
                                        </div>
                                    )}
                                    <hr />
                                    <div className="row">
                                        <h6 className="col-md-6">{t('VAT')}</h6>
                                        <h6 className="col-md-6" style={{ textAlign: 'left' }}>
                                            {vat.toLocaleString()} {t(order.currency)}
                                        </h6>
                                    </div>
                                    {!cashbackIncludedInTax && (
                                        <div className="row">
                                            <h6 className="col-md-6">{t('Discount(Tax gift)')}</h6>
                                            <h6 className="col-md-6" style={{ textAlign: 'left' }}>
                                                {totalCashback.toLocaleString()} {t(order.currency)}
                                            </h6>
                                        </div>
                                    )}
                                    <div className="row">
                                        <h6 className="col-md-6">{t('Total')}</h6>
                                        <h6 className="col-md-6" style={{ textAlign: 'left' }}>
                                            {order.total.toLocaleString()} {t(order.currency)}
                                        </h6>
                                    </div>
                                </Card.Body>
                            </Card>

                            {!controls && order.status === 'completed' && !order.rating && (
                                <Card>
                                    <Card.Header>{t('Order Rating')}</Card.Header>
                                    <Card.Body>
                                        <div className="rating">
                                            {[5, 4, 3, 2, 1].map((rating) => (
                                                <span
                                                    key={rating}
                                                    className={
                                                        'fas fa-star ' +
                                                        (order.new_rating >= rating ? 'active' : '')
                                                    }
                                                    onClick={() =>
                                                        setOrder({ ...order, new_rating: rating })
                                                    }
                                                />
                                            ))}
                                        </div>

                                        <textarea
                                            value={order.rating_notes}
                                            style={{ width: '100%' }}
                                            rows={3}
                                            onChange={(e) =>
                                                setOrder({ ...order, rating_notes: e.target.value })
                                            }
                                        />

                                        <Button onClick={submitRating}>{t('Save')}</Button>
                                    </Card.Body>
                                </Card>
                            )}

                            {controls && (
                                <Card>
                                    <Card.Header>{t('Notes')}</Card.Header>
                                    <Card.Body>
                                        <AsyncCreatableSelect
                                            cacheOptions
                                            defaultOptions
                                            isMulti
                                            value={
                                                order.tags?.map((tag) => ({
                                                    value: tag,
                                                    label: tag,
                                                })) || []
                                            }
                                            onChange={(tags) =>
                                                setOrder({
                                                    ...order,
                                                    tags: tags.map((tag) => tag.value),
                                                })
                                            }
                                            loadOptions={(tag, callback) => {
                                                api(
                                                    '/commerce/orders/data/tags/?tag=' +
                                                        (tag || ''),
                                                    {
                                                        result: (tags) =>
                                                            callback(
                                                                tags?.data?.map((tag) => ({
                                                                    value: tag.tag,
                                                                    label: tag.tag,
                                                                }))
                                                            ),
                                                    }
                                                )
                                            }}
                                        />
                                        <textarea
                                            value={order.notes}
                                            style={{ width: '100%', marginTop: 10 }}
                                            rows={5}
                                            onChange={(e) =>
                                                setOrder({ ...order, notes: e.target.value })
                                            }
                                        />
                                        <Button onClick={updateNotes}>{t('Save')}</Button>
                                    </Card.Body>
                                </Card>
                            )}

                            <Card>
                                <Card.Header>{t('Payment')}</Card.Header>
                                <Card.Body>
                                    {payment && (
                                        <div className="row" style={{ alignItems: 'center' }}>
                                            <div className="col-md-6">{payment.displayed_name}</div>
                                            <div className="col-md-6" style={{ textAlign: 'left' }}>
                                                <img
                                                    src={payment.image}
                                                    alt=""
                                                    style={{ maxHeight: 40 }}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {controls && order.payment && (
                                        <div className="d-flex justify-content-between mt-4">
                                            <h6>
                                                {t('id')} {order.payment.id}
                                            </h6>
                                            <input
                                                type="text"
                                                value={order.payment.gateway_reference}
                                                disabled
                                            />
                                        </div>
                                    )}

                                    {!controls &&
                                        order.status === 'pending' &&
                                        order.payment_method_id &&
                                        order.payment_driver !== 'onsite' && (
                                            <div style={{ marginTop: 15 }}>
                                                <Button onClick={() => makePayment()}>
                                                    {t('Proceed to payment')}
                                                </Button>
                                                <Button
                                                    onClick={() => makePayment(true)}
                                                    style={{ margin: '0 10px' }}
                                                >
                                                    {t('Cash on delivery')}
                                                </Button>
                                            </div>
                                        )}
                                </Card.Body>
                            </Card>

                            {shipping && (
                                <Card>
                                    <Card.Header>{t('Shipping Method')}</Card.Header>
                                    <Card.Body>
                                        <div
                                            className="row"
                                            style={{ alignItems: 'center', marginBottom: 10 }}
                                        >
                                            <div className="col-md-12">
                                                {shipping.displayed_name}
                                            </div>
                                        </div>

                                        {shipping.address_required && (
                                            <table width="100%">
                                                <tbody>
                                                    <tr>
                                                        <th width="30%">{t('Full Name')}</th>
                                                        <td>{order.delivery_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>{t('Mobile')}</th>
                                                        <td dir="ltr">{order.delivery_phone}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>{t('Email')}</th>
                                                        <td>{order.delivery_email}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>{t('Delivery Time')}</th>
                                                        <td>{order.delivery_time}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        )}

                                        {controls && (
                                            <PrintShippingDetails
                                                order={order}
                                                controls={controls}
                                                orderDate={orderDate}
                                                since={since}
                                                arabicNumeralMap={arabicNumeralMap}
                                                statusLabel={statusLabel}
                                            />
                                        )}
                                    </Card.Body>
                                </Card>
                            )}

                            {!!order.history && (
                                <Card className="no-print">
                                    <Card.Header>{t('History')}</Card.Header>
                                    <Card.Body>
                                        <ul dir="ltr" style={{ textAlign: 'left' }}>
                                            {order.history.map((h, i) => (
                                                <li key={i}>
                                                    <pre>{h.replace(': ', ':\n')}</pre>
                                                </li>
                                            ))}
                                        </ul>
                                    </Card.Body>
                                </Card>
                            )}
                        </div>
                    </div>
                </>
            )}

            <Modal show={!!code} onHide={() => setCode(null)}>
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!!code && (
                        <>
                            <QRCode value={code} /> <h3>{code}</h3>
                        </>
                    )}
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default OrderDetails
