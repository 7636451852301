import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import api from '../../utilities/API'
import { Table } from 'react-bootstrap'
import * as t from 'counterpart'

function Addresses() {
    const dispatch = useDispatch()
    const user = useSelector((state) => state.portal.user)
    const [addresses, setAddresses] = useState({ data: [] })

    const load = () => {
        api('commerce/addresses/', {
            result: (data) => setAddresses(data || {}),
        })
    }

    useEffect(() => {
        if (user) {
            load()
        }
    }, [user, dispatch])

    const deleteAddr = (id) => {
        if (!window.confirm('Delete this address?')) {
            return
        }

        api('commerce/addresses/' + id, {
            method: 'DELETE',
            result: () => load(),
        })
    }

    return (
        <div id="addresses" className="container">
            <h1>{t('Addresses')}</h1>

            <div className="page-body">
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>{t('Name')}</th>
                            <th>{t('State')}</th>
                            <th>{t('City')}</th>
                            <th>{t('Building')}</th>
                            <th>{t('Delete')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {addresses.data.map((addr) => (
                            <tr key={addr.id}>
                                <td>{addr.name}</td>
                                <td>{addr.state}</td>
                                <td>{addr.city}</td>
                                <td>
                                    {addr.building}, {addr.floor}, {addr.apartment}
                                </td>
                                <td>
                                    <i
                                        className="fa fa-trash"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => deleteAddr(addr.id)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>

            {addresses.length === 0 && <div className="empty">لا يوجد لديك عناوين</div>}
        </div>
    )
}

export default Addresses
