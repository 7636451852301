import React from 'react'
import QRCode from 'qrcode.react'

function Ticket({ className, data, user, order }) {
    data.parent = { name: '// TODO Event Name' }
    console.log(data)
    return (
        <table
            className={className}
            style={{
                width: '100%',
                backgroundColor: '#efefef',
                margin: 20,
                borderRadius: 15,
                overflow: 'hidden',
            }}
        >
            <tbody>
                <tr>
                    <td colSpan={3} style={{ backgroundColor: '#c1ddff', padding: 20 }}>
                        <img className="logo" src="/logo.svg" width={200} alt="" />
                    </td>
                </tr>

                <tr className="ticket-info">
                    <td
                        width="20%"
                        style={{ padding: '1%', borderRight: '5px dotted #ffffff' }}
                        align="center"
                    >
                        <img
                            alt=""
                            src="https://objects-us-east-1.dream.io/newstn/2020/09/6ffceb51d8f974f6ded392ac7e862d58.png"
                            height="100"
                        />
                    </td>
                    <td style={{ padding: 10 }} valign="top">
                        <table width="100%">
                            <tbody>
                                <tr>
                                    <td
                                        colSpan={2}
                                        style={{ borderBottom: '2px solid #ddd', padding: 10 }}
                                    >
                                        <div>
                                            <span style={{ color: '#a0b7d3' }}>Ticket Owner:</span>{' '}
                                            {order.name}
                                        </div>
                                        <div>
                                            <span style={{ color: '#a0b7d3' }}>Order Number:</span>{' '}
                                            {data.id}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ borderRight: '2px solid #ddd' }}>
                                        <h1
                                            style={{ margin: '10px auto', display: 'inline-block' }}
                                        >
                                            {data.product && data.product.parent_name}
                                        </h1>
                                        <h5>King Abdullah Sport City Stadium</h5>
                                        <h3>
                                            {data.total} {order.currency}
                                        </h3>
                                    </td>
                                    <td width="30%" style={{ textAlign: 'center', padding: 10 }}>
                                        <h6>{data.name}</h6>
                                        <h1>Seat</h1>
                                        <h2>15D</h2>
                                        {data.product && (
                                            <img src={data.product.thumbnail} width="50%" alt="" />
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <td
                        width="20%"
                        style={{ padding: '1%', borderLeft: '5px dotted #ffffff' }}
                        align="center"
                    >
                        <QRCode value={data.code} />
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

export default Ticket
