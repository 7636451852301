import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { Button, Dropdown } from 'react-bootstrap'
import * as t from 'counterpart'
import { ReactComponent as Icon } from './style/user.svg'
import { flushStorage } from '../../../utilities/storage'
import './style/UserMenu.scss'

const withCompare = process.env.REACT_APP_WITH_COMPARE === 'true'
const subscription = process.env.REACT_APP_ENABLE_SUBSCRIPTION === 'true'

export default React.forwardRef(function (props = {}, ref) {
    const { user } = useSelector((state) => state.portal)
    const { store } = useParams()

    props = {
        ...props,
        className: 'user-menu-block ' + (props.className || ''),
    }

    const title = props.title?.replace('{first_name}', user?.first_name) || t('My Account')
    delete props.title

    if (!user) {
        return (
            <div {...props} ref={ref}>
                <Button as={Link} variant="link" to={`/${store}/auth/login`}>
                    <span>{t('Login / Register')}</span>
                    <Icon />
                </Button>
            </div>
        )
    }

    return (
        <Dropdown {...props} ref={ref} show align={t('dir') === 'rtl' ? 'start' : 'end'}>
            <Dropdown.Toggle>
                <span>{title}</span>
                <Icon />
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item as={Link} to={`/${store}/account`}>
                    {' '}
                    <i className="fas fa-id-card-alt" />
                    {t('Account Setting')}
                </Dropdown.Item>
                {/*<Dropdown.Item as={Link} to={`/${store}/account/notifications`}> <i className="fas fa-bell"/>{t("Notifications")}</Dropdown.Item>*/}
                <Dropdown.Divider />
                <Dropdown.Item as={Link} to={`/${store}/orders`}>
                    <i className="fas fa-file-alt" />
                    {t('My Orders')}
                </Dropdown.Item>
                {!subscription && (
                    <Dropdown.Item as={Link} to={`/${store}/addresses`}>
                        <i className="fas fa-address-card" />
                        {t('Your Addresses')}
                    </Dropdown.Item>
                )}
                {!subscription && <Dropdown.Divider />}
                {!subscription && (
                    <Dropdown.Item as={Link} to={`/${store}/wishlist`}>
                        <i className="fas fa-star" />
                        {t('Favorites')}
                    </Dropdown.Item>
                )}
                {withCompare && (
                    <Dropdown.Item as={Link} to={`/${store}/compare`}>
                        <i className="fas fa-fan" /> {t('Compare Products')}
                    </Dropdown.Item>
                )}
                <Dropdown.Divider />
                <Dropdown.Item onClick={flushStorage}>
                    <i className="fas fa-sign-out-alt" />
                    {t('Sign out')}
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
})
