import { Form } from 'react-bootstrap'
import t from 'counterpart'

type ContactInfoProps = {
    onContactChange: (event: { target: { name: string; value: any } }) => void
    order: {
        name: string
        phone: string
        email: string
    }
}

export const ContactInfo = ({ onContactChange, order }: ContactInfoProps) => {
    return (
        <div id="contact-info">
            <h3>
                <span>1</span>
                {t('Contact Information')}
            </h3>
            <div className="row">
                <Form.Group controlId="name" className="col-md-4">
                    <Form.Label>{t('Full Name')}</Form.Label>
                    <Form.Control name="name" value={order.name} onChange={onContactChange} />
                </Form.Group>

                <Form.Group controlId="phone" className="col-md-4">
                    <Form.Label>{t('Mobile')}</Form.Label>
                    <Form.Control name="phone" value={order.phone} onChange={onContactChange} />
                </Form.Group>

                <Form.Group controlId="email" className="col-md-4">
                    <Form.Label>{t('Email')}</Form.Label>
                    <Form.Control name="email" value={order.email} onChange={onContactChange} />
                </Form.Group>
            </div>
        </div>
    )
}
