import React from 'react'
import { connect } from 'react-redux'
import { Link, useParams } from 'react-router-dom'

import './style/Categories.scss'

const Categories = ({ categories, id, className }) => {
    const { store } = useParams()

    const renderLevel = (parent = 0, level = 1) => (
        <ul className={'level-' + level}>
            {(categories[parent] || []).map((cat, key) => (
                <li key={key}>
                    <Link to={`/${store}/category/${cat.id}`}>
                        {cat.image && <img src={cat.image} alt="" />}
                        {cat.icon && <i className={cat.icon} />}
                        {cat.name}
                    </Link>

                    {renderLevel(cat.id, level + 1)}
                </li>
            ))}
        </ul>
    )

    return (
        <div id={id} className={'cms-categories-block ' + (className || '')}>
            {renderLevel()}
        </div>
    )
}

const mapStateToProps = ({ portal }) => ({ categories: portal.categories })

export default connect(mapStateToProps)(Categories)
