import { Badge } from 'react-bootstrap'
import t from 'counterpart'

function ProductBadges({ product, externalStyles = false }) {
    const displayCashback = !!!window.portalSetting('commerce.cashback.display')
    return (
        <div
            className="badges"
            style={
                externalStyles
                    ? {}
                    : { position: 'absolute', display: 'flex', flexWrap: 'wrap', top: 0, right: 0 }
            }
        >
            {product.labels?.map((label, i) => (
                <Badge key={i} bg="success">
                    {label}{' '}
                </Badge>
            ))}
            {!!product.cash_back && displayCashback && (
                <Badge bg="warning">
                    {t('With %(amount)s %(currency)s cashback', {
                        amount: product.cash_back,
                        currency: window.portalSetting('commerce.currency'),
                    })}{' '}
                </Badge>
            )}
            {!!product.saved_amount && (
                <Badge bg="danger">
                    {t('saved_amount')} <span className="percent">{product.saved_percent} ٪</span>{' '}
                    <span className="amount">
                        {product.saved_amount} {window.portalSetting('commerce.currency')}
                    </span>{' '}
                </Badge>
            )}
            {product.available_stock < 6 && (
                <Badge bg="info">
                    {t('Available')} {product.available_stock}{' '}
                </Badge>
            )}
            {!!product.gifts_ids && <Badge bg="info">{t('Gift')} </Badge>}
        </div>
    )
}

export default ProductBadges
