import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import * as t from 'counterpart'
import api from '../../../utilities/API'

function PaymentMethods(props) {
    const [methods, setMethods] = useState([])
    const [selected, setSelected] = useState('payment-cash')

    const includeCashback = !(window.portalSetting('commerce.cashback.type') === 'included')
    const displayCashback = !!!window.portalSetting('commerce.cashback.display')

    const total = includeCashback
        ? props.totals.total
        : props.totals.total - props.totals.totalCashBack

    // load initial data
    useEffect(() => {
        api('payment/methods/?active=1', {
            result: (data) => {
                setMethods(
                    (data || []).map((method) => {
                        if (method.frontend_setting) {
                            try {
                                method.frontend_setting = eval('(' + method.frontend_setting + ')')
                            } catch (e) {
                                console.log('payment method hook: ', e)
                            }
                        }

                        return method
                    })
                )

                // set the first method by default
                if (data.length) {
                    onChange(data[0])
                }
            },
        })
    }, [])

    useEffect(() => {
        methods.forEach((method) => {
            const ctx = {
                lang: t.getLocale(),
                currency: window.portalSetting('commerce.currency', '', false),
            }

            try {
                method.frontend_setting?.hooks?.checkout_page_methods?.(method, total, ctx)
            } catch (e) {
                console.log('payment method hook: ', e)
            }
        })
    }, [methods, total])

    //
    const onChange = (method) => {
        setSelected(method ? method.id : 'payment-cash')
        if (props.onChange) {
            props.onChange(method)
        }
    }
    return (
        <div id="payment">
            <h3>
                <span>3</span>
                {t('Payment Method')}
            </h3>

            {methods.map((method) => (
                <label
                    key={method.id}
                    className={'method ' + (method.id === selected ? 'selected' : '')}
                    id={'payment-method-' + method.id}
                >
                    <Form.Check
                        id={'payment-' + method.id}
                        type="radio"
                        label={method.displayed_name}
                        checked={method.id === selected}
                        onChange={() => onChange(method)}
                    />
                    <img src={method.image} alt="" />

                    <div className="payment-hook" />
                </label>
            ))}

            {false && displayCashback && (
                <label className={'method ' + (selected === 'payment-cash' ? 'selected' : '')}>
                    <Form.Check
                        id={'payment-cash'}
                        type="radio"
                        label={t('Cash on delivery')}
                        checked={selected === 'payment-cash'}
                        onChange={() => onChange(null)}
                    />
                    <img src="/assets/payment/cash.svg" alt="" />
                </label>
            )}
        </div>
    )
}

export default PaymentMethods
