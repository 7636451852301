import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Alert, Navbar } from 'react-bootstrap'
import storage from '../utilities/storage'
import * as t from 'counterpart'
import api from '../utilities/API'

function View({ name, mainBody }) {
    const [view, setView] = useState(null)
    const [replace, setReplace] = useState({})
    const styleRef = useRef()
    const store = useParams().store

    let slug = (name || window.location.pathname.replace('/' + store, '') || '/').replace('/', '')
    if (!slug) {
        slug = 'home'
    }

    // theme preview
    let withTheme = '';
    if(['layout', 'home'].includes(slug)) {
        const theme = storage('theme_key')
        if (theme) {
            withTheme = theme
            slug = theme + '_' + slug
        }
    }

    // get saved view data from local storage and fresh copy from api
    useEffect(() => {
        if (slug.includes('voucher/')) {
            const p = slug.split('/')
            slug = p[0]
            setReplace((state) => ({ ...state, voucher_code: p[1] }))
        }

        const storageKey = 'view_' + t.getLocale() + '_' + slug
        const localData = storage(storageKey)
        const styleElm = styleRef.current

        if (localData) {
            setView(localData)
        }

        load(slug, storageKey, localData, setView, withTheme)

        return () => {
            if (styleElm) {
                styleElm.remove()
            }
        }
    }, [slug])

    return useMemo(() => {
        if (view === null) {
            return
        }

        if (view === 404) {
            return (
                <div style={{ fontSize: 50, textAlign: 'center', margin: 50 }}>
                    <img src="/assets/404.svg" width="500" alt="" />
                    <h4>{t('There are no data right now')}</h4>
                </div>
            )
        }

        // css
        const style = document.createElement('style')
        style.innerText = view.css
        document.head.appendChild(style)

        return render(view.blocks, mainBody, store, replace)
    }, [view, mainBody, store])
}

// load
function load(slug, storageKey, localData, setView, withTheme="") {
    api('cms/contents/' + slug.replace('/', '_') + '/rendered', {
        result: (data) => {
            try {
                if (!data.rendered || (localData && data.updated_at === localData.updated_at)) {
                    return
                }

                const rendered = JSON.parse(data.rendered)
                rendered.updated_at = data.updated_at

                storage(storageKey, rendered)
                setView(rendered)
            } catch (e) {
                console.log('view error:', e)
            }
        },
        error: (msg, resp) => {
            // add theme fall back to default templates
            if(withTheme) {
                load(slug.replace(withTheme+"_", ""), storageKey, localData, setView)
            }

            if (!localData || resp.response?.status === 404) {
                setView(404)
            }
        },
    })
}

// render
function render(components, mainBody, store, replace) {
    if (!components) {
        return null
    }

    return components.map((child, i) => {
        if (child.type === 'main-body') {
            return mainBody
        }

        let Tag = getTag(child, store)
        const props = {
            key: i,
            className: child.classes || '',
            ...child.attributes,
        }

        //
        if (child.type === 'link' && props.href === '#menu-toggle') {
            delete props.href
            delete props.to

            props.onClick = (e) => {
                e.preventDefault()
                document.getElementById('mobile_menu').classList.toggle('active')
            }
        }

        if (Tag === 'img' || Tag === 'br') {
            return <Tag {...props} />
        }

        let content = child.content
        if (replace) {
            for (const k in replace) {
                content = content?.replace('{' + k + '}', replace[k])
            }
        }

        return (
            <Tag {...props}>
                {content}
                {render(child.components, mainBody, store, replace)}
            </Tag>
        )
    })
}

// tag name
function getTag(e, store) {
    if (e.tagName === 'p') {
        return 'div'
    }

    if (e.attributes.custom) {
        return (window.viewBlocks[e.type] || {}).component
    }

    switch (e.type) {
        case 'header':
            return 'h1'

        case 'img':
        case 'bs-image':
            e.attributes = { ...e.attributes, loading: 'lazy' }
            return 'img'

        case 'link':
            e.attributes.to = e.attributes.href?.replace('{store}', store)
            const h = e.attributes?.href
            return h?.startsWith('http') || h?.startsWith('javascript:') ? 'a' : Link

        case 'map':
            delete e.attributes.frameborder
            e.attributes.src =
                'https://maps.google.com/maps?q=2880%20Broadway,%20New%20York&t=&z=13&ie=UTF8&iwloc=&output=embed'
            return 'iframe'

        case 'container':
            if (e.attributes?.id === 'header' || e.attributes?.id === 'header2') {
                e.attributes.sticky = 'top'
                return Navbar
            }
            return 'div'

        case 'alert':
            e.attributes.dismissible = true
            e.attributes.onClose = (closed, ev) => ev.target.parentNode.remove()
            return Alert

        // case "container": case "row": case "column":
        // case "text": case "textnode": case "paragraph":
        // 	return "div";

        default:
            return e.tagName || 'div'
    }
}

export default View
