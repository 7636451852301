import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import * as t from 'counterpart'

export const BreadCrumbs = ({ product, store }) => {
    return (
        <div id="breadcrumbs">
            <i className={'fa fa-caret-' + t('right')} />{' '}
            <Link to={'/' + store}>{t('Homepage')}</Link>
            <BreadCrumbsList categories={product.categoriesBreadcrumbs} store={store} />
            <span> / </span>
            <a> {product.name}</a>
        </div>
    )
}

const BreadCrumbsList = ({ categories, store }) => {
    return categories.map((category, index) => (
        <Fragment key={index}>
            <span> / </span>
            <Link key={category.id} to={'/' + store + '/browse/' + category.id}>
                {category.name}
            </Link>
        </Fragment>
    ))
}
