import { useEffect, useState } from 'react'
import t from 'counterpart'
import api from '../../../../utilities/API'
import './Locations.scss'
import { LocationDTO } from '../../../../Types/DTOs/LocationDTO'

type LocationsProps = {
    onChange: (locId: number) => void
}
const Locations = ({ onChange }: LocationsProps) => {
    const [locations, setLocations] = useState<LocationDTO[]>([])
    const [selected, setSelected] = useState<LocationDTO | null>(null)

    // load initial data
    useEffect(() => {
        api('commerce/locations/?pickup=1', {
            result: ({ data }: { data: LocationDTO[] }) => {
                setLocations(data || [])
                if (data.length) {
                    handleChange(data[0])
                }
            },
            error: alert,
        })
    }, [])

    //
    const handleChange = (loc: LocationDTO) => {
        setSelected(loc)
        if (onChange) {
            onChange(loc.id)
        }
    }

    return (
        <div id="locations-wrapper">
            <h3>
                <span>
                    <i className="fa fa-location" />
                </span>
                {t('Pickup Location')}
            </h3>
            <div id="addresses">
                {locations.map((loc) => (
                    <div
                        key={loc.id}
                        className={'address ' + (loc.id === selected?.id ? 'selected' : '')}
                        onClick={() => handleChange(loc)}
                    >
                        {loc.name}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Locations
