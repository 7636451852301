import React from 'react'
import { Carousel } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import './style/Slider.scss'

export default React.forwardRef(function (props, ref) {
    let { indicators, fade, slides } = props
    const { store } = useParams()

    props = {
        ...props,
        className: 'slider-block ' + props.className,
    }

    try {
        slides = JSON.parse(slides)
    } catch (e) {
        console.log('Slider parse error:', e)
    }

    delete props.slides
    delete props.indicators
    delete props.fade

    return (
        <div {...props} ref={ref}>
            <Carousel
                controls={props.controls !== false}
                indicators={indicators !== false}
                fade={fade}
            >
                {(slides || []).map((slide, i) => {
                    let props = {}
                    if (slide.link) {
                        if (slide.link.includes('http')) {
                            props = { as: 'a', href: slide.link, target: '_blank' }
                        } else {
                            props = { as: Link, to: '/' + store + slide.link }
                        }
                    }

                    return (
                        <Carousel.Item key={i} style={slide.style} {...props}>
                            {(slide.images || []).map((image, i) => (
                                <img key={i} src={image.path} alt="" style={image.style} />
                            ))}
                            <Carousel.Caption>
                                <h3>{slide.title}</h3>
                                <p>{slide.text}</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                    )
                })}
            </Carousel>
        </div>
    )
})
