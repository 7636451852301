import { Link, useParams } from 'react-router-dom'

import '../style/SellerBox.scss'

let imagePrefix = process.env.REACT_APP_IMAGES_PREFIX || "";

function SellerBox({
    seller,
    withLink = true,
    className = 'seller-box-block',
}) {
    const { store } = useParams()
    const url = `/${store}/browse/?seller_id=${seller.id}`
    const InfoTag = withLink ? Link : 'div'

    return (
        <div className={className}>
            <InfoTag className="box-info" to={url}>
                <div className="top-section">
                    <img
                        className="image"
                        src={`${imagePrefix}${seller.image}`}
                        alt="seller"
                    />
                </div>
                <div className="bottom-section">
                    <div>
                        <h4 className="title">{seller.name}</h4>
                    </div>
                </div>
            </InfoTag>
        </div>
    )
}

export default SellerBox
