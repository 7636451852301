import React, { useEffect } from 'react'
import { Badge, Button, Dropdown } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { Link, NavLink, useParams } from 'react-router-dom'
import * as t from 'counterpart'
import { loadFavorites } from '../actions'
import { ReactComponent as Icon } from './style/favorites.svg'
import './style/FavoritesPopup.scss'

let imagePrefix = process.env.REACT_APP_IMAGES_PREFIX || "";

export default React.forwardRef(function (props = {}, ref) {
    const dispatch = useDispatch()
    const items = useSelector((state) => state.commerce.favorites.data)
    const user = useSelector((state) => state.portal.user)
    const { store } = useParams()
    const direct = props.direct

    props = {
        ...props,
        className: 'favorites-popup-block ' + (props.className || ''),
    }

    delete props.direct

    useEffect(() => {
        if (user) {
            dispatch(loadFavorites())
        }
    }, [user, dispatch])

    return (
        <Dropdown {...props} ref={ref} as={direct ? NavLink : 'div'} to={`/${store}/wishlist`}>
            <Dropdown.Toggle>
                <span>{t('Favorites')}</span>
                <Icon />
                {items.length !== 0 && <Badge variant="light">{items.length}</Badge>}
            </Dropdown.Toggle>

            {!direct && (
                <Dropdown.Menu>
                    {items.map((item) => (
                        <Dropdown.Item
                            key={item.id}
                            to={`/${store}/details/${item.slug}-${item.id}`}
                            as={Link}
                        >
                            <div
                                className="image"
                                style={{
                                    backgroundImage: "url('" + imagePrefix + item.thumbnail + "')",
                                }}
                            />
                            <div>{item.name}</div>
                        </Dropdown.Item>
                    ))}

                    {items.length !== 0 && (
                        <Dropdown.Item as="div">
                            <Button as={Link} to={`/${store}/wishlist`} variant="outline-primary">
                                {t('Browse your Favorites')}
                            </Button>
                        </Dropdown.Item>
                    )}

                    {items.length === 0 && (
                        <div className="empty">{t('There are no items in your favorites')}</div>
                    )}
                </Dropdown.Menu>
            )}
        </Dropdown>
    )
})
