import { Alert } from 'react-bootstrap'
import { ProductItem } from './ProductItem'
import { ProductListProps } from '../Types'
import t from 'counterpart'

export const ProductsList = ({ products, cart }: ProductListProps) => {
    return (
        <div id="products">
            <h1>
                <i className="fa fa-shopping-cart" />
                {t('Cart')}{' '}
                <span>
                    ({cart.items.length} {t('Items')})
                </span>
            </h1>
            <div className="products-list">
                {products.map((product) => (
                    <ProductItem key={product.item.id} item={product.item} cart={cart} />
                ))}
            </div>
            {cart.error && <RenderErrorAlert error={cart.error} />}
        </div>
    )
}

const RenderErrorAlert = ({ error }: any) => {
    return (
        <div className="cart-alert">
            <Alert variant="danger">
                {typeof error === 'object'
                    ? Object.keys(error).map((field) => (
                          <div key={field}>
                              <h4>{t(field)}</h4>
                              <ul>
                                  {(error[field] || []).map((msg: string, i: number) => (
                                      <li key={i}>{msg}</li>
                                  ))}
                              </ul>
                          </div>
                      ))
                    : error}
            </Alert>
        </div>
    )
}
