import Carousel from '../components/Carousel'
import SellerBox from './SellerBox'

export default function SellerCarousel(props) {
    return (
        <Carousel
            {...props}
            loadedItems={props.loadedSellers}
            renderItem={(seller, key, extended) => (
                <SellerBox key={key} seller={seller} withDescription={extended} />
            )}
            apiEndpoint="/commerce/sellers"
        />
    )
}
