import TagManager from 'react-gtm-module'

export const triggerViewDetailsGEvent = (product, currency) => {
    let productList = []
    let value = 0

    if (product?.id) {
        // Calculate price based on saved amount
        const price =
            product.saved_amount && product.saved_amount > 0 ? product.current_price : product.price

        productList.push({
            item_id: product.id,
            item_name: product.name,
            price: price,
            discount: product.saved_amount,
        })

        value = price
    }

    const tagManagerArgs = {
        dataLayer: {
            event: 'view_item',
            ecommerce: {
                currency: currency,
                value,
                items: productList,
            },
        },
    }

    TagManager.dataLayer(tagManagerArgs)
}
