import { useEffect, useState, useRef, useCallback, useMemo } from 'react'
import { Button, Form, Alert } from 'react-bootstrap'
import t from 'counterpart'
import { GoogleMap, LoadScript, Marker, Autocomplete } from '@react-google-maps/api'
import api from '../../../../utilities/API'
import './Addresses.scss'
import { AddressForm } from './AddressForm'
import { AddressDTO } from '../../../../Types/DTOs/AddressDTO'
import { AddressesProps, NewAddressFormType } from './types'
// adding this directly shows error
const mapLibs: ('places' | 'geometry' | 'drawing' | 'localContext' | 'visualization')[] = ['places']

const INITIAL_ADDRESS_FORM = {
    name: '',
    country: '',
    state: '',
    city: '',
    street: '',
    building: '',
    floor: '',
    apartment: '',
    neighborhood: '',
    postal_code: '',
    plot_number: '',
    registry_name: '',
    tax_number: '',
    registry_number: '',
    lat: null,
    lng: null,
    default: false,
}

const Addresses = ({ is_order_business, onChange, review = false }: AddressesProps) => {
    const [addresses, setAddresses] = useState<AddressDTO[]>([])
    const [selectedId, setSelectedId] = useState<number | null>(null)
    const [newAddressForm, setNewAddressForm] = useState<NewAddressFormType>(INITIAL_ADDRESS_FORM)

    const autoRef = useRef<google.maps.places.Autocomplete | null>(null)
    const apiKey = window.portalSetting('web.google.maps.key')

    const handleAddressChange = useCallback((addrId: number | null) => {
        setSelectedId(addrId)
        if (onChange) {
            onChange(addrId)
        }
    }, [])
    // load initial data
    useEffect(() => {
        api('commerce/addresses/', {
            result: ({ data }: { data: AddressDTO[] }) => {
                if (!data || data.length === 0) {
                    setAddresses([])
                    handleAddressChange(null) // You might want to handle this in your function
                    return
                }

                setAddresses(handleAddressReordering(data))

                // Handle the change with the selected address
            },
            error: alert,
        })
    }, [handleAddressChange])

    const onAddressFormChange = ({ target }: { target: { name: string; value: string } }) => {
        setNewAddressForm((prevSelected: any) => ({ ...prevSelected, [target.name]: target.value }))
    }
    const onSubmitAddressSucess = (data: AddressDTO) => {
        setAddresses((prevAddress) => [data, ...prevAddress])
        setNewAddressForm(() => INITIAL_ADDRESS_FORM)
        handleAddressChange(data.id)
    }
    //

    const onMapClick = useCallback(
        (e: any) => {
            const location = { lat: e.latLng.lat(), lng: e.latLng.lng() }
            new window.google.maps.Geocoder().geocode(
                e.placeId ? { placeId: e.placeId } : { location },
                (p) => {
                    if (p) {
                        const addr = parseAddress(p[0])
                        if (addr.country && addr.state && addr.city) {
                            setNewAddressForm((prevSelected: any) => ({
                                ...prevSelected,
                                ...location,
                                ...addr,
                            }))
                        }
                    }
                }
            )
        },
        [selectedId]
    )

    //
    const autocompletePlace = () => {
        const p = autoRef.current?.getPlace()
        if (p?.geometry) {
            const l = p.geometry.location
            const addr = parseAddress(p)
            if (addr.country && addr.state && addr.city) {
                setNewAddressForm((prevState: any) => ({
                    ...prevState,
                    ...addr,
                    lat: l?.lat(),
                    lng: l?.lng(),
                }))
            }
        }
    }

    //
    const currentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                onMapClick({
                    latLng: {
                        lat: () => position.coords.latitude,
                        lng: () => position.coords.longitude,
                    },
                })
            })
        }
    }

    const handleAddressReordering = useCallback(
        (addresses: AddressDTO[]) => {
            const selectedAddress = addresses.find((i) => i.default) || addresses[0] || null
            const addressWithoutSelected = addresses.filter((i) => selectedAddress.id !== i.id)
            if (selectedAddress) {
                handleAddressChange(selectedAddress.id)
                return [selectedAddress, ...addressWithoutSelected]
            }
            return addresses
        },
        [handleAddressChange]
    )
    const selectedAddresses = useMemo(() => {
        const filtedAddresses = is_order_business
            ? addresses.filter(
                  (addr) => addr.registry_name && addr.registry_number && addr.tax_number
              )
            : addresses
        return handleAddressReordering(filtedAddresses)
    }, [addresses, is_order_business, handleAddressReordering])
    return (
        <div id="address-wrapper">
            <h3>
                <span>
                    <i className="fa fa-location" />
                </span>
                {t('Shipping Address')}
            </h3>
            <div id="addresses">
                {selectedAddresses.map((addr) => (
                    <div
                        key={addr.id}
                        className={'address ' + (addr.id === selectedId ? 'selected' : '')}
                        onClick={review ? undefined : () => handleAddressChange(addr.id)}
                    >
                        <div>
                            <h6>{addr.name}</h6>
                            {addr.country} - {addr.state} - {addr.city} <br />
                            {addr.street} {addr.building}, {addr.floor}, {addr.apartment}
                        </div>
                    </div>
                ))}
            </div>
            {selectedId && (
                <div onClick={() => handleAddressChange(null)} className="new-address-button">
                    <i className="fa fa-plus" /> {t('Add New Address')}
                </div>
            )}

            <LoadScript googleMapsApiKey={apiKey} libraries={mapLibs}>
                {!selectedId && (
                    <div id="new-address" className="row">
                        <GoogleMap
                            mapContainerStyle={{ width: '100%', height: '400px' }}
                            center={{
                                lat: newAddressForm.lat || 23.1010706,
                                lng: newAddressForm.lng || 44.2407631,
                            }}
                            zoom={5.4}
                            onClick={onMapClick}
                        >
                            {newAddressForm.lat && newAddressForm.lng && (
                                <Marker
                                    position={{ lat: newAddressForm.lat, lng: newAddressForm.lng }}
                                />
                            )}

                            <Autocomplete
                                onLoad={(a) => (autoRef.current = a)}
                                onPlaceChanged={autocompletePlace}
                            >
                                <input
                                    type="text"
                                    placeholder="Search for a place"
                                    style={{
                                        boxSizing: `border-box`,
                                        border: `1px solid transparent`,
                                        width: `240px`,
                                        height: `40px`,
                                        padding: `0 12px`,
                                        borderRadius: `3px`,
                                        boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                        fontSize: `14px`,
                                        outline: `none`,
                                        textOverflow: `ellipses`,
                                        position: 'absolute',
                                        right: '64px',
                                        top: '10px',
                                        marginLeft: '-120px',
                                    }}
                                />
                            </Autocomplete>

                            <Button
                                onClick={currentLocation}
                                style={{ position: 'absolute', bottom: 10, left: 10 }}
                                variant="light"
                            >
                                <i className="fa fa-location-crosshairs" /> {t('Current Location')}
                            </Button>
                        </GoogleMap>

                        {(!newAddressForm.lat || !newAddressForm.lng) && (
                            <div className="d-flex align-items-center justify-content-center">
                                <h3>{t('Select your address on the map')}</h3>
                            </div>
                        )}

                        <AddressForm
                            is_order_business={is_order_business}
                            newAddressForm={newAddressForm}
                            onAddressFormChange={onAddressFormChange}
                            onSubmitAddressSucess={onSubmitAddressSucess}
                        />
                    </div>
                )}
            </LoadScript>
        </div>
    )
}

//
function parseAddress(p: google.maps.places.PlaceResult) {
    let addr = {
        name: p.formatted_address,
        country: '',
        state: '',
        city: '',
        street: '',
        building: '',
        floor: '',
        apartment: '',
        default: false,
    }

    p.address_components?.forEach((component: any) => {
        component.types.forEach((type: any) => {
            if (type === 'country' && component.types.includes('political')) {
                addr.country = component.short_name
            } else if (
                type === 'administrative_area_level_1' &&
                component.types.includes('political')
            ) {
                addr.state = component.short_name
            } else if (
                (type === 'administrative_area_level_2' || type === 'locality') &&
                component.types.includes('political')
            ) {
                addr.city = component.short_name
            } else if (type === 'route') {
                addr.street = component.long_name
            }
        })
    })

    return addr
}

export default Addresses
