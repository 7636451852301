import TagManager from 'react-gtm-module'

export const triggerAddToCartGEvent = (product, currency = 'SAR') => {
    // const formatedCurrency = convertCurrencyToStandardFormat(currency)
    let productList = []
    let value = 0

    if (product?.id) {
        // Calculate price based on saved amount
        const price =
            product.saved_amount && product.saved_amount > 0 ? product.current_price : product.price

        productList.push({
            item_id: product.id,
            item_name: product.name,
            price: price,
            discount: product.saved_amount,
        })

        value = price
    }

    const tagManagerArgs = {
        dataLayer: {
            event: 'add_to_cart',
            ecommerce: {
                currency: currency,
                value,
                items: productList,
            },
        },
    }

    TagManager.dataLayer(tagManagerArgs)
}

export const triggerRemoveFromCartGEvent = (product, currency = 'SAR') => {
    let productList = []
    let value = 0

    if (product?.id) {
        // Calculate price based on saved amount
        const price =
            product.saved_amount && product.saved_amount > 0
                ? product.current_price - (product.cash_back || 0)
                : product.price - (product.cash_back || 0)

        productList.push({
            item_id: product.id,
            item_name: product.name,
            price: price,
            discount: product.saved_amount,
            quantity: product.cart_quantity,
        })
        value = product.price * product.quantity
    }
    const tagManagerArgs = {
        dataLayer: {
            event: 'remove_from_cart',
            ecommerce: {
                currency: currency,
                value,
                items: productList,
            },
        },
    }

    TagManager.dataLayer(tagManagerArgs)
}

export const triggerViewCartGEvent = (products = [], currency = 'SAR') => {
    let productsList = []
    let value = 0

    if (products?.length > 0) {
        productsList = products.map((product) => {
            // Calculate price based on saved amount & cashback
            const price =
                product.saved_amount && product.saved_amount > 0
                    ? product.current_price - (product.cash_back || 0)
                    : product.price - (product.cash_back || 0)

            //return the product object
            return {
                item_id: product.id,
                item_name: product.name,
                price: price,
                discount: product.saved_amount + (product.cash_back || 0),
                quantity: product.cart_quantity,
            }
        })

        // Calculate total value of the products in the cart after discount and cashback
        value = productsList.reduce((acc, item) => {
            return acc + item.price * item.quantity
        }, 0)
    }

    // create the viewCart event object
    const tagManagerArgs = {
        dataLayer: {
            event: 'view_cart',
            ecommerce: {
                currency: currency,
                value,
                items: productsList,
            },
        },
    }

    // push the event to the dataLayer to trigger the event
    TagManager.dataLayer(tagManagerArgs)
}
