import { useEffect, useState, Dispatch, SetStateAction } from 'react'

//

export function useLocalState<T = any>(
    key: string,
    initialState: T
): [T, Dispatch<SetStateAction<T>>] {
    // Try to retrieve from localStorage
    const localState = localStorage.getItem(key)
    if (localState) {
        try {
            initialState = JSON.parse(localState)
        } catch {
            // Keep initialState as is if JSON parsing fails
        }
    }
    // Use React's useState with the parsed initialState
    const [state, setState] = useState<T>(initialState)

    // Wrapper to sync with localStorage
    const onSetState: Dispatch<SetStateAction<T>> = (newStateOrFn) => {
        setState((prevState) => {
            const newState =
                typeof newStateOrFn === 'function'
                    ? (newStateOrFn as (prev: T) => T)(prevState)
                    : newStateOrFn

            localStorage.setItem(key, JSON.stringify(newState))
            return newState
        })
    }

    // track local changes
    useEffect(() => {
        const handler = function (e: any) {
            if (e.key === key) {
                setState(JSON.parse(e.newValue))
            }
        }

        window.addEventListener('storage', handler)
        return () => window.removeEventListener('storage', handler)
    }, [key])

    return [state, onSetState]
}

//
const idbInstances: { [key: string]: IDBDatabase } = {}

interface IndexedDBOptions {
    version?: number
    index?: { name: string; key: string; options?: IDBIndexParameters }[]
}

interface Filters {
    index?: { name: string; value: any } // assuming the index values can be of any type
    filter?: (item: any) => boolean // assuming filter can accept any item
}

export function useIndexedDB<T = any>(
    dbName: string,
    storeName: string,
    options: IndexedDBOptions = {}
) {
    const [db, setDB] = useState<IDBDatabase | null>(null)
    const [items, setItems] = useState<T[]>([])
    const [updates, setUpdates] = useState(0)
    const [filters, setFilters] = useState<Filters>({})

    // connection
    useEffect(() => {
        if (idbInstances[dbName]) {
            setDB(idbInstances[dbName])
            return
        }

        const request = indexedDB.open(dbName, options.version || 1)
        request.onsuccess = () => {
            idbInstances[dbName] = request.result
            setDB(idbInstances[dbName])
        }

        request.onerror = () => {
            console.log('Error opening IndexedDB database')
        }

        request.onupgradeneeded = (event) => {
            const db = (event.target as IDBOpenDBRequest).result
            let store
            if (db.objectStoreNames.contains(storeName)) {
                store = (event.target as IDBOpenDBRequest).transaction!.objectStore(storeName)
            } else {
                store = db.createObjectStore(storeName, { keyPath: 'id' })
            }

            options.index?.forEach((index) => {
                if (store.indexNames.contains(index.name)) {
                    store.deleteIndex(index.name)
                }

                store.createIndex(index.name, index.key, index.options)
            })
        }
    }, [dbName])

    // get items
    useEffect(() => {
        if (db) {
            const transaction = db.transaction(storeName, 'readonly')
            const store = transaction.objectStore(storeName)
            const fetchedItems: T[] = []

            // let cursorRequest
            let cursorRequest: IDBRequest<IDBCursorWithValue | null>
            if (filters.index?.value) {
                cursorRequest = store
                    .index(filters.index.name)
                    .openCursor(IDBKeyRange.only(filters.index.value))
            } else {
                cursorRequest = store.openCursor()
            }

            cursorRequest.onsuccess = (event) => {
                const cursor = (event.target as IDBRequest<IDBCursorWithValue>).result
                if (cursor && fetchedItems.length < 50) {
                    if (!filters.filter || filters.filter(cursor.value)) {
                        fetchedItems.push(cursor.value)
                    }

                    cursor.continue()
                } else {
                    setItems(fetchedItems)
                }
            }
        }
    }, [db, storeName, updates, filters])

    // add items
    const addItems = (newItems: T | T[]) => {
        if (!db) return
        const transaction = db.transaction(storeName, 'readwrite')
        const store = transaction.objectStore(storeName)

        const itemsArray = Array.isArray(newItems) ? newItems : [newItems]
        // if (!Array.isArray(newItems)) {
        //     newItems = [newItems]
        // }

        itemsArray.forEach((item) => {
            store.put(item)
        })

        setUpdates((prevUpdates) => prevUpdates + 1)
    }

    // delete items with range criteria inside the function
    const deleteItemsRange = (key: string, range: IDBKeyRange) => {
        if (!db) return
        const transaction = db.transaction(storeName, 'readwrite')
        const store = transaction.objectStore(storeName)
        const request = store.index(key).openCursor(range)

        request.onsuccess = (event) => {
            const cursor = (event.target as IDBRequest<IDBCursorWithValue>).result
            if (cursor) {
                cursor.delete()
                cursor.continue()
            } else {
                setUpdates((prevUpdates) => prevUpdates + 1)
            }
        }

        request.onerror = (event) => {
            console.error('Error deleting items:', (event.target as IDBRequest).error)
        }
    }

    //
    return {
        dbReady: !!db,
        applyFilters: setFilters,
        items,
        addItems,
        deleteItemsRange,
    }
}

//
export function useOnScreen<T extends HTMLElement>(ref: React.RefObject<T>, callback: () => void) {
    useEffect(() => {
        if (!ref.current || !callback) {
            return
        }

        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                callback()
                observer.disconnect()
            }
        })

        if (ref.current) {
            observer.observe(ref.current)
        }

        return () => {
            observer.disconnect()
        }
    }, [ref])
}
