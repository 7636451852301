import { Spinner } from 'react-bootstrap'
import './Loading.scss'
import { ReactNode } from 'react'

export const Loading = ({ label }: { label?: ReactNode }) => (
    <div className="loading-spinner-container">
        <Spinner animation="border" variant="primary">
            <span className="sr-only">{label}</span>
        </Spinner>
    </div>
)
