const commerceI18n = {
    en: {
        total_order: 'Total Order',
        paid_expired: 'Paid After Expired',
        permissions_com: 'Commerce Permissions',
        seller_id: 'Seller',
        brand_id: 'Brand',
        address_id: 'Address',
        categories_ids: 'Categories',
        products_ids: 'Products',
        attributes_set_id: 'Product Type',
        location_id: 'Location',
        store_id: 'Store',
        attributes_ids: 'Attributes',
        product_id: 'Product',
        category_id: 'Category',
        saved_amount: 'Discount',
        minimum_quantity: 'Minimum Quantity',
        from_order_location: 'From Order Location',
        group_by_location: 'Group by Location',
        address_required: 'Address Required',
        created_at: 'Created at',
        special_price: 'Offer price',
        special_from: 'Offer from',
        special_to: 'Offer to',
        hold_quantity: 'On Hold',
        delivered_quantity: 'Delivered ',
        refund_quantity: 'Refunded',
        sales_start: 'Sales Start',
        sales_end: 'Sales End',
        store_code: 'Store Code',
        displayed_name: 'Display Name',
        coupon_code: 'Coupon Code',
        order_amount: 'Order Amount',
        use_per_user: 'Use Per User',
        date_from: 'From',
        date_to: 'to',
        display_setting: 'Display Setting',
        default_options: 'Default Options',
        content_type_id: 'Content Type',
        product_code: 'Product Code',
        USD: '$',
        url: 'URL',
        name: 'Name',
        monthly: 'Monthly',
        delivered: 'Delivered',
        status: 'Status',
        paid: 'Paid',
        price: 'Price',
        productsRevisions: 'Products Revisions',
        productsSort: 'Bulk Tagging',
        productsFaqs: 'Products FAQs',
        completed_at: 'Completed at',
        gifts_ids: 'Gifts',
        roles_ids: 'Roles',
        'Adding to Cart': 'Adding to Cart',
        Added: 'Added',
        'Model code': 'Model code',
        was: 'Was',
        before_cashback: 'Before cashback',
        now: 'Now',
        payment_methods_ids: 'Payment Methods',
        theme_key: 'Theme',
        pay: 'Pay',
        // Qr processing
        total: 'Total',
        youArePaying: 'You are paying',
        splitTheBill: 'Split the bill',
        payTheBill: 'Pay the bill',
        youCanSplitTheBill: 'You can split the bill',
        divideTheBillEqually: 'Divide the bill equally',
        payForYourItems: 'Pay for your items',
        payCustomAmount: 'Pay a custom amount',
        select: 'Select',
        totalPeopleInTable: 'Total people in your table',
        peopleYouPayFor: 'People you pay for',
        yourShare: 'Your share',
        removeSplit: 'Remove split',
        youCanPayCustomAmount: 'You can pay a custom amount',
        yourCustomAmount: 'Your custom amount',
        enterCustomAmount: 'Enter a custom amount that is between 1 and total price',
        payTheAmount: 'Pay the amount',
        paymentMethods: 'Payment Methods',
        selectPaymentMethod: 'Please select a payment method',
    },
    ar: {
        'Discount(Tax gift)': 'خصم (الضريبية هدية)',
        total_order: 'إجمالي الطلب',
        now: 'الآن',
        before_cashback: 'قبل الإسترداد',
        was: 'قبل',
        after: 'بعد',
        pay: 'أدفع',
        'Model code': 'كود الموديل',
        'Adding to Cart': 'جاري الإضافة',
        Added: 'تم الإضافة',
        pending: 'بإنتظار التأكيد',
        paid: 'مدفوع',
        expired: 'منتهي',
        canceled: 'ملغي',
        canceled_by_client: 'ملغي من قبل العميل',
        refunded: 'مرتجع',
        delivering: 'علي الطريق',
        completed: 'مكتمل',
        ready: 'جاهز',
        Date: 'التاريخ',
        paid_expired: 'مدفوع بعد إنتهاء الصلاحية',
        location: 'الموقع',
        'You did review it before': 'لقد قيمت هذا المنتج بالفعل',
        'Reviews Summary': 'ملخص التقييمات',
        'Share your review': 'قيم هذا المنتج',
        'Share your buying experience': 'شارك تجربته الشراء الخاصة بك',
        'Users Also Viewed': 'المستخدمين شاهدو ايضا',
        'Related Items': 'عناصر ذات علاقة',
        saved_amount: 'خصم',
        Reviews: 'التقييمات',
        'F & Q': 'الأسئلة والأجوبة',
        'There are no reviews right now': 'لا يوجد تقييمات',
        'There are no F&Q right now': 'لا يوجد أسئلة',
        Browse: 'تصفح',
        Hello: 'مرحبا',
        Cart: 'عربة التسوق',
        Favorites: 'المفضلة',
        'Choose Payment Method': 'إختر طريقة الدفع',
        'Thank you, for purchasing from our website': 'نشكرك علي شرائك من موقعنا',
        'Total Saved': 'إجمالي التوفير',
        'Cart Total': 'إجمالي السلة',
        'Continue To Checkout': ' إستكمال عملية الشراء',
        Discount: 'خصم',
        Quantity: 'الكمية',
        Free: 'مجاناً',
        'VAT Included': 'يشمل الضريبة',
        'Add to Cart': 'أضف للسلة',
        Loading: 'جاري التحميل',
        'My Orders': 'طلباتي',
        Favorite: 'المفضله',
        "You don't have products in your favorites list": 'لا يوجد لديك منتجات في المفضلة',
        Addresses: 'عناوين التوصيل',
        Name: 'الإسم',
        Email: 'البريد الإلكترونى',
        Mobile: 'رقم الجوال ',
        'Enter phone or phone already registered': 'أدخل رقم الجوال أو رقم جوال مسجل',
        Building: 'المبني',
        'What are you looking for?': 'إبحث في متجرنا',
        'Starting From': 'يبدأ من',
        Notifications: 'الإشعارات',
        'Save Changes': 'حفظ التعديلات',
        'Your Profile': 'ملفك الشخصي',
        'Update your personal information': 'تعديل بياناتك الشخصية',
        Previous: 'السابق',
        Next: 'التالي',
        'Sort By': 'الترتيب بحسب',
        Popular: 'الأكثر شعبية',
        Views: 'الأكثر مشاهدة',
        Oldest: 'الأقدم',
        Latest: 'الأحدث',
        Products: 'المنتجات',
        'Go to compare': 'الذهاب للمقارنة',
        'Browse your cart': 'الذهاب لسلة مشترياتك',
        'Cash on delivery': 'الدفع عند الإستلام',
        'Pay in Store': 'الدفع في الفرع',
        'This item price changed since you add it to your cart':
            'السعر تغيير منذ أضفت هذا العنصر للسلة',
        Items: 'عناصر',
        'Place Your Order': 'تأكيد الطلب',
        'Please payment amount must be more than or equal total amount':
            'يجب أن يكون المبلغ المدفوع أكبر من أو يساوى قيمة إجمالى الطلب',
        'Shipping Options': 'خيارات الشحن',
        Payment: 'الدفع',
        Delivery: 'التوصيل',
        delivered: 'تم التسليم',
        'Contact Information': 'بيانات الإتصال',
        'Delivery Information': 'بيانات التوصيل',
        'Delivery Time': 'توقيت التوصيل',
        'Full Name': 'الإسم الكامل',
        'Shipping Method': 'طريقة الشحن',
        'Shipping Address': 'عنوان الشحن',
        'Payment Method': 'طريقة الدفع',
        'Discount Code': 'كود الخصم',
        'Continue for options': 'إستمر للخيارات',
        'Add New Address': 'إضافة عنوان جديد',
        'Save Address': 'حفظ العنوان',
        'Add Code': 'إضافة الكود',
        'Your order is completed, Thank you for purchasing with us':
            'تم إستلام طلبك بنجاج، شكراً لشرائك معنا',
        'Check your order details': 'عرض تفاصيل الطلب',
        Orders: 'الطلبات',
        Total: 'الإجمالي',
        'Online Payment': 'دفع إلكتروني',
        'Order Details': 'تفاصيل الطلب',
        'Refund Details': 'تفاصيل المرتجع',
        'Order Confirmed': 'تم التأكيد',
        'Current Location': 'الموقع الحالي',
        'Address Name': 'إسم مختصر',
        Country: 'الدولة',
        State: 'المنطقة',
        City: 'المدينة',
        Street: 'الشارع',
        Floor: 'الدور',
        Apartment: 'الشقه',
        'Set as default address': 'تعيين كعنوان الإفتراضي',
        required: 'مطلوب',
        Address: 'العنوان',
        Invoice: 'الفاتورة',
        Shipment: 'الشحنة',
        Subtotal: 'الإجمالي الجزئي',
        'Shipping Fees': 'رسوم الشحن',
        'Show Code': 'إظهار الكود',
        Catalog: 'المنتجات',
        Categories: 'التصنيفات',
        Store: 'المتجر',
        devices: 'الاجهزة',
        'Content Management': 'إدارة المحتوي',
        address_id: 'العنوان',
        'Select your address on the map': 'حدد عنوانك علي الخريطة',
        Available: 'متوفر',
        'General Info': 'المعلومات الأساسية',
        'Choose Plan': 'إختيار الباقة',
        'Payment Info': 'بيانات الدفع',
        Unauthorized: 'يجب تسجيل الدخول',
        minimum_quantity: 'الحد الأدني للكمية',
        address_required: 'العنوان مطلوب',
        from_order_location: 'من موقع الطلب',
        group_by_location: 'موقع مجمع',
        searchable: 'قابل للبحث',
        key: 'المفتاح',
        'Browse your Favorites': 'عرض المفضلة',
        'There are no items in your favorites': 'لم يتم إضافة منتجات للمفضلة',
        total: 'الإجمالي',
        created_at: 'تاريخ الإضافة',
        'You already reviewed this product': 'قمت بتقييم المنتج مسبقاً',
        Fixed: 'ثابت',
        'Highest Price': 'الأعلي سعر',
        'Lowest Price': 'الأقل سعر',
        'Out of stock': 'نفدت الكمية',
        'Ready for Pickup': 'جاهز للإستلام',
        'Special Products': 'المنتجات المميزة',
        Compare: 'مقارنة',
        'Location not found': 'لا يوجد فرع',
        Brands: 'العلامات التجارية',
        'Pickup from location': 'الإستلام من الفرع',
        'Delivery from location': 'التوصيل من الفرع',
        productsRevisions: 'تعديلات المنتج',
        disable_gifts: 'إلغاء الهدايا',
        'Review Your Order': 'مراجعة الطلب',
        not_confirmed: 'غير مؤكد',

        // pos
        'Cancel payment': 'إلغاء الدفع',
        'Started at': 'توقيت البدأ',
        'Paid Amount': 'المبلغ المدفوع',
        Remaining: 'المتبقي',
        Client: 'العميل',
        'Order Items': 'أصناف الطلب',
        'Order Number': 'رقم الطلب',
        'Refund Number': 'رقم المرتجع',
        'Search by product name or barcode': 'ابحث بإسم المنتج او الباركود',
        'Search by category name': 'ابحث بـإسم القسم',
        Offline: 'غير متصل بالإنترنت',
        'Offline Orders': 'طلبات غير متزامنة',
        'Print Last Invoice': 'طباعة أخر فاتورة',
        'Remove The Code': 'إلغاء الكود',
        'Floor Plan': 'مخطط المطعم',
        Currency: 'العملة',
        'Product details text (Arabic)': 'صفحة المنتج / نص إضافي (عربي)',
        'Product details text (English)': 'صفحة المنتج / نص إضافي (إنجليزي)',
        shippable: 'قابل للشحن',
        'All Categories': 'كل الأقسام',
        New: 'جديد',
        Notes: 'ملاحظات',
        'Order Rating': 'تقييم الطلب',
        cash_back: 'إسترداد نقدي',
        gifts_ids: 'الهدايا',
        Gift: 'هدية',
        labels: 'ملصقات',
        Print: 'طباعة',
        AM: 'صباحاً',
        PM: 'مساءً',
        installation: 'تركيب',
        'Abandon Carts': 'السلات المتروكة',
        ordersItems: 'أصناف الطلب',
        forbidden: 'لا تملك الصلاحية',

        // Admin
        products: 'المنتجات',
        description: 'الوصف',
        categories_ids: 'التصنيفات',
        products_ids: 'المنتجات',
        attributes_set_id: 'التفاصيل',
        stocks: 'المخزون',
        media: 'الصور',
        price: 'السعر',
        special_price: 'سعر العرض',
        special_from: 'بداية العرض',
        special_to: 'نهاية العرض',
        location_id: 'الفرع',
        quantity: 'الكمية',
        hold_quantity: 'المحجوز',
        delivered_quantity: 'المسلم',
        refund_quantity: 'المرتجع',
        code: 'الكود',
        name: 'الإسم',
        seller_id: 'البائع',
        country: 'الدولة',
        state: 'المنطقة',
        city: 'المدينة',
        building: 'المبني',
        street: 'الشارع',
        brand_id: 'العلامة التجارية',
        sales_start: 'بداية البيع',
        sales_end: 'نهاية البيع',
        Payments: 'المدفوعات',
        sets: 'أنواع المنتجات',
        attributes: 'خصائص المنتجات',
        orders: 'الطلبات',
        views: 'الزيارات',
        brands: 'العلامات التجارية',
        reviews: 'التقييمات',
        comment: 'التعليق',
        coupons: 'قسائم الخصم',
        stores: 'المتاجر',
        store_code: 'كود المتجر',
        locations: 'المخازن',
        coordinates: 'الإحداثيات',
        store_id: 'المتجر',
        sellers: 'البائعين',
        shippingMethods: 'طرق الشحن',
        displayed_name: 'إسم الظهور',
        'Payment Methods': 'طرق الدفع',
        coupon_code: 'كود قسيمة الخصم',
        'Number of coupons': 'عدد القسائم',
        discount: 'الخصم',
        order_amount: 'قيمة الطلب',
        use_per_user: 'مرات الإستخدام لنفس المستخدم',
        date_from: 'تاريخ البداية',
        date_to: 'تاريخ الإنتهاء',
        driver: 'المشغل',
        Product: 'منتج',
        'Parent Product': 'منتج رئيسي',
        Subscription: 'إشتراك',
        Reservation: 'حجز',
        unique: 'فريد',
        display_setting: 'إعدادات العرض',
        default_options: 'خيارات المنتج الإفتراضية',
        attributes_ids: 'خصائص المنتج',
        values: 'القييم',
        monthly: 'شهري',
        yearly: 'سنوي',
        From: 'من',
        To: 'إلي',
        Renewal: 'التجديد',
        'this product has no shipping pints near you': 'لا يوجد نقاط شحن قريبة منك',
        'Your cart quantity is not acceptable for this shipping method':
            'كمية الطلب اقل من المطلوبه لطريقة الشحن المختارة',
        'This user has already existed,': 'رقم الجوال الخاص بك مسجل لدينا بالفعل ,',
        'Are you want to use this user?': 'هل تريد إستخدام هذا العميل مرة أخرى ؟',
        'Phone number must be 10 digits': 'رقم الجوال يجم أن يتكون من 10 أرقام',
        'Confirm user': 'التحقق من وجود العميل',
        'Orders not found': 'لا يـوجـد طلـبـات',
        Confirm: 'تأكـيـد',
        Cancel: 'إلـغـاء',
        payment_failed: 'فشل عملية الدفع',
        'Order not paid or invalid status': 'الطلب غير مدفوع او حالة الطلب غير مقبولة',
        Price: 'السعر',
        SAR: 'ريال',
        USD: '$',
        Offers: 'العروض',
        'No coupon found with this id': 'الكوبون غير موجود',
        'There are no items in your cart': 'لا يوجد منتجات في السلة',
        Handed: 'تم التوصيل',
        'Pickup Location': 'موقع الإستلام',
        productsFaqs: 'الأسئلة الشائعة',
        product_id: 'المنتج',
        question: 'السؤال',
        answer: 'الإجابة',
        Return: 'مرتجع',
        'Invalid refund quantity': 'كمية المرتجع غير صحيحة',
        'No items to return': 'لا يوجد عناصر لإرجاعها',
        'You May Also Like': 'ربما يعجبك أيضا',
        search: 'بحث',
        category_id: 'التصنيف',
        VAT: 'ضريبة القيمة المضافة',
        order: 'طلب',
        return: 'مرتجع',
        'Cancel Order': 'إلغاء الطلب',
        Select: 'إختيار',
        Yearly: 'سنوي',
        Monthly: 'شهري',
        'Add New Card': 'إضافة بطاقة جديدة',
        cart: 'سلة التسوق',
        wishlist: 'المفضلة',
        'shipping-methods': 'طرق الشحن',
        shifts: 'ورديات العمل',
        shiftsLogs: 'سجلات ورديات العمل',
        'products-faqs': 'الأسئلة الشائعة',
        product: 'المنتج',
        addresses: 'العناوين',
        "I've read and agreed to the": 'قرأت وأوافق على',
        'terms and conditions agreement': 'إتفاقية الشروط والأحكام',
        'shipping and installation agreement': 'إتفاقية التوصيل والتركيب',
        'You must agree to the terms before you can proceed':
            'يجب الموافقة علي الشروط قبل المتابعة',
        'Product Options': 'خيارات المنتج',
        total_from: 'الإجمالي من',
        total_to: 'إلي',
        payment_id: 'رقم الدفع',
        minimum: 'الحد الأدني',
        maximum: 'الحد الأقصي',
        percent: 'نسبة',
        'Number of Usage': 'مرات الإستخدام',
        content_type_id: 'نوع المحتوي',
        tags: 'الوسوم',
        included_tags: 'الوسوم المشمولة',
        excluded_tags: 'الوسوم المستبعدة',
        customer_id: 'العميل',
        Since: 'منذ',
        processing: 'جاري التحضير',
        frontend_setting: 'إعدادات العرض',
        'Payment method not available, use another method':
            'طريقة الدفع غير متاحة، يرجي إختيار طريقة دفع أخري',
        'Appointment Booking': 'حجز موعد',
        availabilities: 'المواعيد',
        available_from: 'متاح من',
        available_to: 'متاح إلي',
        'Additional Options': 'خيارات إضافية',
        'Product Options Sections': 'أقسام خيارات المنتج',
        section: 'القسم',
        'With %(amount)s %(currency)s cashback': 'مع إسترداد %(amount)s %(currency)s',
        product_code: 'كود المنتج',
        Packing: 'جاري التحضير',
        'Proceed to payment': 'إستكمال الدفع',
        productsSort: 'ترتيب الأصناف',
        completed_at: 'تاريخ الإكتمال',
        stock: 'المخزون',
        'Out of Stock': 'نفدت الكمية',
        'In Stock': 'متوفر',
        payment_methods_ids: 'طرق الدفع',
        theme_key: 'القالب',
        Pricing: 'التسعير',
        'Price Lists': 'قوائم الأسعار',
        priceLists: 'قوائم الأسعار',
        'Product Attributes': 'خصائص المنتج',
        cashback: 'إسترداد نقدي',
        start_date: 'تاريخ البداية',
        end_date: 'تاريخ الإنتهاء',
        price_list_id: 'قائمة الأسعار',
        private: 'خاص',
        'Cash Payment': 'الدفع في الفرع',
        // Qr processing
        total: 'الإجمالي',
        youArePaying: 'أنت تدفع',
        splitTheBill: 'قسّم الفاتورة',
        payTheBill: 'ادفع الفاتورة',
        youCanSplitTheBill: 'يمكنك تقسيم الفاتورة',
        divideTheBillEqually: 'قسّم الفاتورة بالتساوي',
        payForYourItems: 'ادفع مقابل أغراضك',
        payCustomAmount: 'ادفع مبلغًا مخصصًا',
        select: 'اختر',
        totalPeopleInTable: 'عدد الأشخاص على طاولتك',
        peopleYouPayFor: 'الأشخاص الذين تدفع لهم',
        yourShare: 'حصتك',
        removeSplit: 'إزالة التقسيم',
        youCanPayCustomAmount: 'يمكنك دفع مبلغ مخصص',
        yourCustomAmount: 'مبلغك المخصص',
        enterCustomAmount: 'أدخل مبلغًا مخصصًا بين 1 والسعر الإجمالي',
        payTheAmount: 'ادفع المبلغ',
        paymentMethods: 'طرق الدفع',
        selectPaymentMethod: 'برجاء اختيار طريقه الدفع',
    },
}

export default commerceI18n
