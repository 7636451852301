import { useEffect, useState } from 'react'
import { Cart } from '../Types'

export const useProductAndTotal = (cart: Cart, order: any) => {
    const [products, setProducts] = useState<any[]>([])
    const [totals, setTotals] = useState({ total: 0, totalSaved: 0, totalCashBack: 0 })
    const includeCashback = !(window.portalSetting('commerce.cashback.type') === 'included')

    useEffect(() => {
        const MainItems: any[] = []
        const tempTotals = { total: 0, totalSaved: 0, totalCashBack: 0 }

        cart.items.forEach((item) => {
            const cashBack = item.cash_back || 0
            item.cart_options_price = item.cart_options_price || 0

            tempTotals.total +=
                (item.current_price + item.cart_options_price - (includeCashback ? cashBack : 0)) *
                item.cart_quantity
            tempTotals.totalSaved += item.saved_amount * item.cart_quantity
            tempTotals.totalCashBack += cashBack * item.cart_quantity

            const gifts = (order.coupon?.disableGifts ? [] : item.gifts) || []

            MainItems.push({ key: item.id, item, cashBack, gift: false })
            MainItems.push(
                ...gifts.map((gift) => {
                    gift.cart_quantity = item.cart_quantity
                    gift.saved_amount = item.current_price
                    gift.cart_price = 0
                    gift.current_price = 0
                    gift.cash_back = 0

                    return { key: gift.id, item: gift, cashBack: 0, gift: true }
                })
            )
        })
        if (order.coupon) {
            tempTotals.total -= !order.coupon?.percent
                ? order.coupon.discount
                : ((tempTotals.total - tempTotals.totalCashBack) / 100) * order.coupon.discount
        }
        setTotals(tempTotals)
        setProducts(MainItems)
    }, [cart.items, order.coupon])

    return [products, totals]
}
