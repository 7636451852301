import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, ButtonGroup } from 'react-bootstrap'
import api, { webSocket } from '../../utilities/API'
import { addToCart } from '../Cart/actions'
import storage from '../../utilities/storage'
import './style/Seats.scss'

let reconnect = true

function Seats({ onSeatsChange }) {
    const dispatch = useDispatch()
    const user = useSelector((state) => state.portal.user)
    const [ready, setReady] = useState(false)
    const [, setReservedSeats] = useState([])
    const [tokenSeats, setTokenSeats] = useState([])
    const [expiration, setExpiration] = useState(0)
    const [ticker, setTicker] = useState(0)
    const navigate = useNavigate()
    const { store, mapID, eventID } = useParams()
    const stream = useRef(null)
    const script = useRef(null)
    const stageRef = useRef(null)

    // load seats map
    useEffect(() => {
        api('/commerce/seats/' + mapID, {
            result: (data) => {
                if (script.current) {
                    script.current.remove()
                }

                script.current = document.createElement('script')
                script.current.src = '/konva.min.js'
                script.current.onload = () => {
                    const stage = (stageRef.current = window.Konva.Node.create(
                        data,
                        'map-container'
                    ))
                    stage.on('click tap', seatSelectionHandler)

                    setTimeout(() => setReady(true), 1000)
                }

                document.body.appendChild(script.current)
            },
        })
    }, [mapID])

    // setting stream
    useEffect(() => {
        if (!ready) {
            return
        }

        reconnect = true
        streamConnection()

        // close connection on unmounted
        return () => {
            reconnect = false
            stream.current.close()
        }
    }, [ready])

    // handle seat selection
    const seatSelectionHandler = ({ target }) => {
        if (target.attrs.type !== 'seat' || target.reserved || !stream.current) {
            return
        }

        const seat = target.id()

        let isReserved = false
        setReservedSeats((reservedSeats) => {
            isReserved = reservedSeats.includes(seat)
            return reservedSeats
        })

        let isTokenSeat = false
        setTokenSeats((tokenSeats) => {
            isTokenSeat = tokenSeats.includes(seat)
            return tokenSeats
        })

        if (!isReserved || isTokenSeat) {
            stream.current.send(
                JSON.stringify({
                    type: isTokenSeat ? 'deselect' : 'select',
                    seat,
                })
            )
        }
    }

    // countdown ticker
    useEffect(() => {
        setTimeout(() => setTicker(ticker + 1), 1000)
    }, [ticker])

    //
    const streamConnection = () => {
        const conn = webSocket(
            '/commerce/products/' +
                eventID +
                '/seats?seats-token=' +
                (storage('seats_token_' + eventID) || '')
        )
        conn.onopen = () => (stream.current = conn)
        conn.onmessage = (data) => incomingMessages(JSON.parse(data))
        conn.onclose = (error) => {
            if (reconnect) {
                setTimeout(() => streamConnection(), 1000)
            }
        }
    }

    //
    const incomingMessages = (msg) => {
        switch (msg.type) {
            case 'init':
                if (!msg.token) {
                    return
                }

                msg.reserved_seats = msg.reserved_seats || []
                msg.token_seats = msg.token_seats || []

                // setToken(msg.token);
                setExpiration(msg.expiration || 0)
                setReservedSeats(msg.reserved_seats)
                setTokenSeats(msg.token_seats)

                // mark selected seats
                msg.reserved_seats.forEach((seat) => markSeat(seat, 'booked', true))
                msg.token_seats.forEach((seat) => markSeat(seat, 'owned', true))

                storage('seats_token_' + eventID, msg.token)
                break

            case 'select':
                setReservedSeats((reservedSeats) => [...reservedSeats, msg.seat])
                markSeat(msg.seat, 'booked', true)

                if (msg.is_mine) {
                    setTokenSeats((tokenSeats) => [...tokenSeats, msg.seat])
                    markSeat(msg.seat, 'owned', true)
                }
                break

            case 'deselect':
                setReservedSeats((reservedSeats) => spliceArray(reservedSeats, msg.seat))
                markSeat(msg.seat, 'booked', false)

                if (msg.is_mine) {
                    setTokenSeats((tokenSeats) => spliceArray(tokenSeats, msg.seat))
                    markSeat(msg.seat, 'owned', false)
                }
                break

            case 'expired':
                const expiredSeats = msg.expired_seats || []

                setReservedSeats((reservedSeats) => spliceArray(reservedSeats, expiredSeats))
                if (msg.is_mine) {
                    // setToken(null);
                    alert('Your session has expired. A new session will start')
                    stream.current.close()
                }

                // clear expired seats
                expiredSeats.forEach((seat) => {
                    markSeat(seat, 'booked', false)
                    markSeat(seat, 'owned', false)
                })

                break

            default:
                console.log('Seats type not handled: ' + msg.type)
        }
    }

    //
    const markSeat = (id, mark, force) => {
        let elm = stageRef.current.find('#' + id)
        if (elm.length) {
            elm = elm[0]
            elm[mark] = force
            if (!elm.initFill) {
                elm.initFill = elm.attrs.fill
            }

            elm.fill(force ? (mark === 'owned' ? 'yellow' : 'gray') : elm.initFill)
        }
    }

    //
    const onAddSeatsToCart = () => {
        const selectedSeats = tokenSeats.map((groupAndSeat) => {
            const group = groupAndSeat.split('-')[0]
            return {
                product_slug: eventID + '-' + group,
                quantity: 1,
                options: [{ key: 'seat', value: groupAndSeat }],
            }
        })

        dispatch(
            addToCart({
                data: selectedSeats,
                after: (status, error) => {
                    if (status === 'success') {
                        navigate(`/${store}/cart/order`)
                        return
                    }

                    alert(error)
                },
                local: !user,
                token: storage('seats_token_' + eventID),
            })
        )
    }

    return (
        <div id="seats-map" className={ready ? 'ready' : 'disabled'}>
            <h1>Select Your Seats</h1>

            {tokenSeats.length > 0 && (
                <ButtonGroup style={{ float: 'right', margin: '10px 10px 0 0', fontSize: 17 }}>
                    <Button disabled>
                        <i className="fa fa-chair" /> {tokenSeats.length}
                    </Button>
                    <Button onClick={onAddSeatsToCart}>Continue To Checkout</Button>
                </ButtonGroup>
            )}

            <h2 id="map-countdown">{countDown(expiration)}</h2>
            <div id="map-container" />
        </div>
    )
}

// remove item from array
function spliceArray(array, items) {
    if (typeof items === 'string') {
        items = [items]
    }

    items.forEach((item) => {
        const i = array.indexOf(item)
        if (i !== -1) {
            array.splice(i, 1)
        }
    })

    return [...array]
}

//
function countDown(timestamp) {
    const target = new Date()
    const now = new Date()

    target.setTime(timestamp * 1000)
    const totalMilliSeconds = target.getTime() - now.getTime()

    if (totalMilliSeconds < 0) {
        return
    }

    const totalSeconds = parseInt(totalMilliSeconds / 1000)
    const seconds = totalSeconds % 60
    const totalMinutes = parseInt(totalSeconds / 60)
    const minutes = totalMinutes % 60
    const totalHours = parseInt(totalMinutes / 60)
    const hours = totalHours % 24

    return hours + ':' + minutes + ':' + seconds
}

export default Seats
