import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import * as t from 'counterpart'

import ProductsCarousel from '../Blocks/ProductsCarousel'

// image gallery

import 'yet-another-react-lightbox/styles.css'
import 'yet-another-react-lightbox/plugins/thumbnails.css'

import 'react-datepicker/dist/react-datepicker.css'

import { triggerViewDetailsGEvent } from '../analytics/g-tags-events-triggers'
import '../style/Product.scss'

//Componenets
import { Loading } from '../../../Components/Loading/Loading'
import { Setup } from './Setup'
import { BreadCrumbs } from './BreadCrumbs'
import { ReviewsSummery } from './ReviewsSummery'
import { TabsSection } from './TabsSection'
import { MainDetails } from './MainDetails'
import { useGetReviews } from './hooks/useGetReviews'
import { useGetProductAndCategories } from './hooks/useGetProductAndCategories'
// import { AttributesBar } from './AttributeBar'
// import { SubProduct } from './SubProduct'

function Product() {
    let { store, slug } = useParams()
    const id = slug.split('-').pop()
    const user = useSelector((state) => state.portal.user)

    const [product, setProduct, faqs] = useGetProductAndCategories(id)
    const [reviews] = useGetReviews(id)

    useEffect(() => {
        if (product?.id) {
            triggerViewDetailsGEvent(product, window.portalSetting('commerce.currency', '', false))
        }
    }, [product?.id])

    const shareURL = window.portalSetting('site_url') + `/${store}/details/` + product?.id
    const ratingAverage =
        product?.stars && product?.reviewers ? (product.stars / product.reviewers).toFixed(1) : 5

    return (
        <div id="commerce-product-page" className="container">
            {!product && <Loading label="...loading" />}

            {product && (
                <>
                    <Setup product={product} ratingAverage={ratingAverage} shareURL={shareURL} />
                    <BreadCrumbs product={product} store={store} />

                    {/* Main details */}
                    <MainDetails
                        product={product}
                        ratingAverage={ratingAverage}
                        shareURL={shareURL}
                        setProduct={setProduct}
                        store={store}
                        user={user}
                    />

                    {/* Sub products */}

                    {/* {product.sub_products && (
                                <SubProduct product={product} user={user} store={store} />
                            )} */}

                    {/* <AttributesBar product={product} /> */}

                    <TabsSection product={product} reviews={reviews} faqs={faqs} />
                    <ReviewsSummery product={product} id={id} store={store} user={user} />

                    <ProductsCarousel
                        title={t('Users Also Viewed')}
                        cardsNumber={4}
                        filters={{ order_by: 'views' }}
                    />
                    <ProductsCarousel title={t('You May Also Like')} cardsNumber={4} />
                </>
            )}
        </div>
    )
}

export default Product
