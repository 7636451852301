import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as t from 'counterpart'
import { loadFavorites } from './actions'
import ProductBox from './Blocks/ProductBox'
import './style/Favorites.scss'

function Wishlist() {
    const dispatch = useDispatch()
    const items = useSelector((state) => state.commerce.favorites.data)
    const user = useSelector((state) => state.portal.user)

    useEffect(() => {
        if (user) {
            dispatch(loadFavorites())
        }
    }, [user, dispatch])

    return (
        <div id="favorites-page" className="container">
            <h1>{t('Favorites')}</h1>

            <div className="page-body d-flex">
                {items.map((item) => (
                    <ProductBox key={item.id} product={item} />
                ))}
                {items.length === 0 && (
                    <div className="empty">
                        {t("You don't have products in your favorites list")}
                    </div>
                )}
            </div>
        </div>
    )
}

export default Wishlist
