import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import ProductsCarousel from './ProductsCarousel'
import * as t from 'counterpart'
import api from '../../../utilities/API'
import './style/CategoriesProductsList.scss'

export default React.forwardRef(function (props = {}, ref) {
    const [categories, setCategories] = useState({ data: [] })
    const { store } = useParams()

    props = {
        ...props,
        className: 'commerce-categories-products-block ' + (props.className || ''),
    }

    useEffect(() => {
        api('/commerce/categories/?per_page=10&with_products=true', {
            result: (data) => setCategories(data),
        })
    }, [])

    return (
        <div {...props} ref={ref}>
            {(categories.data || []).map((category) => (
                <div key={category.id}>
                    <Link className="category_title" to={`/${store}/browse/${category.id}`}>
                        {category.name}
                        <span>{t('More')}</span>
                    </Link>

                    <ProductsCarousel
                        loadedProducts={category.top_products || category.latest_products || []}
                        hideDots
                        extended
                        cardsNumber={1}
                        cardsNumberMobile={1}
                    />
                    <ProductsCarousel
                        loadedProducts={category.latest_products || []}
                        hideDots
                        cardsNumber={3}
                        cardsNumberMobile={2}
                    />
                </div>
            ))}
        </div>
    )
})
