import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import * as t from 'counterpart'

import './style/StoreSwitcher.scss'

export default React.forwardRef(function (props = {}, ref) {
    const stores = window.portalSetting('stores') || []
    let currentStore = (useParams().store || '').split('-')[0]

    props = {
        ...props,
        className: ' store-switcher-block ' + props.className,
    }

    return (
        <Dropdown {...props} ref={ref}>
            <Dropdown.Toggle id="dropdown-basic">
                {(stores.find((s) => s.store_code === currentStore) || {}).name}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {stores.map((store) => (
                    <Dropdown.Item
                        key={store.id}
                        to={'/' + store.store_code + '-' + t.getLocale()}
                        as={Link}
                    >
                        {store.name}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    )
})
