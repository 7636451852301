import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import * as t from 'counterpart'

import { toggleFavorites } from '../../actions'
import { ToolTip } from '../../components/Tooltip'
import '../style/ProductBox.scss'

import { ReactComponent as FavIcon } from '../style/nsaq-icon-fav.svg'

export const FavAction = ({ productId }) => {
    const dispatch = useDispatch()
    const { favorites } = useSelector((state) => ({
        favorites: state.commerce.favoritesIDs,
    }))

    const [favStatus, setFavStatus] = useState('')
    const isFavorite = favorites && favorites.includes(productId)
    // toggle favorites
    const onAddToFavorites = (e) => {
        e.stopPropagation()
        e.preventDefault()

        dispatch(toggleFavorites(productId, setFavStatus, isFavorite))
    }

    // toggle fav label and classes
    let toggleFavLabel = 'Favorite'
    if (favStatus === 'loading') toggleFavLabel = 'Loading'

    let favoriteClass = 'fav-icon-button '
    if (isFavorite) favoriteClass += ' success'

    return (
        <ToolTip label={t(toggleFavLabel)}>
            <Button
                onClick={onAddToFavorites}
                className={favoriteClass}
                disabled={favStatus === 'loading'}
            >
                <FavIcon />
            </Button>
        </ToolTip>
    )
}
