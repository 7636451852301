import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import AliceCarousel from 'react-alice-carousel'
import api from '../../../utilities/API'

import 'react-alice-carousel/lib/alice-carousel.css'
import './style/BrandsCarousel.scss'

export default React.forwardRef(function (props = {}, ref) {
    const [brands, setBrands] = useState([])
    const { store } = useParams()
    const { hideButtons, hideDots, cardsNumber } = props

    props = {
        ...props,
        className: 'brands-carousel-block ' + props.className,
    }

    delete props.hideButtons
    delete props.hideDots
    delete props.cardsNumber

    useEffect(() => {
        api('/commerce/brands/', {
            result: ({ data }) => setBrands(data),
        })
    }, [])

    let carousel
    return (
        <div {...props} ref={ref}>
            {props.title && <h3>{props.title}</h3>}

            <AliceCarousel
                autoPlay={props.autoPlay}
                dotsDisabled={hideDots}
                disableButtonsControls
                duration={1000}
                mouseTrackingEnabled
                disableAutoPlayOnAction
                touchMoveDefaultEvents={false}
                responsive={{ 0: { items: 3 }, 960: { items: cardsNumber || 6 } }}
                ref={(el) => (carousel = el)}
            >
                {brands.map((brand) => (
                    <Link to={`/${store}/browse?brand_id[]=${brand.id}`} key={brand.id}>
                        <img src={brand.image} alt="" />
                    </Link>
                ))}
            </AliceCarousel>

            {!hideButtons && (
                <div className="buttons">
                    <i className="fa fa-caret-right" onClick={() => carousel.slideNext()} />
                    <i className="fa fa-caret-left" onClick={() => carousel.slidePrev()} />
                </div>
            )}
        </div>
    )
})
