import React from 'react'
import { Route } from 'react-router-dom'

const Reports = React.lazy(() => import('./Reports'))

const reportsInit = {
    reducers: {},
    routes: [<Route key="reports_main" exact path="/" element={<Reports />} />],
    i18n: {
        en: {},
        ar: {},
    },
}

export default reportsInit
